import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ReactApexChart from "react-apexcharts";
import RightSideLayout1 from "app/fuse-layouts/layout1/components/RightSideLayout1";
import "./dashboard.css"
const useStyles = makeStyles((theme) => ({
  subscribeCOntainer: {
    height: "333px",
    boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
    borderRadius: "8px",
    background: "#FFFFFF",
  },
  categoryCOntainer: {
    height: "333px",
    boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
    borderRadius: "8px",
    background: "#FFFFFF",
  },
  topTitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.0025em",
    color: "#313133",
    padding: "12px",
  },
  toggaleGroup: {
    height: "32px",
  },
  toggleButton: {
    "& .Mui-selected": {
      background: "#E8E6FF",
    },
  },
  toolBarContainer: {
    padding: "10px 14px",
  },
}));
export function Subscriptions({ data,name }) {
  const classes = useStyles();
  const [alignment, setAlignment] = useState("usage");
  const [istoggleUsage, setToggleUsage]=useState(true);
  const [istoggleSpending, setToggleSpending]=useState(false);
  var colorPalette1 = ["#92D4BE", "#8378F9", "#FFE899", "#FF99B3", "#80B7FF"];
  var colorPalette2 = ["#92D4BE", "#8378F9", "#FFE899", "#FF99B3", "#80B7FF"];
  var optionDonutUsage = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        customScale: 0.8,
        donut: {
          size: "89%",
          labels: {
            show: true,
            name: {
              offsetY: 20,
            },
            colors: "#578495",
            value: {
              show: true,
              showAlways: true,
              fontSize: "28px",
              fontWeight: 500,
              color: "#575759",
              offsetY: -20,
              formatter: function (val) {
                return val +"%";
              },
            },
          },
        },
      },
    },

    colors: colorPalette1,
    series: data.series,
    labels: data.labels,
    legend: {
      offsetX:30,
      offsetY:30,
      show: true,
      position:"right",
      width:"50%",
      formatter: function (seriesName, opts) {
        return `<div class="graphLabel"> <p class="graphLabelName">${[seriesName]}</p><p style="margin-left:20px">${[opts.w.globals.series[opts.seriesIndex]]}%</p></div>`
        
      },
      markers: {
        width: 16,
        height: 16,
        strokeWidth: 10,
        
        strokeColor: "#fff",
        fillColors: ["#fffff"],
        radius: 12,

        onClick: undefined,
        // offsetX: 0,
        // offsetY: 0,
        customHTML: function (val) {
          return '<span class="custom-marker"><svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style="fill: white"> <circle cx="50" cy="50" r="20"/> </svg></span>';
        },
      },
    },
  };

  
  var optionDonutSpend = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        customScale: 0.8,
        donut: {
          size: "89%",
          labels: {
            show: true,
            name: {
              offsetY: 20,
            },
            colors: "#578495",
            value: {
              show: true,
              showAlways: true,
              fontSize: "28px",
              fontWeight: 500,
              color: "#575759",
              offsetY: -20,
              formatter: function (val) {
                return  "$"+val ;
              },
            },
          },
        },
      },
    },

    colors: colorPalette2,
    series: data.series2,
    labels: data.labels,
    legend: {
      offsetX:30,
      offsetY:30,
      show: true,
      
      formatter: function (seriesName, opts) {
        return `<div class="graphLabel"> <p class="graphLabelName">${[seriesName]}</p><p style="margin-left:30px">$${[opts.w.globals.series[opts.seriesIndex]]}</p></div>`
        
      },
      markers: {
        width: 16,
        height: 16,
        strokeWidth: 10,
        strokeColor: "#fff",
        fillColors: ["#fffff"],
        radius: 12,

        onClick: undefined,
        // offsetX: 0,
        // offsetY: 0,
        customHTML: function (val) {
          return '<span class="custom-marker"><svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style="fill: white"> <circle cx="50" cy="50" r="20"/> </svg></span>';
        },
      },
    },
  };
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handletoggleUsage = () => {
    setToggleUsage(true);
    setToggleSpending(false);
  };const handletoggleSpending = () => {
    setToggleSpending(true);
    setToggleUsage(false);
  };
  return (
    <div className={classes.subscribeCOntainer}>
      <div
        className={`flex justify-between border-b items-center ${classes.toolBarContainer}`}
      >
        <div className={classes.topTitle}>{name}</div>
        <div>
          <ToggleButtonGroup
            className={classes.toggaleGroup}
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton
              value="usage"
              onClick={handletoggleUsage}
              style={{
                backgroundColor: istoggleUsage ? "#E8E6FF" : "",
                color: istoggleUsage ? "#483EB3" : "",
              }}
              aria-label="usage"
            >
              Usage
            </ToggleButton>
            <ToggleButton
              value="spend"
              onClick={handletoggleSpending}
              style={{
                backgroundColor: istoggleSpending ? "#E8E6FF" : "",
                color: istoggleSpending ? "#483EB3" : "",
              }}
              aria-label="spend"
            >
              Spend
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div style={{justifyContent:"flex-start"}}>
      { istoggleSpending ? (
        <ReactApexChart
          options={optionDonutSpend}
          series={optionDonutSpend.series}
          type="donut"
          height="275px"
          width="100%"
        />
        
      ) :
      <ReactApexChart
      options={optionDonutUsage}
      series={optionDonutUsage.series}
      type="donut"
      height="275px"
      width="100%"
    />
    }
       
      </div>
    
    </div>
  );
}

export function Category({ data }) {
  const classes = useStyles();
  const [alignment, setAlignment] = useState("usage");
  const [state, setState] = useState({
    options: {
      xaxis: {
        show: false,
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "right",
        offsetY: 0,
        offsetX: 0,
        height: 230,
      },
      chart: {
        type: "donut",
        width: "100%",
        height: "100%",
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "89%",
            labels: {
              show: true,
              name: {
                labels: "tex",
              },
              value: {
                value: "value",
              },
            },
          },
        },
      },
    },
    series: [44, 55, 41, 17, 15],
    chartOptions: {
      labels: ["Apple", "Mango", "Orange", "Watermelon"],
    },
  });
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  return (
    <div className={classes.categoryCOntainer}>
      <div
        className={`flex justify-between border-b items-center ${classes.toolBarContainer}`}
      >
        <div className={classes.topTitle}>App Categories</div>
        <div>
          <ToggleButtonGroup
            className={classes.toggaleGroup}
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton value="usage" aria-label="usage">
              Usage
            </ToggleButton>
            <ToggleButton value="spend" aria-label="spend">
              Spend
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div>
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="donut"
        />
      </div>
    </div>
  );
}
