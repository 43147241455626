import React from "react";
import { authRoles } from 'app/auth';
import Insights from "./Insights";

const InsightsConfig = {
  settings: {
    layout: {
      config: {
        navTitle:"Insights"
      },
    },
  },
  routes: [
    {
      path: "/subscribe/insight",
      // auth: authRoles.admin,
      component: Insights,
    },
  ],
};

export default InsightsConfig;