import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Divider, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { AvatarGroup } from '@mui/material';
import AreaChart from './compnonets/AreaChart';
import axios from "axios";
import { fetchToken } from '../auth/auth';
import { useContext } from 'react';
import { SubsciptionContext } from './Subscriptions';
const useStyles = makeStyles((theme) => ({
    cardWrapper: {
        background: "#FFFFFF",
        boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
        borderRadius: "8px",
        // padding: "32px 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    dollar:{
        fontFamily: 'Mail Sans Roman',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',   
        color: '#575759',
        
    },
    divider: {
        border: "1px solid #E8E6FF",
        transform: "rotate(180deg)",
        // height: "80px"
        // paddig:""
        margin: "18px 0"
    },
    heading: {
        fontWeight: "500",
        fontSize: "22px",
        lineHeight: "28px",
        color: "#313133",
        fontFamily: "Mail Sans Roman",
        textAlign:"center",
    },
    subheading: {
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "16px",
        letterSpacing: "0.0025em",
        color: "#575759",
        marginTop: "10px",
        fontFamily: "Inter"
    },
    redSubheading: {
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "16px",
        color: "#CC2525",
        // marginBottom:"7px",
        fontFamily: "Inter"
    },
    greenSubheading: {
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "16px",
        color: "#3A9375",
        // marginBottom:"7px",
        fontFamily: "Inter"
    },
    mailSansRoman: {
        fontFamily: "Mail Sans Roman"
    },
    Inter: {
        fontFamily: "Inter"
    }
}))

function Showoverall() {
    const classes = useStyles()
    const [apps,setApps]=React.useState([])
    const [users,setUsers]=React.useState([])
    const [annualspend,setAnnualSpend]=React.useState([])
    const {getdata,setGetData} = useContext(SubsciptionContext);
    const [usage,setUsage]=React.useState([])
   

  const getApps = ()=>{
    axios.get('https://demo.subscribepod.com/api/v1/subscriptions/get-total-apps/', 
    {
      headers: {
        Authorization: `Bearer ${fetchToken()}`,
      },
    },

    )
    .then(function (response) {
      console.log(response.data, "app set");
      if (response.data) {
        setApps(response.data);
        // localStorage.setItem("apps",response.data.Total_apps)

        console.log(apps);
      
      }
    })
    .catch(function (error) {
      console.log(error, "error");
    });
  }

  const getUsers = ()=>{
    axios.get('https://demo.subscribepod.com/api/v1/subscriptions/get-total-users/', 
    {
      headers: {
        Authorization: `Bearer ${fetchToken()}`,
      },
    },

    )
    .then(function (response) {
      console.log(response.data, "user get");
      if (response.data) {
        // localStorage.setItem("users",response.data.Total_users)
        setUsers(response.data);

        console.log(apps);
      
      }
    })
    .catch(function (error) {
      console.log(error, "error");
    });
  }
  const getAnnualSpend = ()=>{
    axios.get('https://demo.subscribepod.com/api/v1/subscriptions/get-all-subscriptions-annual-spend/', 
    {
      headers: {
        Authorization: `Bearer ${fetchToken()}`,
      },
    },

    )
    .then(function (response) {
      console.log(response.data, "annual spend");
      if (response.data) {
        setAnnualSpend(response.data);
        console.log(apps);
      
      }
    })
    .catch(function (error) {
      console.log(error, "error");
    });
  }
  const getUsage = ()=>{
    axios.get('http://demo.subscribepod.com/api/v1/subscriptions/get-all-subscriptions-usage/', 
    {
      headers: {
        Authorization: `Bearer ${fetchToken()}`,
      },
      
    },

    )
    .then(function (response) {
      console.log(response.data, "usage");
      if (response.data) {
        setUsage(response.data);
        console.log(apps);
      
      }
    })
    .catch(function (error) {
      console.log(error, "error");
    });
  }


 
  useEffect(() => {
    getApps()
    getUsers()
    getAnnualSpend()
    // getUsage()
   
  },[getdata])


    const chartData = {
        chart_1: [30, 10, 40, 50, 30, 70],
        chart_2: [30, 95, 40, 50, 30, 70],
        chart_3: [30, 10, 40, 80, 30, 70],
        chart_4: [10, 60, 40, 50, 30, 70],
    }
    return (
        <div className={classes.cardWrapper}>
            <div className='flex justify-between px-32 py-32'>
                <div>
                    <Typography className={`${classes.heading} H2_22`}>{apps.Total_apps}</Typography>
                    <Typography className={`${classes.subheading} Small_13R`}>Total Apps</Typography>
                </div>
                {/* <div className="flex flex-col items-end">
                    <div className="flex mb-5">
                        <img className="mr-5 justify-end" src="assets/icones/subscription/greenArrow.svg" alt="arrow"></img>
                        <Typography className={`${classes.greenSubheading} Small_13R`}>2% vs Feb</Typography>
                    </div>
                    <AreaChart data={chartData.chart_1} />
                </div> */}

            </div>
            {/* <div className={classes.divider} /> */}
            <div className='flex justify-between px-32 py-32'>
                <div className="flex flex-col items-center">
                    <div className='flex items-center'>
                        <span className={classes.dollar}>$</span>
                        <Typography className={`${classes.heading} H2_22`}>{annualspend.Annual_spend}</Typography>
                    </div>
                    <Typography className={`${classes.subheading} Small_13R`}>Annual Spend</Typography>
                </div>
                {/* <div>
                    <div className="flex mb-5 justify-end">
                        <img className="mr-4" src="assets/icones/subscription/redArrow.svg" alt="arrow"></img>
                        <Typography className={`${classes.redSubheading} Small_13R`}>5%</Typography>
                    </div>
                    <AreaChart data={chartData.chart_2} />
                </div> */}

            </div>
            {/* <div className={classes.divider} /> */}
            <div className='flex justify-between px-32 py-32'>
                <div>
                    <Typography className={`${classes.heading} H2_22`}>{users.Total_users}</Typography>
                    <Typography className={`${classes.subheading} Small_13R`}>Total Users</Typography>
                </div>
                {/* <div className="flex flex-col items-end">
                    <div className="flex mb-5 justify-end">
                        <img className="mr-4" src="assets/icones/subscription/redArrow.svg" alt="arrow"></img>
                        <Typography className={`${classes.redSubheading} Small_13R`}>2% vs Feb</Typography>
                    </div>
                    <AreaChart data={chartData.chart_3} />
                </div> */}

            </div>
            {/* <div className={classes.divider} /> */}
            {/* <div className='flex justify-between w-full px-32 pt-32'>
                <div>
                    <Typography className={`${classes.heading} H2_22`}>{usage.Total_usage}</Typography>
                    <Typography className={`${classes.subheading} Small_13R`}>Avg. Usage</Typography>
                </div>
                <div className="flex flex-col items-end">
                    <div className="flex mb-5">
                        <img className="mr-5" src="assets/icones/subscription/redArrow.svg" alt="arrow"></img>
                        <Typography className={`${classes.redSubheading} Small_13R`}>5% vs Feb</Typography>
                    </div>
                    <AreaChart data={chartData.chart_4} />
                </div> 

            </div> */}
        </div>
    )
}

export default Showoverall