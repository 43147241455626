import {  Avatar, Box, Divider, makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { AvatarGroup } from '@mui/material';
import React from 'react'
const useStyles = makeStyles((theme) => ({
    column:{
      height:"100%"
    },
    row:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height:"20px",
        padding: "6px 0px"
    },
    avatarGroups:{
        display: "flex",
        justifyContent: "flex-end",
        flexDirection:"column",
        alignItems: "center",
        height:"70px",
        padding: "12px 0px",
        marginBottom: "12px"
    },
    avatar:{
        minWidth: "24px",
        minHeight: "24px",
        marginBottom:"-16px",
        border: "1px solid #E8E6FF",
    },
    divider: {
        height: "5px",
    },
    
    rangeText:{
        // font-family: 'Inter';
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
/* identical to box height */

       letterSpacing: "0.005em",
       marginTop:"8px",

/* Neutrals/Labels&Icons */

        color: "#7F7E8C"
    },
    
  }));
  
function ColumnCharts() {
    const classes = useStyles()
    const chartData=[
      {
        month:"Apr",
        imgs:[
          "assets/icones/subscribe/figm.svg",
          "assets/icones/subscribe/figm.svg",

        ]
      },
      {
        month:"May",
        imgs:[
          "assets/icones/subscribe/figm.svg",
        ]
      },
      {
        month:"Jun",
        imgs:[
          "assets/icones/subscribe/figm.svg",
          "assets/icones/subscribe/figm.svg",

        ]
      },
      {
        month:"Jul",
        imgs:[
          "assets/icones/subscribe/figm.svg",
          "assets/icones/subscribe/figm.svg",

        ]
      },
      {
        month:"Aug",
        imgs:[]
      },
      {
        month:"Sep",
        imgs:["assets/icones/subscribe/figm.svg"]
      },
      {
        month:"Oct",
        imgs:[
          "assets/icones/subscribe/figm.svg",
          "assets/icones/subscribe/figm.svg",

        ]
      },
      {
        month:"Nov",
        imgs:[
          "assets/icones/subscribe/figm.svg",
          "assets/icones/subscribe/figm.svg",

        ]
      },
      {
        month:"Dec",
        imgs:[
          "assets/icones/subscribe/figm.svg",
          "assets/icones/subscribe/figm.svg",

        ]
      },
    ]

  return (
  

    <div className="flex item-center justify-center w-full p-20">
    <Grid container direction="row" wrap="nowrap"	>
        {chartData.map((items , key)=>
          <Grid item xs={2}  key={key}>
               <AvatarGroup max={4} class={classes.avatarGroups} >
                {items?.imgs.map((img, key)=><Avatar  className={classes.avatar} alt={key}  src={img} />)}
               </AvatarGroup>
              <span className="mt-32">
                     <Divider></Divider>
              </span>
              <Box component="span" class={classes.row}>
                  <Typography class={classes.rangeText}>{items.month}</Typography>
              </Box>
          </Grid>
        )}
        
        
    </Grid>
    </div>
        
   
  )
}

export default ColumnCharts