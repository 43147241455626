import { Redirect,Route } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import ExampleConfig from 'app/main/example/ExampleConfig';
import FuseLoading from '@fuse/core/FuseLoading';
import Error404Page from 'app/main/404/Error404Page';
import InsightsConfig from 'app/main/Insights/InsightsConfig';
import SubscriptionsConfig from 'app/main/Subscriptions/SubscriptionsConfig';
import TeamConfig from 'app/main/Teams/TeamConfig';
import TransactionsConfig from 'app/main/Transactions/TransactionsConfig';
import UsersConfig from 'app/main/Users/UsersConfig';
import ActivityConfig from 'app/main/Workflows/Activity/ActivityConfig';
import TemplatesConfig from 'app/main/Workflows/Templates/TemplatesConfig';
import WorkflowsConfig from 'app/main/Workflows/Workflows/WorkflowsConfig';
import LoginConfig from 'app/main/login/LoginConfig';
import RegisterConfig from "app/main/Register/RegisterConfig"
import ForgotPasswordConfig from 'app/main/Forgotpassword/ForgotPasswordConfig';
import UserDetailsConfig from 'app/main/Users/UsersDetailsConfig';
import DashboardConfig from 'app/main/Dashboard/DashboardConfig';
import WorkflowsDetailsConfig from 'app/main/Workflows/Workflows/WorkflowDetailsConfig';
import OnboardingConfig from 'app/main/Onboarding/OnboardingConfig';
import TriggerConfig from 'app/main/Workflows/NewWorklow/Trigger/TriggerConfig';
import ConditionConfig from 'app/main/Workflows/NewWorklow/Condition/ConditionConfig';
import TourAppConfig from 'app/main/Dashboard/TourAppConfig';
import ActionConfig from 'app/main/Workflows/NewWorklow/Action/ActionConfig';
import Settings from "app/main/Settings/SettingsConfig"
import DetailpageConfig from 'app/main/Subscriptions/Detailpage/DetailpageConfig';
import IntegrationsConfig from 'app/main/integrations/IntegrationsConfig';
import PasswordConfig from 'app/main/Register/PasswordsetConfig';
import { RequireToken } from 'app/main/auth/auth';

const routeConfigs = [ExampleConfig,PasswordConfig,TourAppConfig, DetailpageConfig, Settings, OnboardingConfig, ForgotPasswordConfig, RegisterConfig, LoginConfig, DashboardConfig, TemplatesConfig, WorkflowsConfig, InsightsConfig, SubscriptionsConfig, TeamConfig, TransactionsConfig, UsersConfig, ActivityConfig, UserDetailsConfig, WorkflowsDetailsConfig, TriggerConfig, ConditionConfig, ActionConfig, IntegrationsConfig];
const isAuthenticated = !!localStorage.getItem('token');
const routes = [
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
  {
    exact: true,
    path: '/subscribe/dashboard',
    component: () => <Redirect to='/subscribe/dashboard' />,
  },
  // {
  //   path: '/subscribe/dashboard',
  //   exact: true,
  //   component: isAuthenticated ? <Redirect to="/subscribe/dashboard" /> : () => <Redirect to="/login" />,
  // },
  {
    exact: true,
    path: '/password',
    component: () => <Redirect to="/password" />,
  },
  {
    exact: true,
    path: '/subscribe/insight',
    component: () =><RequireToken> <Redirect to="/subscribe/insight" /></RequireToken>,
  },
  {
    exact: true,
    path: '/subscribe/subscription',
    //component: () => <RequireToken><Redirect to="/subscribe/subscription" /></RequireToken>,
  },
  {
    exact: true,
    path: '/subscribe/user',
    component: () => <RequireToken><Redirect to="/subscribe/user" /></RequireToken>,
  },
  {
    exact: true,
    path: '/subscribe/team',
    component: () =><RequireToken> <Redirect to="/subscribe/team" /></RequireToken>,
  },
  {
    exact: true,
    path: '/subscribe/workflows/template',
    component: () => <RequireToken><Redirect to="/subscribe/workflows/template" /></RequireToken>,
  },
  {
    exact: true,
    path: '/subscribe/workflows/workflow',
    component: () => <RequireToken><Redirect to="/subscribe/workflows/workflow" /></RequireToken>,
  },
  {
    exact: true,
    path: '/subscribe/workflows/activity',
    component: () => <RequireToken><Redirect to="/subscribe/workflows/activity" /> </RequireToken>,
  },
  {
    exact: true,
    path: '/subscribe/transaction',
    component: () => <RequireToken><Redirect to="/subscribe/transaction" /></RequireToken>,
  },
  {
    path: '/loading',
    exact: true,
    component: () => <FuseLoading />,
  },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/login" />,
  },
  {
    path: '/register',
    exact: true,
    component: () => <Redirect to="/register" />,
  },
  {
    path: '/forgot-password',
    exact: true,
    component: () => <Redirect to="/forgot-password" />,
  },
  {
    path: '/404',
    component: () => <Error404Page />,
  },
  {
    component: () => <Redirect to="/404" />,
  },
  {
    path: '/users/details',
    component: () =><RequireToken> <Redirect to="/users/details" /></RequireToken>,
  },
  {
    path: '/subscribe/workflow/details',
    component: () => <RequireToken><Redirect to="/subscribe/workflow/details" /></RequireToken>,
  },
  {
    path: '/onboarding',
    component: () => <RequireToken><Redirect to="/onboarding" /></RequireToken>,
  },
  {
    path: '/subscribe/workflows/Trigger',
    component: () => <RequireToken><Redirect to="/subscribe/workflows/Trigger" /></RequireToken>,
  },
  {
    path: '/subscribe/workflows/Condition',
    component: () => <RequireToken><Redirect to="/subscribe/workflows/Condition" /></RequireToken>,
  },
  {
    path: '/subscribe/workflows/Action',
    component: () =><RequireToken> <Redirect to="/subscribe/workflows/Action" /></RequireToken>,
  },
  {
    path: '/subscribe/integrations',
    component: () => <RequireToken><Redirect to="/subscribe/integrations" /></RequireToken>,
  },
  {
    path: '/subscribe/settings',
    component: () => <RequireToken> <Redirect to="/subscribe/settings" /></RequireToken> ,
  },
  {
    path: "/subscribe/subscription/:id",
    component: () =>  <RequireToken><Redirect to="/subscribe/subscription/:id" /></RequireToken> ,
  }
];

export default routes;
