import React from "react";
import {
  alpha,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputBase,
  InputLabel,
  makeStyles,
  MenuItem,
  Popover,
  Select,
  styled,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  ListItemIcon,
  Icon,
  ListItemText,
} from "@material-ui/core";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useHistory } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import axios from "axios";
import { fetchToken } from "app/main/auth/auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const useStyles = makeStyles({
  loader: {
    backgroundColor: "white",
    marginTop: "250px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  container: {
    maxHeight: 800,
  },
  textAlign: {
    textAlign: "left",
    paddingLeft: "32px",
  },
  columTitleRow: {
    background: "#FCFCFF",
    paddingLeft: "32px",
  },
  main: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
    borderRadius: "8px",
    marginTop: "30px",
    height: "100%",
  },
  smallLabelText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    linehHeight: "15px",
    letterSpacing: "0.005em",
    // marginLeft:"24px",
    color: "#7F7E8C",
    textAlign: "left",
    width: "100%",
  },
  sub_text: {
    // fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    linehHeight: "18px",
    letterSpacing: "0.0025em",
    // marginTop:"12px",

    /* Neutrals/Subheading */

    color: "#575759",
  },
  heading3: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "23px",
    margin: "0px 20px",
    fontFamily: "mail sans roman",
    color: "#313133",
  },
  heading4: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "23px",
    color: "#313133",
    marginBottom: "12px",
  },
  heading4_white: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "23px",
    color: "#FFFFFF",
    marginBottom: "12px",
  },
  heading1_white: {
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "23px",
    color: "#FFFFFF",
    marginBottom: "12px",
  },
  text_grey: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "16px",
    color: "#7F7E8C",
    // marginBottom: "12px",
  },
  text_CardNumber: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#313133",
  },
  current_planBox: {
    background: "#FFFFFF",
    /* Neutrals/Border */

    border: "1px solid #E8E6FF",
    /* Elevation2 */

    boxShadow: "1px 1px 13px rgba(76, 72, 113, 0.1)",
    bordeRadius: "8px",
  },
  current_plan: {
    // position: absolute;
    width: "301px",
    height: "164px",

    background: "linear-gradient(105.5deg, #8378F9 0.81%, #6456F7 100%)",
    /* Elevation2 */
    padding: "30px",

    boxShadow: "1px 1px 13px rgba(76, 72, 113, 0.1)",
    borderRadius: "8px 0px 0px 8px",
  },
  addButton: {
    boxSizing: "border-box",
    padding: "27px 37px",

    /* Neutrals/Text_White */

    background: "#FFFFFF",
    /* Neutrals/Border */

    border: "1px dashed #E8E6FF",
    bordeRadius: "8px",
  },
  payment_cards: {
    background: "#FFFFFF",
    /* Neutrals/Border */

    border: "1px solid #E8E6FF",
    /* Elevation2 */

    boxShadow: "1px 1px 13px rgba(76, 72, 113, 0.1)",
    bordeRadius: "8px",
    padding: "24px",
  },
  connectButton: {
    padding: "9px 16px",

    /* Neutrals/Border */

    border: "1px solid #E8E6FF",
    borderRadius: "4px",
    color: "#FFFFFF",
    backgroundColor: "#49B892",
    fontSize: "13px",
    fontWeight: "500",
  },
  reviweBotton: {
    padding: "9px 16px",

    width: "88px",
    height: "40px",

    /* Neutrals/Border */

    border: "1px solid #E8E6FF",
    borderRadius: "4px",
    color: "#7F7E8C",
  },
});

const userData = [
  {
    USER: "Harsh Dhorajia",
    "WORK EMAIL": "harsh@gmail.com",
    PERMISSION: "admin",
  },
  {
    USER: "Harsh Dhorajia",
    "WORK EMAIL": "harsh@gmail.com",
    PERMISSION: "manager",
  },
  {
    USER: "Harsh Dhorajia",
    "WORK EMAIL": "harsh@gmail.com",
    PERMISSION: "manager",
  },
  {
    USER: "Harsh Dhorajia",
    "WORK EMAIL": "harsh@gmail.com",
    PERMISSION: "manager",
  },
];

// const useStyles = makeStyles((theme) => ({

// }));

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^(?=.*[^\s])[A-Za-z\s]+$/, "Only alphabets allowed")
    .max(50, "Full Name must be at most 50 characters")
    .required("Full Name is required"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter your work email address"),
});

function Administration() {
  const classes = useStyles();
  const [addUserButton, setAddUserButton] = React.useState(false);
  const [permission, setPermission] = React.useState("Manager");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [Id, setId] = React.useState("");
  const [userdata, setUserData] = React.useState([]);
  const [exist, setExist] = React.useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handlePopover = (event, id) => {
    setAnchorEl(event.currentTarget);
    setId(id);
    console.log(id);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handleClick = () => {
    if (Id) {
      axios
        .delete(
          "https://demo.subscribepod.com/api/v1/administration/delete-user/" +
            Id,
          {
            headers: {
              Authorization: `Bearer ${fetchToken()}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          // setUserData(res.data);
          // console.log(userdata);
          //console.log(access);
          setAnchorEl(null);
          //console.log(accessvalue);
        });
    }
  };

  // const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const columTitleRow = ["USERS", "WORK EMAIL", "PERMISSIONS", ""];
  const onChangePage = (e, nextPage) => {
    setPage(nextPage);
  };

  const onChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };
  const handlePermissionType = (event) => setPermission(event.target.value);
  const setOpen = (e) => {
    setAddUserButton(true);
  };
  const close = (e) => {
    setAddUserButton(false);
    reset();
    setExist("");
  };
  React.useEffect(() => {
    axios
      .get(
        "https://demo.subscribepod.com/api/v1/administration/get-company-administrators/",
        {
          headers: {
            Authorization: `Bearer ${fetchToken()}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setUserData(res.data);
        console.log(userdata);
        //console.log(access);
        //console.log(accessvalue);
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  }, [!addUserButton, anchorEl]);
  const onSubmit = (data) => {
    console.log(email, name, permission, data);

    if (email === "" && name === "" && permission === "") {
      return;
    } else {
      axios
        .post(
          "https://demo.subscribepod.com/api/v1/administration/add-user/",
          {
            full_name: data.name,
            email: data.email,
            permission: permission,
          },
          {
            headers: {
              Authorization: `Bearer ${fetchToken()}`,
            },
          }
        )
        .then(function (response) {
          console.log(response.message);
          setAddUserButton(false);
          if (response.message) {
            // toast.success("User Added");
            setAddUserButton(false);
          }
        })
        .catch(function (error) {
          console.log(error, "error in admin");
          setExist("User with this email already exist");
        });
    }
  };

  return (
    <Box class={classes.main}>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {
          <MenuItem
            // component={Link}
            to="/pages/profile"
            onClick={handleClick}
            role="button"
          >
            <ListItemIcon className="min-w-40">
              <Icon>delete_icon</Icon>
            </ListItemIcon>
            <ListItemText style={{ marginRight: "20px" }} primary="Remove" />
          </MenuItem>
        }
      </Popover>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div className="flex justify-between px-20 py-20 items-center">
            <Typography class={classes.heading3}>Administration</Typography>
            <Button
              variant="contained"
              class={classes.connectButton}
              onClick={setOpen}
            >
              + NEW USER
            </Button>
            <Dialog open={addUserButton} maxWidth={"sm"} scroll={"body"}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {" "}
                {/* Add the form tag here */}
                <div className="flex justify-between items-center p-12">
                  <DialogTitle>New User</DialogTitle>
                  <Button autoFocus onClick={close} color="primary">
                    <img
                      src={"assets/icones/settings/close_button.svg"}
                      alt="close"
                    />
                  </Button>
                </div>
                <DialogContent>
                  <div className="mt-12 mb-20 mx-12">
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <InputLabel className="mb-5">Full Name</InputLabel>
                        <TextField
                          name="name"
                          variant="outlined"
                          size="small"
                          style={{ width: "100%" }}
                          {...register("name")}
                          error={!!errors.name}
                          helperText={errors.name?.message}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel className="mb-5">
                          Work Email Address
                        </InputLabel>
                        <TextField
                          name="email"
                          variant="outlined"
                          size="small"
                          style={{ width: "100%" }}
                          {...register("email")}
                          error={!!errors.email}
                          helperText={errors.email?.message}
                        />
                        {exist && (
                          <Typography variant="caption" color="error">
                            {exist}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel className="mb-5">Permission</InputLabel>
                        <Select
                          // {...register("permission")}
                          value={permission}
                          borderRadius="4px"
                          margin="dense"
                          variant="outlined"
                          style={{ width: "100%" }}
                          // error={!!errors.permission}
                        >
                          <MenuItem value={"Manager"}>Manager</MenuItem>
                          <MenuItem value={"Admin"}>Admin</MenuItem>
                        </Select>
                        {errors.permission && (
                          <p className="text-red-500">
                            {errors.permission?.message}
                          </p>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </DialogContent>
                <Divider />
                <div className="p-12">
                  <DialogActions>
                    <Button
                      variant="contained"
                      class={classes.reviweBotton}
                      onClick={close}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="contained"
                      class={classes.connectButton}
                      type="submit"
                    >
                      ADD USER
                    </Button>
                  </DialogActions>
                </div>
              </form>{" "}
              {/* Close the form tag here */}
            </Dialog>
          </div>

          <Divider />
        </Grid>

        <Grid item xs={12}>
          <TableContainer className={classes.container}>
            <Table style={{ border: "1px solid #E8E6FF" }}>
              <TableHead style={{ background: "#FCFCFF" }}>
                <TableRow>
                  {columTitleRow.map((title) => (
                    <TableCell
                      key={title}
                      align={"left"}
                      padding={"normal"}
                      style={{
                        color: "#7f7e8c",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userdata.length > 0 &&
                  userdata.map((user, index) => (
                    <TableRow key={user.ID} hover style={{ color: "#575759" }}>
                      <TableCell
                        align={"left"}
                        padding={"normal"}
                        style={{ color: "#575759" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          {/* <img src='assets/icones/settings/dropdown.svg' /> */}
                          <Avatar style={{ background: "#6456F7" }}>
                          {`${user.USER.match(/(\b\S)?/g).join("").toUpperCase()}`}
                          </Avatar>
                          <div style={{ marginLeft: "8px" }}>
                            <Typography
                              style={{ color: "#6456f7", fontSize: "14px" }}
                            >
                              {user.USER}
                            </Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        align={"left"}
                        padding={"normal"}
                        style={{ color: "#575759" }}
                      >
                        <Typography style={{ fontSize: "14px" }}>
                          {user.WORK_EMAIL}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align={"left"}
                        padding={"normal"}
                        style={{ color: "#6456f7" }}
                      >
                        <Select
                          value={user.PERMISSIONS}
                          onChange={handlePermissionType}
                          disableUnderline
                          margin="dense"
                          style={{ color: "#575759" }}
                          variant="standard"
                          IconComponent={() => (
                            <img
                              src="assets/icones/settings/dropdown.svg"
                              style={{ margin: "0 0 0 -18px" }}
                            />
                          )}
                        >
                          <MenuItem value={"Manager"}>Manager</MenuItem>
                          <MenuItem value={"Admin"}>Admin</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell align={"left"} padding={"normal"}>
                        {!(user.PERMISSIONS == "Admin") && (
                          <div>
                            <MoreHorizIcon
                              onClick={(e) => handlePopover(e, user.ID)}
                              style={{ cursor: "pointer" }}
                            />
                            {/* <Popover 
                  open={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                  onClose={handlePopover}
                  >
                  The content of the Popover.
                </Popover> */}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Box display="flex" justifyContent="end" className="pt-10 pb-10">
              {/* <Pagination
                count={userdata.length}
                onChangeRowsPerPage={onChangeRowsPerPage}
              /> */}
            </Box>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Administration;
