import React from 'react'
import { alpha, Avatar, Box, Button, Divider, Grid, InputBase, InputLabel, makeStyles, styled, TextField, Typography } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
    main:{
      backgroundColor:  "#FFFFFF",
      boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
      borderRadius: "8px",
      marginTop: "30px", 
      height: "500px",
    },
    smallLabelText:{
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      linehHeight: "15px",
      letterSpacing: "0.005em",
      // marginLeft:"24px",
      color: "#575759",
      textAlign: "left",
      width:"100%"
     
    },
    sub_text:{
      // fontFamily: 'Inter',
      fontStyle: "normal",
      color:"#313133",
      fontWeight: "400",
      fontSize: "15px",
      linehHeight: "18px",
      letterSpacing: "0.0025em",
      // marginTop:"12px",
  
  /* Neutrals/Subheading */
  
     
  
    },heading3:{
      
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "23px",
      margin:"28px 40px",
/* identical to box height */


/* Neutrals/Text_Black */

      color: "#313133",

  },
    
  }));
 const settingData = [
  {
    title:'Email Notifications',
    description:'Receive email notifications about your SaaS portfolio.'
  },
  {
    title:'Requests',
    description:'Receive notifications for user requests.'
  },
  {
    title:'Recent Activities',
    description:'Receive notifications whenever someone does something.'
  },
  {
    title:'Workflows',
    description:'Receive notifications about the workflows you have created.'
  },
  {
    title:'Insights',
    description:'Receive notifications for your portfolio insights that helps you to manage it in a better way.'
  },
  {
    title:'Subscribepod Updates',
    description:'Receive notifications whenever we come up with something new.'
  },
  {
    title:'Exports',
    description:'Receive notifications whenever you export something.'
  },

 ]

function Notification() {
    const classes = useStyles();
  return (
    <Box class={classes.main}>
    <Grid container spacing={0}>
    <Grid item xs={12} >
        <Box> 
            <Typography class={classes.heading3} >Notifications</Typography>
            
        </Box>
        <Divider /> 
    </Grid>
    {settingData.map((item,key)=>
    <Grid item xs={12} lg={6} md={6} sm={12} key={key} >
        <div className='flex px-36 pt-20'>
          <div className='pt-5 min-w-max'>
            <img src={'assets/icones/settings/check.svg'} />
          </div>
          <div >
            <Typography class={classes.sub_text}>{item.title}</Typography>
            <Typography class={classes.smallLabelText}>{item.description}</Typography>
          </div>
        </div>
             
     </Grid>
      )}
      
   
     </Grid>
     </Box>
  )
}

export default Notification