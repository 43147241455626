import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Typography } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import ReviewButtonWithDialog from '../Insights/compnonets/ReviewButtonWithDialog';

const useStyles = makeStyles((theme) => ({
    card: {
        minWidth: "300px",
        height: "296px",
        background: "#FFF",
        borderRadius: "8px"
    },
    title: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "22px",
        letterSpacing: "0.0025em",
        color: "#313133",
        borderBottom: "1px solid #E8E6FF",
        padding: "12px"
    },
    container: {
        padding: "12px"
    },
    avtar: {
        display: "flex"
    },
    price: {
        fontWeight: "500",
        fontSize: "22px",
        lineHeight: "28px",
        color: "#313133",
    },
    subtitle: {
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "16px",
        letterSpacing: "0.0025em",
        color: "#7F7E8C"
    },
    titleContainer: {
        display: "flex",
        alignItems: "center",
        columnGap: "5px",
        paddingTop: "15px"
    },
    description: {
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "19px",
        letterSpacing: "0.0025em",
        color: "#575759",
        paddingTop: "15px"
    },
    button: {
        width: "82px",
        height: "40px",
        background: "#49B892",
        color: "#fff",
        borderRadius: "4px",
    }
}));

function Informationcard() {
    const classes = useStyles()
    return (
        <div className={classes.card}>
            <div className={classes.title}>
                80 inactive users for 60 days
            </div>
            <div className={classes.container}>
                <AvatarGroup max={3}>
                    <Avatar src="assets/icones/dashboard/figma.svg" />
                    <Avatar src="assets/icones/dashboard/1.svg" />
                    <Avatar src="assets/icones/dashboard/2.svg" />
                    <Avatar src="assets/icones/dashboard/3.svg" />
                    <Avatar src="assets/icones/dashboard/card_2_1.svg" />
                </AvatarGroup>
                <div className={classes.titleContainer}>
                    <Typography>$</Typography>
                    <Typography className={classes.price}>400</Typography>
                    <Typography className={classes.subtitle}>in potential savings</Typography>
                </div>
                <div>
                    <Typography className={classes.description}>We found around 80 inactive users for the past 60 days.</Typography>
                </div>
                <div className='py-24'>
                <ReviewButtonWithDialog subscriptionName='Sentry' />

                </div>
            </div>
        </div>
    )
}

export default Informationcard