import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { submitForgotPassword } from 'app/auth/store/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import _ from '@lodash';

import * as yup from 'yup';
import Otpsend from './Otpsend';
import Passwordset from './Passwordset';

const useStyles = makeStyles((theme) => ({
    login: {
        // width: "586px",
        margin: ' 0 427px',
        height: '346px'
        // height: "auto",
    },
    loginWrapper: {
        background: "#FFFFFF",
        boxShadow: "1px 1px 13px rgba(76, 72, 113, 0.1)",
        borderRadius: "8px",
        zIndex: 15,
        marginTop: '54px',
        width: '586px',
        height: '346px',
        zIndex: 15,
    },
    container: {
        // background: "#E5E5E5",
        // padding: "0 64px",
        paddingTop: '25px',
        marginLeft: '123px',
    },
    glogn: {
        background: "#FFFFFF",
        border: "1px solid #E8E6FF",
        borderRadius: "4px",
    },
    divider: {
        padding: "0 10px 0 10px"
    },
    topbutton: {
        border: "1px solid #E8E6FF",
        padding: "10px",
        borderRadius: "4px",
        color: "#313133 !important",
        width: "159px",
        height: "44px",
        textDecoration: "none !important",
        display: "flex",

    },
    title: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "25px",
        lineHeight: "36px",
        /* identical to box height */
        textAlign: "center",
        letterSpacing: "-0.02em",
        /* Neutrals/Text_Black */
        color: "#313133",
        marginBottom: "15px"
    },
    ///
    loginBtn: {
        background: "#49B892",
        borderRadius: "4px",
        color: "white",
        height: '50px'
    },
    ///
    text: {
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "22px",
        /* or 22px */

        textAlign: "center",
        letterSpacing: "0.0025em",

        /* Neutrals/Subheading */

        color: "#575759",

    },
    headerText: {
        fontSize: "24px",
        lineHeight: "31px",
        letterSpacing: "-0.01em",
        fontStyle: "normal",
        color: "#6456F7",
        // fontFamily: 'Mail Sans Roman',
        fontWeight: 500,
    },
    headerTextHeighlight: {
        fontSize: "24px",
        lineHeight: "31px",
        letterSpacing: "-0.01em",
        fontStyle: "normal",
        color: "#49B892",
        // fontFamily: 'Mail Sans Roman',
        fontWeight: 500,
    }
}));
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    email: yup.string().email('You must enter a valid email').required('You must enter a email'),
});

const defaultValues = {
    email: '',
};
function Forgotpassword() {
    const classes = useStyles()
    const dispatch = useDispatch();
    const login = useSelector(({ auth }) => auth.login);
    const [forgot, setForot] = useState(true)
    const [otp, setOtp] = useState(false)
    const [password, setPassword] = useState(false)
    const { control, setValue, formState, handleSubmit, reset, trigger, setError } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;

    useEffect(() => {
        setValue('email', 'admin@fusetheme.com', { shouldDirty: true, shouldValidate: true });
    }, [reset, setValue, trigger]);

    useEffect(() => {
        login.errors.forEach((error) => {
            setError(error.type, {
                type: 'manual',
                message: error.message,
            });
        });
    }, [login.errors, setError]);

    const setpass = () => {
        setForot(false)
        setOtp(false)
        setPassword(true)
    }

    function onSubmit(model) {
        dispatch(submitForgotPassword(model));
        // API OTP
        // console.log("first")
        setForot(false)
        setOtp(true)
        setTimeout(() => {
            // setOtp(false)
            setpass()
        }, 2000)

    }
    const changeEmail = () => {
        setForot(true)
        setOtp(false)
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         setOtp(false)
    //     }, 2000)
    // }, [otp])
    return (
        <div className={classes.container}>
            <div className='flex justify-between w-full'>
                <div className='flex'>
                    <img src="assets/icones/logo/Logo_short.svg" />
                    <div className={classes.headerText}>Subscribe<span className={classes.headerTextHeighlight}>pod</span>
                    </div>
                </div>
            </div>
            {forgot && <div className={`flex w-full h-full justify-center items-center `}>
                <div className={`${classes.login} p-28  ${classes.loginWrapper} items-center`}>
                    <Typography className={classes.title}>Forgot your password?</Typography>
                    <Typography className='flex text-center px-56 mt-8 h-44 w-474 mb-32'>Don’t worry! it happens. We’ll send you a
                        password reset link to this email.</Typography>
                    <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <InputLabel className='mb-4'>Business Email</InputLabel>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mb-16"
                                        fullWidth
                                        type="email"
                                        error={!!errors.email}
                                        helperText={errors?.password?.eamil}
                                        variant="outlined"
                                        required
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                className={`w-full mx-auto mt-16 ${classes.loginBtn}`}
                                aria-label="CONTINUE"
                                disabled={_.isEmpty(dirtyFields) || !isValid}
                                value="legacy"
                            >
                                CONTINUE
                            </Button>
                        </div>
                    </form>
                </div>
            </div>}
            {otp && <Otpsend changeEmail={changeEmail} setpass={setpass} />}
            {password && <Passwordset />}
            <img src='assets/icones/iconography/bottom-curve.png' width='100%'
                style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    zIndex: 10
                }}
            />
        </div>
    )
}

export default Forgotpassword