import React, { useEffect, useState } from "react";
import Card from "./Card";
import Informationcard from "./Informationcard";
import { Topapp, TopRedunt } from "./Topapp";
import useTour from "reactour";
import Grid from "@material-ui/core/Grid";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import Upcoming from "./Upcoming";
import { Subscriptions, Category } from "./BottomChart";
import { useHistory, Redirect } from "react-router-dom";
import Tour from "reactour";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";
import axios from "axios";
import { fetchToken } from "../auth/auth";
import { mdiSortAlphabeticalAscending } from "@mdi/js";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "500",
    fontSize: "18px",
    color: "#313133",
  },
  infocard: {
    background: "#E9F8F3",
  },
  recentInsights: {
    margin: "30px 0",
    padding: "40px 0px 48px 52px",
    background: "#E9F8F3",
    /* Elevation1 */

    boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
  },
  buttonClose: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "9px 16px",
    width: "135px",
    height: "60px",
    background: "#49B892",
    color: "#FFFFFF",
    borderRadius: "4px",
  },
  dialog: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
}));
const subscriptionsChartData = {
  series: [21, 23, 19, 14, 6],
  series2: [1200, 900, 600, 400, 200],
  labels: ["Design ", "Developing", "Marketing", "IT", "Accounts"],
};
const categoryChartData = {
  series: [21, 23, 19, 14, 6],
  series2: [1200, 900, 600, 400, 200],
  labels: [
    "Productivity ",
    "Collaboration",
    "Operations",
    "Design",
    "Accounts",
  ],
};

function Dashboard({ isShow, openTour, toggleShowMore, IsShowingMore }) {
  const isAuthenticated = localStorage.getItem("token") !== null;
  // const { setIsOpen } = useTour()
  const [show, setShow] = useState(false);
  const [tour, setTour] = useState(true);
  const [team, setTeam] = useState([]);
  const [usage,setUsage]= useState([]);
  const [spending,setSpending] =useState([]);
  const [sub, setSub] = useState([]);
  const [subusage,setSubUsage]= useState([]);
  const [subspending,setSubSpending] =useState([]);
  const [totalusers,setTotalUsers] = useState([]);
  const [totalapps,setapps] = useState([]);

  const getApps = ()=>{
    axios.get('https://demo.subscribepod.com/api/v1/subscriptions/get-total-apps/', 
    {
      headers: {
        Authorization: `Bearer ${fetchToken()}`,
      },
    },

    )
    .then(function (response) {
      console.log(response.data, "app set");
      if (response.data) {
        setapps(response.data);
        // localStorage.setItem("apps",response.data.Total_apps)

        console.log(totalapps);
      
      }
    })
    .catch(function (error) {
      console.log(error, "error");
    });
  }

  const getUsers = ()=>{
    axios.get('https://demo.subscribepod.com/api/v1/subscriptions/get-total-users/', 
    {
      headers: {
        Authorization: `Bearer ${fetchToken()}`,
      },
    },

    )
    .then(function (response) {
      console.log(response.data, "user get");
      if (response.data) {
        // localStorage.setItem("users",response.data.Total_users)
        setTotalUsers(response.data);

        console.log(totalusers);
      
      }
    })
    .catch(function (error) {
      console.log(error, "error");
    });
  }
  useEffect(() => {
    const team_name = [];
    const usage = [];
    const spending=[];
    axios
      .get('https://demo.subscribepod.com/api/v1/operations/get_team_usage', {
        headers: {
          Authorization: `Bearer ${fetchToken()}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        res.data.map(t=>{
          if(t.usage !== 0){

            team_name.push(t.team_name);
            usage.push(
              parseFloat(parseFloat(t.usage).toFixed(2))
            );
            spending.push(
              parseFloat(parseFloat(t.spending).toFixed(0))
            );
            }
            setTeam(team_name);
            setSpending(spending);
            setUsage(usage);

        })

        // setCompanyName(res.data.company_name);
        // setValue("companyname", res.data.company_name || "");
        // setCompanyId(res.data.id);
        // console.log(rows);
      });
  }, []);


  useEffect(() => {
    getApps()
    getUsers()
   // getAnnualSpend()
    // getUsage()
   
  },[])

  useEffect(() => {
    const sub_name = [];
    const usage = [];
    const spending=[];
    axios
      .get('https://demo.subscribepod.com/api/v1/operations/get_subscription_usage', {
        headers: {
          Authorization: `Bearer ${fetchToken()}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        res.data.map(t=>{
          if(t.usage !== 0){

            sub_name.push(t.subscription_name);
            usage.push(
              parseFloat(parseFloat(t.usage).toFixed(2))
            );
            spending.push(
              parseFloat(parseFloat(t.spending).toFixed(0))
            );
            }
            setSub(sub_name);
            setSubSpending(spending);
            setSubUsage(usage);

        })

        // setCompanyName(res.data.company_name);
        // setValue("companyname", res.data.company_name || "");
        // setCompanyId(res.data.id);
        // console.log(rows);
      });
  }, []);

  const TeamsChart = {
    series: usage,
    series2: spending,
    labels: team,
  };

  const SubscriptionsChart = {
    series: subusage,
    series2: subspending,
    labels: sub,
  };
  const wrapperFunction = () => {
    handleClose();
    handleTour();
  };


  const handleClose = () => {
    setShow(false);
    enableBodyScroll;
  };
  const handleTour = () => {
    setTour(openTour);
  };
  const classes = useStyles();
  const history = useHistory();
  const handleShowMore = () => history.push("/subscribe/insight");

  if (isAuthenticated) {
    return (
      <>
        <Box style={{width:"80vw"}}>
        {/* <Popup ></Popup> */}
        <Dialog open={show} disableScrollLock>
          <div class={classes.dialog}>
            <DialogTitle>Welcome to your dashboard</DialogTitle>
            <DialogContent>
              <DialogContentText>
                We're glad to have you onboard. Please take a tour to get you up
                and running
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                class={classes.buttonClose}
                style={{ background: "white", color: "black" }}
              >
                SKIP FOR NOW
              </Button>
              <Button
                variant="contained"
                class={classes.buttonClose}
                color="primary"
                onClick={wrapperFunction}
              >
                TAKE A TOUR
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        {/* <div>
         <Button variant="contained" class={classes.buttonClose} color="primary" onClick={(openTour)}>
           TAKE A TOUR
          </Button>
      </div> */}

        <div
          data-tut="first_step"
          className="flex gap-x-28 justify-between px-52"
          
        >
          <Card icon="wallet" total={138} text="Total Annual Spend" />
          <Card icon="total_users"  total={totalusers.Total_users} text="Total Users" />
          <Card icon="subscribtions" total={totalapps.Total_apps} text="Total Subscriptions" />
        </div>
        <div
          data-tut="second_step"
          className={`no-scrollbar::-webkit-scrollbar ${classes.recentInsights}`}
        >
          <div className="px-0 pb-24">
            <div className="flex item-center justify-between">
              <Typography className={classes.title}>Recent Insights</Typography>
              {/* <Button startIcon={<img src="assets/icones/dashboard/rightside.svg" />} onClick={handleShowMore}>Show More</Button> */}
            </div>
          </div>
          <div className={`flex gap-x-20 overflow-x-auto`}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography className={classes.title}>
                No insights to show
              </Typography>
              {/* <Informationcard />
          <Informationcard />
          <Informationcard />
          <Informationcard />
          <Informationcard /> */}
            </div>
          </div>
        </div>
        <Grid container className="pt-20 px-52" spacing={3} >
          <Grid data-tut="third_step" item xs={12}>
            <Topapp />
          </Grid>
          {/* <Grid data-tut="fourth_step" item xs={4}>
            <TopRedunt />
          </Grid> */}
        </Grid>
        <div data-tut="fifth_step" className="pt-20 w-full px-52">
    
          <Upcoming />
        </div>
        <Grid
          data-tut="sixth_step"
          container
          className="pt-20 full px-52"
          spacing={3}
        >
          <Grid item xs={6}>
            <Subscriptions name="Teams" data={TeamsChart} />
          </Grid>
          <Grid item xs={6} >
            <Subscriptions name="Subsciptions" data={SubscriptionsChart} />
          </Grid>
        </Grid>
        </Box>
      </>
    );
  } else {
    // Redirect to the login page when not authenticated
    return <Redirect to="/login" />;
  }
}
export default Dashboard;
