import React from "react";
import { authRoles } from 'app/auth';

const IntegrationsConfig = {
  settings: {
    layout: {
      config: {
        navTitle:"Integrations"
      },
    },
  },
  routes: [
    {
      path: "/subscribe/integrations",
      // auth: authRoles.admin,
      component: React.lazy(() => import("./Integrations")),
    },
  ],
};

export default IntegrationsConfig;