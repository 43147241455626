import React from "react";

const UsersConfig = {
  settings: {
    layout: {
      config: {
        navTitle: 'Vishal Soni',
        backButton: true,
        toolbarColor: '#FFFFFF',
        ispadding: false
      },
    },
  },
  routes: [
    {
      path: "/users/details",
      component: React.lazy(() => import("./UsersDetails")),
    },
  ],
};

export default UsersConfig;