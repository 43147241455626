import React from "react";

const WorkflowsConfig = {
  settings: {
    layout: {
      config: {
        navTitle:"Workflows",
        toolbarColor:"#FFFFFF",
        ispadding:false
      },
    },
  },
  routes: [
    {
      path: "/subscribe/workflows/workflow",
      component: React.lazy(() => import("./Workflows")),
    },
  ],
};

export default WorkflowsConfig;