import React from 'react';

const TeamConfig = {
  settings: {
    layout: {
      config: {
        navTitle: 'Teams',
        ispadding: false,
        toolbarColor: '#FFFFFF',
      },
    },
  },
  routes: [
    {
      path: '/subscribe/team',
      component: React.lazy(() => import('./Team')),
    },
  ],
};

export default TeamConfig;
