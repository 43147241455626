import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography,Avatar } from '@material-ui/core';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ReactApexChart from "react-apexcharts";
import { set } from "date-fns";
import { imageListClasses } from "@mui/material";
import HTMLReactParser, { htmlToDOM } from "html-react-parser";
import { element } from "prop-types";

const useStyles = makeStyles((theme) => ({
  topCard: {
    height: "480px",
    boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
    borderRadius: "8px",
    background: "#FFFFFF",
  },
  toolBarContainer: {
    padding: "10px 14px",
  },
  price: {
    fontWeight: "500",
    fontSize: "22px",
    lineHeight: "28px",
    color: "#313133",
},
percentDown: {
    color: "green"
},
userContainer: { padding: "0px 24px 0px 24px" },
userContainer2: { padding: "0px 24px 0px 24px" },

title: {
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "16px",
    letterSpacing: "0.0025em",
    color: "#575759",
},
  topTitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.0025em",
    color: "#313133",
  },
  TopRedunt: {
    height: "480px",
    boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
    borderRadius: "8px",
    background: "#FFFFFF",
  },
  toggaleGroup: {
    height: "32px",
  },

  topReduntChart: {
    padding: "15px 0px 10px 20px",
    //textAlign: "left"
  },
}));

export function Topapp() {
  const [istoggleUnused, setToggleUnused] = useState(true);
  const [istoggleMulti, setToggleMulti] = useState(false);
  const [istoggleSpending, setToggleSpending] = useState(false);
  const classes = useStyles();
  const [alignment, setAlignment] = useState("most-unused");
  const chartData = {
    series: [
      {
        data: [13,13,13,33,33,33,0,0,0,0,0,0]
      },
    ],
    chart: {
      type: "bar",
      id: "column-chart",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // bottom/center/top
        },
        columnWidth: "40%",
      },
    },

    colors: ["#8378F9"],
    grid: {
      strokeDashArray: 10,
    },
    tooltip: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "$";
      },
      offsetY: -20,
      position: "top",
      style: {
        fontSize: "12px",
        fontWeight: "400",
        colors: ["#483EB2"],
      },
      // style: {
      //     fontSize: '14px',
      //     fontFamily: 'Helvetica, Arial, sans-serif',
      //     fontWeight: 'bold',
      //     colors: undefined
      // },
    },
    xaxis: {
      categories:  ['Jan','Feb','Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dec'],
      labels: {
        show: true,
       
        formatter: function (val) {
        val=showImage(val)
        return val;
        
          // const tag = document.createElement("div")
          
        
          // return '<tag style={{background:"red"}}>'+'</tag>'
          //        }
        }
      // legends:{
      //   show:true,
      //   formatter: function (num=0) {
      //     return '<div>'+[num]+'</div>'
      //   }

      // },
    },
  },
}

  const showImage =(val)=>{
         const tag = document.createElement("div")
          
        
          return [val]
        

  };


  const handletoggleUnused = () => {
    setToggleUnused(true);
    setToggleMulti(false);
    setToggleSpending(false);
  };
  const handletoggleMulti = () => {
    setToggleMulti(true);
    setToggleUnused(false);
    setToggleSpending(false);
  };
  const handletoggleSpending = () => {
    setToggleSpending(true);
    setToggleUnused(false);
    setToggleMulti(false);
  };
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  return (
    <div className={classes.topCard}>
      <div
        className={`flex justify-between border-b items-center ${classes.toolBarContainer}`}
      >
        <div className={classes.topTitle}>Total Spending</div>
        <div>
          <ToggleButtonGroup
            className={classes.toggaleGroup}
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton
              value="most-unused"
              onClick={handletoggleUnused}
              style={{
                backgroundColor: istoggleUnused ? "#E8E6FF" : "",
                color: istoggleUnused ? "#483EB3" : "",
              }}
              aria-label="most-unused"
            >
              Most Unused
            </ToggleButton>
            <ToggleButton
              value="multi-source-spend"
              onClick={handletoggleMulti}
              style={{
                backgroundColor: istoggleMulti ? "#E8E6FF" : "",
                color: istoggleMulti ? "#483EB3" : "",
              }}
              aria-label="multi-source-spend"
            >
              Multi-source Spend
            </ToggleButton>
            <ToggleButton
              value="spending"
              onClick={handletoggleSpending}
              style={{
                backgroundColor: istoggleSpending ? "#E8E6FF" : "",
                color: istoggleSpending ? "#483EB3" : "",
              }}
              aria-label="spending"
            >
              Spending
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className="py-40">
        <div>
          <div className={`flex items-center gap-x-4 ${classes.userContainer}`}>
            
            <Typography className={classes.price}>138</Typography>
            <Typography className={classes.price}>$</Typography>
            {/* <img src="assets/icones/subscription/greenArrow.svg" />
            <Typography className={classes.percentDown}>5%</Typography>*/}
          </div> 
          <div>
            <Typography className={`classes.title ${classes.userContainer2}`}>Spending</Typography>
          </div>
        </div>
        <>
        <ReactApexChart
          height="350px"
          options={chartData}
          type="bar"
          series={chartData.series}
          xaxis="1"
         
    />
        </>
        </div>
      </div>
    
  );
}

export function TopRedunt() {
  const classes = useStyles();
  const chartData = {
    series: [
      {
        data: [70, 43, 44, 47, 54, 58, 69, 10, 20, 60],
      },
    ],
    chart: {
      type: "bar",
      height: 450,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top",
          offsetX: 100,
        },
        horizontal: true,
        columnHeight: "0%",
      },
    },

    colors: ["#8378F9"],
    grid: {
      strokeDashArray: 10,
    },
    tooltip: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "%";
      },
      offsetX: 25,
      position: "top",
      style: {
        fontSize: "12px",
        fontWeight: "400",
        colors: ["#483EB2"],
      },
    },
    xaxis: {
      type: "category",
      categories: [
        "Online Training Classes  ",
        "Team Collaboration       ",
        "Other Data as a Service  ",
        "Project Management       ",
        "Business Intelligence    ",
        "Governance, Risk & Compl.",
        "Sales Intelligence       ",
        "Application Performance .",
        "Corporate LMS            ",
        "Infrastructure As a Serv.",
      ],

      labels: {
        style: {
          fontSize: "8px",
          fontWeight: 500,
        },
        position: "left",
      },

      cssClass: "w-44",
    },
  };

  //make the output as text
  // var output = "<p>" + label + "</p><p>" + sy + " - " + ey + "</p>";

  // //create and add the element to the HTML
  // var outputElement = document.createElement("div");
  // outputElement.innerHTML = output;
  // document.body.appendChild(outputElement);
  return (
    <div className={classes.TopRedunt}>
      <div className="flex justify-between border-b p-16 ">
        <div className={classes.topTitle}>
          Top 10 Redundant Functions by spend
        </div>
      </div>
      <div className={classes.topReduntChart}>
        <ReactApexChart
          height="350px"
          width="90%"
          options={chartData}
          series={chartData.series}
          type="bar"
        />
      </div>
    </div>
  );
}
