import React, {useState} from 'react'
import {Box, Button, Typography, makeStyles} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Tour from 'reactour'

const useStyles = makeStyles((theme) => ({
    buttonClose: {
        boxSizing: "border-box", display: "flex", alignItems: "center", justifyContent: "center",
        padding: "9px 16px",
        width: "135px",
        height: "60px",
        background: "#49B892",
        color: "#FFFFFF",
        borderRadius: "4px",
      },
    title: {
        fontSize: "20px",
    },
    dialog: {
        display: "flex",
        justifyContent: "center",
        flexDirection:"column",
        alignItems: "center",
    }
})
)

const Popup = ({ openTour, isShowingMore, toggleShowMore }) => { 
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const classes = useStyles();
  return (
    < >
    <Dialog open={show}  >
      <div class={classes.dialog}>
        <DialogTitle >
           Welcome to your dashboard
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            We're glad to have you onboard. Please take a tour to get you up and running
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} class={classes.buttonClose} style={{ background: "white", color: "black" }}>
           SKIP FOR NOW
          </Button>
          <Button variant="contained" class={classes.buttonClose} color="primary" onClick={openTour}>
           TAKE A TOUR
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    </>
  )
}

export default Popup
