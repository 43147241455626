import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { submitForgotPassword } from 'app/auth/store/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import _ from '@lodash';
import * as yup from 'yup';
const useStyles = makeStyles((theme) => ({
    container: {
        // background: "#E5E5E5"
    },
    text: {
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "22px",
        /* or 22px */

        textAlign: "center",
        letterSpacing: "0.0025em",

        /* Neutrals/Subheading */

        color: "#575759",
        padding: '10px 128px'

    },
    imageSize: {
        width: "110px",
        height: "135px",
    },
    afterdetailHeading: {
        color: "#313133",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "22px",
        lineHeight: "28px",
        textAlign: "center",
    },
    fogot: {

        /* Neutrals/Text_White */
        background: "#FFFFFF",
        /* Elevation2 */
        boxShadow: "1px 1px 13px rgba(76, 72, 113, 0.1)",
        borderRadius: "8px",
    },
    login: {
        // width: "586px",
        margin: ' 0 427px',
        // height: "auto",
    },
    loginWrapper: {
        background: "#FFFFFF",
        boxShadow: "1px 1px 13px rgba(76, 72, 113, 0.1)",
        borderRadius: "8px",
        display: "flex",
        marginTop: '54px',
        width: '586px',
        height: '346px',
        zIndex: 15,
    },
    changeEmailText: {
        color: '#49B892'
    }
}));
function Otpsend(props) {
    const classes = useStyles()

    return (
        <div className='flex justify-center items-center'>
            <div className={`${classes.login} p-28 flex justify-center items-center  ${classes.loginWrapper}`}>
                <div className={` flex flex-col justify-center w-full`}>
                    <div className='flex justify-center items-center'>
                        <img className={classes.imageSize} src="assets/icones/logo/Logo_short.svg" />
                    </div>
                    <div className={classes.afterdetailHeading}>Please check your email</div>
                    <div className={`text - center px - 56 mt - 28 ${classes.text} `}>We’ve sent the password reset link to ********soni@jmdstudio.com  <span className={`${classes.changeEmailText} cursor-pointer`} onClick={props.changeEmail}>Change email</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Otpsend