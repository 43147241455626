import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Divider, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { AvatarGroup } from '@mui/material';
import ColumnCharts from './ColumnCharts';
const useStyles = makeStyles((theme) => ({
    upcomingContiner: {
        width:"100%",
        height: "200px",
        boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
        borderRadius: "8px",
        background: "#FFFFFF"
    },
    column: {
        height: "100%"
    },
    header:{
        fonteight: "500",
        fontSize: "18px", 
        lineHeight: "23px",
        color: "#313133"

    },
   
    toolBarContainer: {
        padding: "10px 14px",
        height: "75px"
    },
}));
const arr = [
    {
        month: "jan",
    },
    {
        month: "feb",
    },
    {
        month: "mar",
    },
    {
        month: "apr",
    },
    {
        month: "may",
    },
    {
        month: "jun",
    },

]
function Upcoming() {
    const classes = useStyles()
    return (
        <div className={classes.upcomingContiner}>
            <div className='p-20 inline-block'>
                <div className={classes.header}>Upcoming Renewals</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography className={classes.header}>
                No upcoming renewals
              </Typography>
              </div>

            {/* <div className='flex justify-items-center w-full'>
                 <ColumnCharts/>
            </div> */}
        </div>
    )
}

export default Upcoming