import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({

    transition: {
        transform: "rotate(180deg)"
    },
    headerText: {
        fontSize: "24px",
        lineHeight: "31px",
        letterSpacing: "-0.01em",
        fontStyle: "normal",
        color: "#6456F7",
        fontWeight: 500,
    },
    container: {
        background: "#FFFFFF", boxShadow: "1px 1px 13px rgba(76, 72, 113, 0.1)",
        borderRadius: "8px",
        padding: "40px",
        marginTop: "32px",
        width: "780px"
    },
    title: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "28px",
        letterspacing: "-0.02em",
        color: "#313133",
    },
    mainNote: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "26px",
        color: "#313133",
    },
    note: {
        fontWeight: "400",
        fontSize: "14px",
        letterSpacing: "0.0025em",
        color: "#575759",
        maxWidth: "450px",
        paddingTop: "6px"
    },
    cardContainer: {
        background: "#FFFFFF",
        border: "1px solid #E8E6FF",
        borderRadius: "4px",
        height: "208px",
        width: "250px",
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
    },
    button: {
        background: "#49B892",
        borderRadius: "4px",
        color: "white"
    },
    addData: {
        background: "#FFFFFF",
        boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
        borderRadius: "8px",
        marginTop: "30px",
        padding: "30px",
        width: "780px"
    },
    cardTitle: {
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "18px",
        textAlign: "center",
        letterSpacing: "0.0025em",
        color: "#313133"
    },
    gotoDash: {
        marginTop: "30px",
    }
}));
function Card() {
    const classes = useStyles()
    return <div className={classes.cardContainer}>
        <div className='flex items-center justify-center'>
            <div className='gap-y-28'>
                <div className='flex justify-center'>
                    <img className='h-56  w-56' src="assets/icones/dashboard/Rectangle.svg" />
                </div>
                <div className='pt-28'>
                    <Typography className={classes.cardTitle}>SAP Concur</Typography>
                </div>
            </div>
        </div>
    </div>
}
function Onboarding() {
    const classes = useStyles()
    const history = useHistory();
    const [more, setMore] = useState(false)
    const [emp, setEmp] = useState(false)
    const handleMore = () => {
        setMore(!more)
    }
    const handleEmp = () => {
        setEmp(!emp)
    }
    return (
        <div className='flex justify-center py-24 w-full'>
            <div>
                <Typography className={classes.title}>Getting started with Subscribepod</Typography>
                <div className={classes.container}>
                    <div className='flex w-full justify-between items-center'>
                        <div className='flex items-center justify-start gap-x-28'>
                            <img src="assets/icones/dashboard/wallate.svg" />
                            <div>
                                <Typography className={classes.mainNote}>Connect your Expense account</Typography>
                                <Typography className={classes.note}>Please connect your expense management software with us to help monitor all of your subscriptions and payments.</Typography>
                            </div>
                        </div>
                        <div className='cursor-pointer' onClick={() => handleMore()}>
                            <img className={more && classes.transition} src="assets/icones/dashboard/more.svg" />
                        </div>
                    </div>
                    <Divider className='mt-28 mb-28' />
                    {more &&
                        <div>
                            <div className='flex gap-x-20'><Card /><Card /><Card /></div>
                            <div className='pt-20'>
                                <Button variant='contained' className={classes.button}>CONNECT</Button>
                            </div>
                        </div>
                    }

                </div>
                <div className={classes.addData}>
                    <div className='flex justify-between items-center'>
                        <div className='flex justify-start gap-x-20 items-center'>
                            <div>
                                <img className='h-56  w-56' src="assets/icones/dashboard/empoy.svg" />
                            </div>
                            <div>
                                <Typography className={classes.mainNote}>Add Employee data</Typography>
                                <Typography className={classes.note}>Please connect your employee management software with us
                                    to onboard your employees easily.</Typography>
                            </div>
                        </div>
                        <div className='cursor-pointer' onClick={() => handleEmp()}>
                            <img className={emp && classes.transition} src="assets/icones/dashboard/more.svg" />
                        </div>
                    </div>
                    <Divider className='mt-28 mb-28' />
                    {emp &&
                        <div>
                            <div className='flex gap-x-20'><Card /><Card /><Card /></div>
                            <div className='pt-20'>
                                <Button variant='contained' className={classes.button}>CONNECT</Button>
                            </div>
                        </div>
                    }
                </div>
                <div className={`${classes.gotoDash} flex w-full justify-center items-center`}>
                    <Button onClick={() => history.push('/subscribe/dashboard')} variant='outlined' className={classes.button}>Go To Dashboard</Button>
                </div>
            </div>
        </div >
    )
}

export default Onboarding