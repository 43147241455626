import React from "react";
import { RequireToken } from "../auth/auth";
import Subscriptions from "./Subscriptions"

const SubscriptionsConfig = {
  settings: {
    layout: {
      config: {
        navTitle: "Subscriptions",
        toolbar: {
          display: false
        }
      },
    },
  },
  routes: [
    {
      path: "/subscribe/subscription",
      component: () => <RequireToken> <Subscriptions /> </RequireToken>
    },
  ],
};

export default SubscriptionsConfig;