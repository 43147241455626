import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  Box
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Popover from "@material-ui/core/Popover";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import axios from "axios";
import { fetchToken } from "../auth/auth";
import { useEffect } from "react";
import { useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubsciptionContext } from "./Subscriptions";

const columns = [
  {
    id: "subscription",
    align: "left",
    disablePadding: false,
    label: "Name",
    sort: true,
  },
  {
    id: "anualSpend",
    align: "left",
    disablePadding: false,
    label: "ANUAL SPEND",
    sort: true,
  },
  {
    id: "users",
    align: "left",
    disablePadding: false,
    label: "USERS",
    sort: true,
  },
  {
    id: "usage",
    align: "left",
    disablePadding: false,
    label: "USAGE",
    sort: true,
  },
  {
    id: "licenceType",
    align: "left",
    disablePadding: false,
    label: "LICENCE TYPE",
    sort: true,
  },
  {
    id: "renewal",
    align: "center",
    disablePadding: false,
    label: "RENEWAL",
    sort: true,
  },
];

const data = [
  {
    id: 1,
    url: "assets/icones/subscribe/figm.svg",
    name: "Figma",
    anualSpend: 1200,
    users: 80,
    usage: 89.28,
    licenceType: 10,
    renewal: new Date(),
  },
  {
    id: 2,
    url: "assets/icones/subscribe/figm.svg",
    name: "Basecamp",
    anualSpend: 1232,
    users: 85,
    usage: 98.28,
    licenceType: 50,
    renewal: new Date(),
  },
  {
    id: 3,
    url: "assets/icones/subscribe/figm.svg",
    name: "Confluence",
    anualSpend: 8792,
    users: 325,
    usage: 89.58,
    licenceType: 32,
    renewal: new Date(),
  },
  {
    id: 4,
    url: "assets/icones/subscribe/figm.svg",
    name: "ZohoBooks",
    anualSpend: 2148,
    users: 100,
    usage: 88,
    licenceType: 25,
    renewal: new Date(),
  },
  {
    id: 5,
    url: "assets/icones/subscribe/figm.svg",
    name: "Adobe",
    anualSpend: 2541,
    users: 58,
    usage: 36,
    licenceType: 21,
    renewal: new Date(),
  },
];
const useStyles = makeStyles((theme) => ({
  datatableContainer: {
    background: "#FFFFFF",
    boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
    borderRadius: "8px",
  },
  tableHeaderText: {
    color: "#7F7E8C",
  },
  button: {
    justifyContent: "center",
    alignItems: "center",
    padding: "9px 16px 9px 12px",
    columnGap: "4px",
    width: "176px",
    height: "40px",
    background: "#49B892",
    borderRadius: "4px",
    display: "flex",
    color: "#fff",
  },
  squarebutton: {
    width: "40px",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid #7F7E8C",
  },
  blueColor: {
    color: "#483EB3",
  },
  tableTitle: {
    fontWeight: 400,
    fontSize: "14px",
    letterSpacing: "0.0025em",
    color: "#575759",
    fontFamily: "Inter",
  },
  remaintime: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#CC2525",
    fontFamily: "Inter",
  },
  progresscolor: {
    background: "#92D4BE",
    color: "red",
    height: "6px",
    borderRadius: "10px",
  },
  bgcolr: {
    background: "#8378F9",
  },

  title: {
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Mail Sans Roman",
    color: "#313133",
  },
  typography: {
    padding: theme.spacing(2),
  },
  trstfield: {
    ".MuiInput - underline - 274: before": {
      borderBottom: "none !important",
    },
    ".MuiInput - underline - 274: after": {
      borderBottom: "none !important",
    },
  },
  radio: {
    '&$checked': {
      color: '#6456F7'
      
    }
  },
  checked: {},
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  mailSansRoman: {
    fontFamily: "Mail Sans Roman",
  },
  Inter: {
    fontFamily: "Inter",
  },
}));


const validationSchema = yup.object().shape({
  // subscriptionName: yup.string().matches(/^(?=.*[A-Za-z0-9])[A-Za-z0-9\s]+$/, "Subscription name should not include only spaces").required('Subscription name is required'),
  vendor: yup.string().required('Vendor is required'),
  url: yup.string().matches(
    /^(https?:\/\/)?([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/,
    "Invalid URL format"
  )
  .required("Url is required"),
  // authentication: yup.string().required('Authentication is required'),
  apiKey: yup.string().required('API Key is required'),
});

function Datatable() {
  const classes = useStyles();
  const history = useHistory();
  const [rows,setRows]=useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [subscriptionName, setSubscriptionName] = useState("");
  const [vendor, setVendor] = useState("");
  const [subsciptionUrl, setSubscriptionUrl] = useState("");
  const [authentication, setAuthentication] = useState("API Token");
  const [selectedOption, setSelectedOption] = useState("Slack");
  const {getdata,setGetData} = React.useContext(SubsciptionContext);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [apiKey, setApiKey] = useState("");
  const [open, setOpen] = useState(false);
  const onChangePage = (e, nextPage) => {
    setPage(nextPage);
  };

  const {  register,handleSubmit, control, reset, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    // Handle form submission
    console.log("selectedoption")
    console.log(data);

    if(data) {
      axios
        .post("https://demo.subscribepod.com/api/v1/subscriptions/add-subscription/", {
          subscription_name: selectedOption,
          vendor: data.vendor,
          url: data.url,
          authentication: authentication,
          api_key: data.apiKey,
        }
        ,{
          headers: {
            Authorization: `Bearer ${fetchToken()}`,
          },
        })
        .then(function (response) {
          console.log(response.data);
          setOpen(false)
          setGetData(false)
          // if (response.data.message) {
          //   toast.success("subscription Added");
          //   handleClose();
          // }
        })
        .catch(function (error) {
          console.log(error, "error ");
        });
      
    }
  };

  useEffect(() => {
 
    axios.get('https://demo.subscribepod.com/api/v1/subscriptions/get-all-subscriptions-detail/', {
      headers: {
        Authorization: `Bearer ${fetchToken()}`,
      },
    }).then((res) => {
      console.log(res.data);
        setRows(res.data);
        console.log(rows);
})
},[open,getdata])


  const onChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleSubmitSubscription = () => {
  //   console.log(
  //     // subscriptionName,
  //     // vendor,
  //     // subsciptionUrl,
  //     // authentication,
  //     // apiKey
  //   );
  //   if (
  //     (subscriptionName === "") &
  //     (vendor === "") &
  //     (subsciptionUrl === "") &
  //     (authentication === "") &
  //     (apiKey === "")
  //   ) {
  //     return;
  //   } 
  //   else {
  //     axios
  //       .post("https://demo.subscribepod.com/api/v1/subscriptions/add-subscription/", {
  //         subscription_name: subscriptionName,
  //         vendor: vendor,
  //         url: subsciptionUrl,
  //         authentication: authentication,
  //         api_key: apiKey,
  //       }
  //       ,{
  //         headers: {
  //           Authorization: `Bearer ${fetchToken()}`,
  //         },
  //       })
  //       .then(function (response) {
  //         console.log(response.data);
  //         handleClose();
  //         if (response.data.message) {
  //           toast.success("subscription Added");
  //           handleClose();
  //         }
  //       })
  //       .catch(function (error) {
  //         console.log(error, "error ");
  //       });
      
  //   }
  // };

  const handleClose = () => {
    setOpen(false);
    setGetData(false)
    reset();
  };

  const [value, setValue] = React.useState("female");
  const [detail, setDetail] = React.useState({
    status: false,
    anchorEl: "",
    data: "",
  });

  const handleDeatil = (e) => {
    setDetail((prev) => ({
      ...prev,
      status: !detail.status,
      anchorEl: e.currentTarget,
    }));
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const Poper = () => {
    return (
      <div className=" w-160 ">
        <div className="flex p-3 w-full items-center justify-between">
          <div className="flex items-center gap-x-7">
            <div className={`${classes.bgcolr} w-10 h-10 rounded-2`} />
            <Typography className="text-black">Pro</Typography>
          </div>
          <Typography className="text-grey-600">10 left</Typography>
        </div>
        <hr />
        <div className="flex p-3 w-full items-center justify-between">
          <div className="flex items-center gap-x-7">
            <div className={`${classes.progresscolor} w-10 h-10 rounded-2`} />
            <Typography className="text-black">Basic</Typography>
          </div>
          <Typography className="text-grey-600">10 left</Typography>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.datatableContainer}>
      <div className="flex justify-between items-center p-20">
        <div>
          <TextField
            className={`w-256 ${classes.trstfield}`}
            placeholder="Search subscriptions..."
            InputProps={{
              startAdornment: (
                <img src="assets/icones/iconography/Search.svg" />
              ),
              classes,
            }}
          />
        </div>
        <div className="flex gap-x-8 justify-center items-center">
          {/* <div>
            <Button className={classes.squarebutton} variant="text">
              <img
                className="w-28 h-28"
                src="assets/icones/iconography/Share.svg"
              />
            </Button>
          </div>
          <div>
            <Button className={classes.squarebutton} variant="text">
              <img
                className="w-28 h-28"
                src="assets/icones/iconography/download.svg"
              />
            </Button>
          </div> */}
          <div>
            <Button
              className={`${classes.button}`}
              variant="contained"
              onClick={handleClickOpen}
            >
              <img src="assets/icones/iconography/add.svg" />
              <span className="text-white">ADD SUBSCRIPTION</span>
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              fullWidth="true"
              maxWidth="md"
              maxHeight="800px"
            >
              <form onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{
                  display: "flex",
                  height: "88px",
                  borderBottom: "1px solid #E8E6FF",
                  paddingBottom: "22px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginLeft: "48px",
                    marginTop: "30px",
                    alignItems: "center",
                  }}
                >
                  <div className="flex align-center">
                    <DialogTitle id="alert-dialog-title">
                      <Typography
                        style={{
                          marginTop: "10px",
                          fontSize: "22px",
                          fontFamily: "Mail Sans Roman",
                          color: "#313133",
                        }}
                      >
                        Add Subscription{" "}
                      </Typography>
                    </DialogTitle>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={handleClose}
                    style={{
                      borderRadius: "4px",
                      width: "32px",
                      height: "32px",
                      marginTop: "16px",
                      marginRight: "16px",
                      background: "#F7F7FF",
                    }}
                  >
                    <CloseIcon style={{ width: "13.33", height: "13.33" }} />
                  </Button>
                </div>
              </div>

              <DialogContent style={{ marginLeft: "25px" }}>
                <Typography
                  style={{
                    marginTop: "10px",
                    fontSize: "16px",
                    fontFamily: "Mail Sans Roman",
                    color: "#313133",
                  }}
                >
                  Subscription Detail
                </Typography>
                <div
                  style={{
                    marginTop: "16px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{}}>
                    <Typography style={{ color: "#575759" }}>
                      Subscription Name
                    </Typography>
                    {/* <TextField
                      variant="outlined"
                      {...register("subscriptionName")}
                     style={{ width: "387px", height: "48px" }}
                      placeholder="Eg: Subscription Name"
                      error={!!errors.subscriptionName}
                      helperText={errors.subscriptionName?.message}
                      // onChange={(e) => setSubscriptionName(e.target.value)}
                    /> */}
                   
      <Select
        labelId="select-label"
        variant="outlined"
        style={{ width: "387px", height: "53px" }}
        id="select"
        value={selectedOption}
        onChange={handleOptionChange}
      >
        <MenuItem value="Slack">Slack</MenuItem>
        <MenuItem value="Figma">Figma</MenuItem>
        <MenuItem value="Zoom">Zoom</MenuItem>
      </Select>
   
                  </div>
                  <div>
                    <Typography style={{ color: "#575759" }}>Vendor</Typography>
                    <TextField
                      variant="outlined"
                      style={{ width: "387px", height: "48px" }}
                      placeholder="Eg:Vendor Name"
                      {...register("vendor")}
                      error={!!errors.vendor}
                       helperText={errors.vendor?.message}
                      // onChange={(e) => setVendor(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{}}>
                    <Typography style={{ color: "#575759" }}>URL</Typography>
                    <TextField
                      variant="outlined"
                      style={{ width: "387px", height: "48px" }}
                      placeholder="https://"
                      {...register("url")}
                      error={!!errors.url}
                      helperText={errors.url?.message}
                      // onChange={(e) => setSubscriptionUrl(e.target.value)}
                    />
                  </div>
                </div>
                <FormControl component="fieldset" style={{ marginTop: "64px" }}>
                  <FormLabel
                    component="legend"
                    style={{ fontSize: "16px", color: "#313133" }}
                  >
                    Authentication
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="gender1"
                    value={authentication}
                    style={{ color: "#313133" }}
                    onChange={(e) => setAuthentication(e.target.value)}
                  >
                    <FormControlLabel
                      value="API Token"
                      control={<Radio classes={{root: classes.radio, checked: classes.checked}} />}
                      label="API Token"
                    />
                    <FormControlLabel
                      value="HTTP Authentication"
                      control={<Radio classes={{root: classes.radio, checked: classes.checked}}/>}
                      label="HTTP Authentication"
                    />
                    <FormControlLabel
                      value="None"
                      control={<Radio classes={{root: classes.radio, checked: classes.checked}}/>}
                      label="None"
                    />
                  </RadioGroup>
                </FormControl>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{}}>
                    <Typography style={{ color: "#575759" }}>
                      API Key
                    </Typography>
                    <TextField
                      variant="outlined"
                      style={{ width: "387px", height: "48px" }}
                      placeholder="xxxxxxxxxx"
                      {...register("apiKey")}
                      error={!!errors.apiKey}
                      helperText={errors.apiKey?.message}
                      // onChange={(e) => setApiKey(e.target.value)}
                    />
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
               
                <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
            >
              <p
                style={{ color: '#575759', marginLeft: '30px', fontSize: '13px', fontWeight: 400 }}
              >
                NEED HELP?
              </p>
               
                <Button
                  style={{
                    marginTop: "16px",
                    borderRadius: "4px",
                    border: "1px solid #F7F7FF",
                    padding: "10px",
                    marginBottom: "16px",
                    display:"flex-end",
                    marginLeft:"auto",
                    marginRight:"20px",
                    width: "113px",
                    height: "40px",
                  }}
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  style={{
                    marginTop: "16px",
                    borderRadius: "4px",
                    color: "#fff",
                    border: "1px solid #F7F7FF",
                    padding: "10px",
                    marginBottom: "16px",
                    marginRight: "48px",
                    background: "#49B892",
                    width: "231px",
                    height: "40px",
                  }}
                  type="submit"
                  // onClick={handleSubmitSubscription}
                >
                  ADD SUBSCRIPTION
                </Button>
                </Box>
              </DialogActions>
              </form>
            </Dialog>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <TableContainer>
          <Table stickyHeader aria-labelledby="tableTitle">
            <TableHead>
              <TableRow className="h-48 sm:h-64">
                {columns.map((row) => {
                  return (
                    <TableCell
                      key={row.id}
                      align={row.align}
                      padding={row.disablePadding ? "none" : "normal"}
                      className={`${classes.tableHeaderText}   w-40 md:w-64  z-99 Table_header_12`}
                    >
                      {/* // className={`${classes.tableHeaderText} Table_header_12`} */}
                      {row.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length>0 && rows.map((n) => {
                return (
                  <TableRow
                    key={n.id}
                    className="h-72 cursor-pointer"
                    onClick={() =>
                      history.push({
                        pathname:`/subscribe/subscription/${n.id}`,
                        state: `${n.name}`,
                        state2: `${n.id}`
                      })
                    }
                    hover
                  >
                    <TableCell
                      align="left"
                      padding="none"
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <div className="flex gap-x-7 justify-start items-center pl-4">
                        <div className="flex justify-end items-center">
                          {!!n?.url ? (
                            <img
                              className="w-28 h-28 block rounded"
                              src={n.url}
                              alt={n.name}
                            />
                          ) : (
                            <img
                              className="w-28 h-28 block rounded"
                              src="assets/images/ecommerce/product-image-placeholder.png"
                              alt={n.name}
                            />
                          )}
                        </div>
                        <span className={`${classes.blueColor} Medium_14M`}>
                          {n.name}
                        </span>
                        <span>
                          <img src="assets/icones/dashboard/right.svg" />
                        </span>
                      </div>
                    </TableCell>
                    {n.name === "Slack" && (<TableCell
                      align="left"
                      padding="none"
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <span className={`${classes.tableTitle} Medium_14R`}>
                        {/* ${n.annual_spend} */}
                        $78
                      </span>
                    </TableCell>)}
              {n.name === "Zoom" && ( <TableCell
                      align="left"
                      padding="none"
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <span className={`${classes.tableTitle} Medium_14R`}>
                        {/* ${n.annual_spend} */}
                        $60
                      </span>
                    </TableCell>)}
                    {n.name === "Figma"    &&  (<TableCell
                      align="left"
                      padding="none"
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <span className={`${classes.tableTitle} Medium_14R`}>
                        ${n.annual_spend}
                      </span>
                    </TableCell>)}
                    <TableCell
                      align="left"
                      padding="none"
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <span className={`${classes.tableTitle} Medium_14R`}>
                        {n.users}
                      </span>
                    </TableCell>
                    <TableCell
                      align="left"
                      padding="none"
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <span className={`${classes.tableTitle} Medium_14R`}>
                        {n.usage.toFixed(2)}%
                      </span>
                    </TableCell>
                    <TableCell
                      align="left"
                      padding="none"
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <Tooltip
                        classes={{
                          tooltip: "bg-white",
                          arrow: "bg-white",
                          tooltipArrow: "bg-white",
                          // popperArrow: "bg-white"
                        }}
                        arrow
                        title={<Poper />}
                      >
                        <LinearProgress
                          onMouseEnter={(e) => handleDeatil(e, n.id)}
                          onMouseLeave={(e) => handleDeatil(e, n.id)}
                          classes={{ barColorPrimary: classes.bgcolr }}
                          className={`${classes.progresscolor}`}
                          variant="determinate"
                          value={n.licence_type}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <span className="block">
                        <span
                          className={`${classes.tableTitle} Medium_14R items-center`}
                        >
                          {moment(n.renewal_date).format("DD MMM 'YY")}
                        </span>
                        <br />
                        <span className={`${classes.remaintime} Small_12R`}>
                          30 days left
                        </span>
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
               {/* <TableRow
                    className="h-72 cursor-pointer"
                    onClick={() =>
                      history.push({
                        pathname:"/subscribe/subscription/slack",
                        state:"Slack"
                      })
                    }
                    hover
                  >
                    <TableCell
                      align="left"
                      padding="none"
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <div className="flex gap-x-7 justify-start items-center pl-20">
                        <div className="flex justify-end items-center">
                          
                            <img
                              className="w-28 h-28 block rounded"
                              src="assets/images/ecommerce/product-image-placeholder.png"
                              alt="Slack"
                            />
                          
                        </div>
                        <span className={`${classes.blueColor} Medium_14M`}>
                          Slack
                        </span>
                        <span>
                          <img src="assets/icones/dashboard/right.svg" />
                        </span>
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      padding="none"
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <span className={`${classes.tableTitle} Medium_14R`}>
                        {/* ${n.annual_spend} 
                        $10
                      </span>
                    </TableCell>
                    <TableCell
                      align="left"
                      padding="none"
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <span className={`${classes.tableTitle} Medium_14R`}>
                        13
                      </span>
                    </TableCell>
                    <TableCell
                      align="left"
                      padding="none"
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <span className={`${classes.tableTitle} Medium_14R`}>
                        40%
                      </span>
                    </TableCell>
                    <TableCell
                      align="left"
                      padding="none"
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <Tooltip
                        classes={{
                          tooltip: "bg-white",
                          arrow: "bg-white",
                          tooltipArrow: "bg-white",
                          // popperArrow: "bg-white"
                        }}
                        arrow
                        title={<Poper />}
                      >
                        <LinearProgress
                          onMouseEnter={(e) => handleDeatil(e, 1)}
                          onMouseLeave={(e) => handleDeatil(e, 1)}
                          classes={{ barColorPrimary: classes.bgcolr }}
                          className={`${classes.progresscolor}`}
                          variant="determinate"
                          value={"0"}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      className="p-4 md:p-16"
                      component="th"
                      scope="row"
                    >
                      <span className="block">
                        <span
                          className={`${classes.tableTitle} Medium_14R items-center`}
                        >
                          {moment("2 June 2023").format("DD MMM 'YY")}
                        </span>
                        <br />
                        <span className={`${classes.remaintime} Small_12R`}>
                          30 days left
                        </span>
                      </span>
                    </TableCell>
                  </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

export default Datatable;
