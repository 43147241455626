import React from "react";

const UsersConfig = {
  settings: {
    layout: {
      config: {
        navTitle:"Users"
      },
    },
  },
  routes: [
    {
      path: "/subscribe/user",
      component: React.lazy(() => import("./Users")),
    },
  ],
};

export default UsersConfig;