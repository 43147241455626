import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Chart from 'react-apexcharts'
import { $CombinedState } from '@reduxjs/toolkit';

const useStyles = makeStyles((theme) => ({
    cardWrapper: {
        background: " #FFFFFF",
        boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
        borderRadius: "4px",
        width: "342px",
        height: "180px",

    },
    datacontainer: { padding: "24px 24px 0px 24px" },
    price: {
        fontWeight: "500",
        fontSize: "22px",
        lineHeight: "28px",
        color: "#313133",
    },
    percentDown: {
        color: "#CC2525"
    },
    title: {
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "16px",
        letterSpacing: "0.0025em",
        color: "#575759",
    },
    chartConntainer: {
        height: "100px"
    }
}));
function Card(props) {
    const classes = useStyles()

    var options = {
        chart: {
            height: 380,
            type: "line",
            // foreColor: '#6D6D6D',
            dropShadow: {
                enabled: true,
                top: 3,
                left: 2,
                blur: 4,
                opacity: 0.3,
                color: ['rgba(100, 86, 247, 0.6)', 'rgba(73, 184, 146, 0.6)']
            },
            toolbar: {
                show: false,
            }
        },
        series: [
            {
                name: "Series 1",
                data: [2, 23, 19, 45, 38, 52, 45]
            }
        ],
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                colorStops: [
                    {
                        offset: 0,
                        color: "#6456F7",
                        opacity: 1
                    },
                    {
                        offset: 100,
                        color: "#49B892",
                        opacity: 1
                    }
                ]
            }
        },
        xaxis: {
            show: false,
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: false
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            show: false,
            padding: {
                top:-20,
                bottom:-10,
                left: 0,
                right: 0
              }
        },
        stroke: {
            width: 4,
            curve: 'smooth'
        },
        zoom: {
            enabled: false
        },

        xaxis: {
            show: false,
            labels: {
                show: false
            },
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
        }
    };


    return (
        <div className={classes.cardWrapper}>
            <div className={`flex items-center gap-x-14 ${classes.datacontainer}`}>
                <img src={"assets/icones/dashboard/" + props.icon + ".svg"} />
                {props.text ==="Total Users" || props.text==="Total Subscriptions" ? <div>
                    <div className='flex items-center gap-x-3 '>
                        {/* <Typography className='text-12'>$</Typography> */}
                        <Typography className={classes.price}>{props.total}</Typography>
                        {/* <img src="assets/icones/dashboard/red-down.svg" />
                        <Typography className={classes.percentDown}>5%</Typography> */}
                    </div>
                    <div>
                        <Typography className={classes.title}>{props.text}</Typography>
                    </div>
                </div>: <div>
                    <div className='flex items-center gap-x-3 '>
                        <Typography className='text-12'>$</Typography>
                        <Typography className={classes.price}>{props.total}</Typography>
                        {/* <img src="assets/icones/dashboard/red-down.svg" />
                        <Typography className={classes.percentDown}>5%</Typography> */}
                    </div>
                    <div>
                        <Typography className={classes.title}>{props.text}</Typography>
                    </div>
                </div>} 
               
            </div>
            <div className={classes.chartConntainer}>
                <Chart options={options} series={options.series} type="line" height="100%" />

            </div>
        </div>
    )
}

export default Card