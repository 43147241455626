import { authRoles } from 'app/auth';
import Forgotpassword from './Forgotpassword';

const ForgotPasswordConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
                ispadding: false
            },
        },
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: '/forgot-password',
            component: Forgotpassword,
        },
    ],
};

export default ForgotPasswordConfig;
