import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router';
import Toolbar from '@material-ui/core/Toolbar';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import { selectNavigation } from 'app/store/fuse/navigationSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 0
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    color: '#313133',
    // fontFamily: 'Mail Sans Roman DemBd',
    // fontWeight: 500,
    // fontSize: "22px",
    // lineHeight: '28px'
    // padding:"52px 0"
  }
}));

function ToolbarLayout1(props) {
  const param = useParams()
  const navigation = useSelector(selectNavigation);
  const history = useHistory();
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const navbar = useSelector(({ fuse }) => fuse.navbar);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const classes = useStyles(props);

  function onBackButtonClick() {
    history.goBack();
  }
  // console.log(props.toolbarColor && props.toolbarColor);
  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx(classes.root, 'shadow-0 flex relative', props.className)}
        style={{ backgroundColor: props.toolbarConfig.toolbarColor ? props.toolbarConfig.toolbarColor : "#F7F7FF", height: "80px", }}
        position="static"
      >
        <Toolbar className="min-h-48 md:min-h-64 px-52">
          <div className="flex flex-1 ">
            {config.navbar.display && config.navbar.position === 'left' && (
              <>
                <Hidden mdDown>
                  {(config.navbar.style === 'style-3' ||
                    config.navbar.style === 'style-3-dense') && (
                      <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                    )}

                  {config.navbar.style === 'style-1' && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}

            <div className={`${classes.heading} H2_22`}>
              {props.backButton && props.backButton === true && (<img src='assets/icones/iconography/Arrow_left.svg' width='20px' height='20px' style={{ cursor: 'pointer', marginRight: '10px' }} onClick={onBackButtonClick} />)}
              {props.title && props.title}
            </div>
          </div>

          <div className="flex gap-x-20 items-center px-8 h-full overflow-x-auto">
            {/* <img src="assets/icones/toolbar/info.svg" />

            <img src="assets/icones/toolbar/notification.svg" /> */}


            <UserMenu />

          </div>

          {config.navbar.display && config.navbar.position === 'right' && (
            <>
              <Hidden mdDown>
                {!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}
              </Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);
