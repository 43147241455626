import React from "react";
import {Redirect} from "react-router-dom"
import { authRoles } from 'app/auth';
import TourApp from "./TourApp"; 
const isAuthenticated = localStorage.getItem('token');
import history from './../../../@history';

const DashboardConfig = {
  settings: {
    layout: {
      config: {
        navTitle:"Dashboard",
        ispadding: false
      },
    },
  },
  routes: [
    { 
      exact:true,
      path: '/subscribe/dashboard',
      // auth: authRoles.admin,
      component: React.lazy(() => import("./TourApp")),
      // component: isAuthenticated ? TourApp :() => ,
    },
  ],
};
console.log(isAuthenticated)
console.log(localStorage.getItem('token'))
export default DashboardConfig;