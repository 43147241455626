import FuseDialog from '@fuse/core/FuseDialog';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseSuspense from '@fuse/core/FuseSuspense';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from 'app/AppContext';
import navigationConfig from 'app/fuse-configs/navigationConfig';
import SettingsPanel from 'app/fuse-layouts/shared-components/SettingsPanel';
import clsx from 'clsx';
import { memo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import FooterLayout1 from './components/FooterLayout1';
import LeftSideLayout1 from './components/LeftSideLayout1';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import RightSideLayout1 from './components/RightSideLayout1';
import ToolbarLayout1 from './components/ToolbarLayout1';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.boxed': {
      clipPath: 'inset(0)',
      maxWidth: (props) => `${props.config.containerWidth}px`,
      margin: '0 auto',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    },
    '&.container': {
      '& .container': {
        maxWidth: (props) => `${props.config.containerWidth}px`,
        width: '100%',
        margin: '0 auto',
      },
    },
  },
  content: {
    background: "#F7F7FF"
  }
}));

function Layout1(props) {
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const appContext = useContext(AppContext);
  const { routes } = appContext;
  const classes = useStyles({ ...props, config });

  return (
    <div id="fuse-layout" className={clsx(classes.root, config.mode, 'w-full flex')}>
      {config.leftSidePanel.display && <LeftSideLayout1 />}

      <div className="flex flex-auto min-w-0">
        {config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1 />}

        <main id="fuse-main" className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
          {config.toolbar.display && (
            <ToolbarLayout1 {...props} title={config.navTitle} toolbarConfig={config} backButton={config.backButton} navigation={navigationConfig} className={config.toolbar.style === 'fixed' && 'sticky top-0 z-10'} />
          )}

          {
            config.ispadding !== null && config.ispadding === false ? (<div className={`flex flex-col flex-auto min-h-0 relative z-10 ${classes.content}`}>
              <FuseDialog />

              <FuseSuspense>{renderRoutes(routes)}</FuseSuspense>

              {props.children}
            </div>
            ) : (<div className={` flex flex-col ${config.ispadding != false && "px-52"}  pt-18 flex-auto min-h-0 relative z-10 ${classes.content}`}>
              <FuseDialog />

              <FuseSuspense>{renderRoutes(routes)}</FuseSuspense>

              {props.children}
            </div>
            )}

          {config.footer.display && (
            <FooterLayout1 className={config.footer.style === 'fixed' && 'sticky bottom-0'} />
          )}
        </main>

        {config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1 />}
      </div>

      {config.rightSidePanel.display && <RightSideLayout1 />}
      <FuseMessage />
    </div>
  );
}

export default memo(Layout1);
