import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { Avatar, Box, Button, SvgIcon } from '@material-ui/core'; import Card from '@material-ui/core/Card';
// import {ReactComponent as ReactLogo} from '../assets/icones/insight/tooltips.svg'
// import tipLogo from 'assets/icones/insight/tooltips.svg'
import { AvatarGroup } from '@mui/material';
import Slider from '@material-ui/core/Slider';
import RangSider from '../RangSider';
import ColumnCharts from '../compnonets/ColumnCharts';
import ReviewButtonWithDialog from '../compnonets/ReviewButtonWithDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    // gridTemplateColumns: 'repeat(12, 1fr)',
    // gridGap: theme.spacing(0),
    boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
    borderRadius: "8px"
  },
  main: {
    // display: 'grid',
    // gridTemplateColumns: 'repeat(12, 1fr)',
    // gridGap: theme.spacing(0),
    
    boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
    borderRadius: "8%"
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  gird: {
    borderRadius: "20px",
    flexBasis: "1",
  },
  root: {
    // maxWidth:362,
    // maxHeight:200,
    borderRadius: "0px",
    boxShadow: "none",
    padding: "30px ",
    minWidth: "100%",
    minHeight: "100%",

  },
  multiAvatar: {
    justifyContent: "left",
  },
  singleAvatar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

  },
  sub_text: {
    // fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    linehHeight: "18px",
    letterSpacing: "0.0025em",
    marginTop: "12px",
    marginBottom: "12px",

    /* Neutrals/Subheading */

    color: "#575759"

  },
  headingsText: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    linehHeight: "18px",
    letterSpacing: "0.0025em",
    marginBottom: "24px"

  },
  subheadingsText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    linehHeight: "18px",
    letterSpacing: "0.0025em",
    color: "#575759"


  },
  smallLabelText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    linehHeight: "15px",
    letterSpacing: "0.005em",
    marginBottom: "8px",
    color: "#313133",
    textAlign: "center",

  },
  toolTip: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    linehHeight: "15px",
    letterSpacing: "0.005em",
    marginBottom: "8px",
    color: "#313133",

  },
  toolTipIcon: {
    marginTop: "10px",
    marginBottom: "10px",

  },
  reviweBotton: {
    boxSizing: "border-box",
    padding: "9px 16px",
    width: "82px",
    height: "40px",
    background: "#49B892",
    color: "#FFFFFF",
    borderRadius: "4px",
  },
  lists: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }
}));

export default function Ren() {
  const classes = useStyles();

  return (
    <div>
      

    
      <Box component="div" className={classes.lists}>
        <Grid container wrap="nowrap" >
          <Grid item xs={4}>

            <Card className={classes.root}>
              <AvatarGroup max={4} spacing={12} className={classes.multiAvatar} >
              <Avatar alt="Remy Sharp" src="assets/icones/insight/Avatar.svg"/>
                <Avatar alt="Travis Howard"  src="assets/icones/insight/Avatar 2.svg"/>
                <Avatar alt="Cindy Baker"src="assets/icones/insight/Avatar 3.svg"/>
                <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
              </AvatarGroup >
              <Box component="div" display="flex" style={{ display: "flex", gap: "12px", alignItems: "center", marginBottom: "12px" }}>
                <Typography className={classes.smallLabelText} style={{ color: "#483EB3" }}> Upcoming Renewals </Typography>
                <Typography className={classes.smallLabelText} style={{ marginBottom: "12px" }}>·</Typography>
                <Typography className={classes.smallLabelText} style={{ marginBottom: "12px" }}> 1d </Typography>
              </Box>
              {/* <Box component="div" style={{ margin : "21px 0px 0px 0px"}}> */}
              <Typography className={classes.subheadingsText} style={{ textAlign: "left" }}>We found 10 upcoming renewals for the next 6 months.</Typography>
              {/* </Box> */}
            </Card>


          </Grid>
          <Divider orientation="vertical" />
          <Grid item xs={4}>
            <Card className={classes.root}>
              <Typography className={classes.headingsText}>10 upcoming renewals</Typography>

              <ColumnCharts data={"data"} />

            </Card>
          </Grid>
          <Divider orientation="vertical" />
          <Grid item xs={4}>
            <Card className={classes.root}>
              <img className={classes.toolTipIcon} src="assets/icones/insight/tooltips.svg" />
              <Typography className={classes.toolTip}>Please run the deprovision workflow to alert the users about their usage status.
              </Typography>
              <ReviewButtonWithDialog subscriptionName='Sentry' />

            </Card>
          </Grid>
        </Grid>
        <Grid container wrap="nowrap" >
          <Grid item xs={4}>

            <Card className={classes.root}>
              <AvatarGroup max={4} spacing={12} className={classes.multiAvatar} >
              <Avatar alt="Remy Sharp" src="assets/icones/insight/Avatar.svg"/>
                <Avatar alt="Travis Howard"  src="assets/icones/insight/Avatar 2.svg"/>
                <Avatar alt="Cindy Baker"src="assets/icones/insight/Avatar 3.svg"/>
                <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
              </AvatarGroup >
              <Box component="div" display="flex" style={{ display: "flex", gap: "12px", alignItems: "center", marginBottom: "12px" }}>
                <Typography className={classes.smallLabelText} style={{ color: "#483EB3" }}> Upcoming Renewals </Typography>
                <Typography className={classes.smallLabelText} style={{ marginBottom: "12px" }}>·</Typography>
                <Typography className={classes.smallLabelText} style={{ marginBottom: "12px" }}> 1d </Typography>
              </Box>
              {/* <Box component="div" style={{ margin : "21px 0px 0px 0px"}}> */}
              <Typography className={classes.subheadingsText} style={{ textAlign: "left" }}>We found 10 upcoming renewals for the next 6 months.</Typography>
              {/* </Box> */}
            </Card>


          </Grid>
          <Divider orientation="vertical" />
          <Grid item xs={4}>
            <Card className={classes.root}>
              <Typography className={classes.headingsText}>10 upcoming renewals</Typography>

              <ColumnCharts data={"data"} />

            </Card>
          </Grid>
          <Divider orientation="vertical" />
          <Grid item xs={4}>
            <Card className={classes.root}>
              <img className={classes.toolTipIcon} src="assets/icones/insight/tooltips.svg" />
              <Typography className={classes.toolTip}>Please run the deprovision workflow to alert the users about their usage status.
              </Typography>
              <ReviewButtonWithDialog subscriptionName='Sentry' />

            </Card>
          </Grid>
        </Grid>
        <Grid container wrap="nowrap" >
          <Grid item xs={4}>

            <Card className={classes.root}>
              <AvatarGroup max={4} spacing={12} className={classes.multiAvatar} >
              <Avatar alt="Remy Sharp" src="assets/icones/insight/Avatar.svg"/>
                <Avatar alt="Travis Howard"  src="assets/icones/insight/Avatar 2.svg"/>
                <Avatar alt="Cindy Baker"src="assets/icones/insight/Avatar 3.svg"/>
                <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
              </AvatarGroup >
              <Box component="div" display="flex" style={{ display: "flex", gap: "12px", alignItems: "center", marginBottom: "12px" }}>
                <Typography className={classes.smallLabelText} style={{ color: "#483EB3" }}> Upcoming Renewals </Typography>
                <Typography className={classes.smallLabelText} style={{ marginBottom: "12px" }}>·</Typography>
                <Typography className={classes.smallLabelText} style={{ marginBottom: "12px" }}> 1d </Typography>
              </Box>
              {/* <Box component="div" style={{ margin : "21px 0px 0px 0px"}}> */}
              <Typography className={classes.subheadingsText} style={{ textAlign: "left" }}>We found 10 upcoming renewals for the next 6 months.</Typography>
              {/* </Box> */}
            </Card>


          </Grid>
          <Divider orientation="vertical" />
          <Grid item xs={4}>
            <Card className={classes.root}>
              <Typography className={classes.headingsText}>10 upcoming renewals</Typography>

              <ColumnCharts data={"data"} />

            </Card>
          </Grid>
          <Divider orientation="vertical" />
          <Grid item xs={4}>
            <Card className={classes.root}>
              <img className={classes.toolTipIcon} src="assets/icones/insight/tooltips.svg" />
              <Typography className={classes.toolTip}>Please run the deprovision workflow to alert the users about their usage status.
              </Typography>
              <ReviewButtonWithDialog subscriptionName='Sentry' />

            </Card>
          </Grid>
        </Grid>
       
      </Box>


      {/* <Divider className={classes.divider} /> */}


    </div>
  );
}
