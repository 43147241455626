import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
import { authRoles } from 'app/auth';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: 'overview',
    title: 'Overview',
    translate: 'OVERVIEW',
    type: 'group',
    // auth: authRoles.admin,
    icon: 'apps',
    children: [{
      id: "dashboard",
      title: "Dashboard",
      translate: "Dashboard",
      type: "item",
      // auth: authRoles.admin,
      icon: "assets/icones/iconography/Dashboard.svg",
      url: "/subscribe/dashboard",
    },
    {
      id: "insights",
      title: "Insights",
      translate: "Insights",
      type: "item",
      // auth: authRoles.admin,
      icon: "assets/icones/iconography/Insights.svg",
      url: "/subscribe/insight",
    },],

  },

  {
    id: 'management',
    title: 'Management',
    translate: 'Management',
    type: 'group',
    // auth: authRoles.admin,
    icon: 'apps',
    children: [
      {
        id: "subscriptions",
        title: "Subscriptions",
        translate: "Subscriptions",
        type: "item",
        // auth: authRoles.admin,
        icon: "assets/icones/iconography/subscriptions.svg",
        url: "/subscribe/subscription",
      },
      {
        id: "workflows",
        title: "Workflows",
        translate: "Workflows",
        type: "collapse",
        // auth: authRoles.admin,
        icon: "assets/icones/iconography/workflows.svg",
        children: [
          {
            id: "templates",
            title: "Templates",
            type: "item",
            // auth: authRoles.admin,
            url: "/subscribe/workflows/template",
          },
          {
            id: "workflows",
            title: "Workflows",
            type: "item",
            // auth: authRoles.admin,
            url: "/subscribe/workflows/workflow",
          },
          {
            id: "activity",
            title: "Activity",
            type: "item",
            // auth: authRoles.admin,
            url: "/subscribe/workflows/activity",
          },
        ],
      },
      {
        id: "users",
        title: "Users",
        translate: "Users",
        type: "item",
        // auth: authRoles.admin,
        icon: "assets/icones/iconography/users.svg",
        url: "/subscribe/user",
      },
      {
        id: "teams",
        title: "Teams",
        translate: "Teams",
        type: "item",
        // auth: authRoles.admin,
        icon: "assets/icones/iconography/teams.svg",
        url: "/subscribe/team",
      },
      
      {
        id: "transaction",
        title: "Transaction",
        translate: "Transaction",
        type: "item",
        // auth: authRoles.admin,
        icon: "assets/icones/iconography/transaction.svg",
        url: "/subscribe/transaction"
      },
      // {
      //   id: "integrations",
      //   title: "Integrations",
      //   translate: "Integrations",
      //   type: "item",
      //   // auth: authRoles.admin,
      //   icon: "assets/icones/iconography/Integrations.svg",
      //   url: "/subscribe/integrations"
      // }
    ]
  },
  {
    id: "settings",
    title: "Settings",
    translate: "Settings",
    type: "item",
    // auth: authRoles.admin,
    icon: "assets/icones/iconography/setting.svg",
    url: "/subscribe/settings"
  }


];

export default navigationConfig;
