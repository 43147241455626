import React from "react";

const WorkflowsDetailsConfig = {
  settings: {
    layout: {
      config: {
        navTitle: "30 - day deprovision",
        backButton: true
      },
    },
  },
  routes: [
    {
      path: "/subscribe/workflow/details",
      component: React.lazy(() => import("./WorkflowDetails")),
    },
  ],
};

export default WorkflowsDetailsConfig;