import React from "react";
import {Redirect, useHistory} from "react-router-dom"
import { authRoles } from 'app/auth';
const isAuthenticated = localStorage.getItem('token');
import TourApp from "./TourApp"; 
import history from './../../../@history';



const TourAppConfig = {
 
  settings: {
    layout: {
      config: {
        navTitle:"Dashboard",
        ispadding: false
      },
    },
  },
  routes: [
    {
      exact:true,
      path: '/subscribe/dashboard',
      // auth: authRoles.admin,
      component: React.lazy(() => import("./TourApp")),
      // component: isAuthenticated ?  : () => <Redirect to="/login" />,

    },
  ],
};
console.log(isAuthenticated)
console.log(localStorage.getItem('token'))
export default TourAppConfig;
