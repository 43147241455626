import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { submitRegister } from 'app/auth/store/registerSlice';
import * as yup from 'yup';
import _ from '@lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Passwordset from "./Passwordset"
import Userdetail from './Userdetail';
import Greeting from './Greeting';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    loginWrapper: {
        background: "#FFFFFF",
        borderRadius: "8px",
        boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
        width: "480px",
        height: "521px",
        paddingTop: '28px',
        zIndex: 15,
        paddingBottom: '20px',
        marginTop: '50px'
    },
    setPassword: {
        background: "#FFFFFF",
        borderRadius: "8px",
        boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
        width: "586px",
        height: "428px",
        paddingTop: '28px',
        zIndex: 15,
        paddingBottom: '20px',
        marginTop: '50px'
    },
    container: {
        // background: "#E5E5E5",
        padding: "0 60px",
        paddingTop: '20px',
        marginLeft: '123px'
    },
    topbutton: {
        border: "1px solid #E8E6FF",
        borderRadius: "4px",
        color: "#313133 !important",
        width: "50px",
        height: "30px",
        textDecoration: "none !important",
        display: "flex",
    },
    descHeader: {
        fontSize: "32px",
        lineHeight: "41px",
        letterSpacing: "-0.01em",
        fontStyle: "normal",
        color: " #313133",
        fontStyle: 'normal',

    },
    heighlight: {
        color: "#6456F7",
        fontSize: "32px",
        lineHeight: "41px",
        letterSpacing: "-0.01em",
        fontStyle: "normal",
    },
    headerText: {
        fontSize: "24px",
        lineHeight: "31px",
        letterSpacing: "-0.01em",
        fontStyle: "normal",
        color: "#6456F7",
        fontWeight: 500,
    },
    headerTextHeighlight: {
        fontSize: "24px",
        lineHeight: "31px",
        letterSpacing: "-0.01em",
        fontStyle: "normal",
        color: "#49B892",
        // fontFamily: 'Mail Sans Roman',
        fontWeight: 500,
    }
}));


function Register(props) {
    const classes = useStyles()
    const history = useHistory()

    const [passwordSetup, setPasswordSetup] = useState(false)
    const [userDetail, setUserDetail] = useState(true)
    const [greeting, setGreetings] = useState(false)

    const submitDetailHandler = (userDetail, greeting) => {
        setUserDetail(userDetail)
        setGreetings(greeting)
    }

    useEffect(() => {
        if (greeting)
            setTimeout(() => {
                setGreetings(true)
                setPasswordSetup(true)
            }, 100)
    }, [greeting])

    function onClickLogin(model) {
        history.push('/Login')
        //dispatch(submitLogin(model));
      }
    return (
        <>
            <div className={classes.container}>
                <div className='flex justify-between w-full'>
                    <div>
                        <div className='flex'>
                            <img src="assets/icones/logo/Logo_short.svg" />
                            <div className={classes.headerText}>Subscribe<span className={classes.headerTextHeighlight}>pod</span>
                            </div>
                        </div>
                    </div>
                    <Button onClick={onClickLogin} className={`${classes.topbutton} flex justify-center items-center`}>LOG IN</Button>
                </div>

                <div className={`flex w-full h-full justify-center items-center`}>
                    {!passwordSetup &&
                        <div>
                            <div className={`${classes.descHeader} `} >Book a demo to kick-off
                                your <span className={classes.heighlight}> SaaS management </span>  journey</div>
                            <div className='mt-28'>
                                <ul>
                                    <li className='flex items-center family-Inter'><span><img src="assets/icones/selection/list.svg" className='mr-16' /></span> How to setup Subscribepod account</li>
                                    <li className='flex items-center'><span><img src="assets/icones/selection/list.svg" className='mr-16' /></span> Training in the management of your expenses</li>
                                    <li className='flex items-center'><span><img src="assets/icones/selection/list.svg" className='mr-16' /></span> How to optimize your subscriptions and renewals.</li>
                                    <li className='flex items-center'><span><img src="assets/icones/selection/list.svg" className='mr-16' /></span> Authorize your employees with the necessary tools.</li>
                                </ul>
                            </div>
                        </div>
                    }


                    {passwordSetup ? (
                        <div className={`px-56 ${classes.setPassword} items-center`}>
                            <Passwordset />
                        </div>
                    ) : (
                        <div className={`px-56 ${classes.loginWrapper} items-center`}>
                            {userDetail && <Userdetail submitDetailHandler={submitDetailHandler} />}
                            {greeting && <Greeting />}
                        </div>
                        )
                    }
                </div>
                <img src='assets/icones/iconography/bottom-curve.png' width='100%'
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        zIndex: -1
                    }}
                />
            </div>
        </>
    )
}

export default Register