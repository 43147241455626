import {
  alpha,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputBase,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { fetchToken } from "app/main/auth/auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
    borderRadius: "8px",
    marginTop: "30px",
  },
  connection_box: {
    padding: "24px",
    borderRight: "1px solid #E8E6FF",
    height: "100%",
  },
  connection: {
    padding: "24px",
  },
  heading3: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "23px",
    margin: "28px 40px",
    fontFamily: "mail sans roman",
    color: "#313133",
  },

  sub_text: {
    fontWeight: "400",
    fontSize: "15px",
    linehHeight: "18px",
    letterSpacing: "0.0025em",
    marginTop: "12px",
    color: "#575759",
  },
  headingsText: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    linehHeight: "18px",
    color: "#313133",
    letterSpacing: "0.0025em",
    marginBottom: "24px",
  },
  DialogTitletext: {
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "mail sans roman",
    linehHeight: "28px",
    color: "#313133",
  },
  subheadingsText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    linehHeight: "18px",
    letterSpacing: "0.0025em",
    color: "#313133",
    marginBottom: "24px",
  },
  smallLabelText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    linehHeight: "15px",
    letterSpacing: "0.005em",
    marginLeft: "24px",
    color: "#7F7E8C",
    textAlign: "left",
    width: "125px",
  },
  smallLabelText2: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    linehHeight: "15px",
    letterSpacing: "0.005em",
    color: "#313133",
  },
  smallLabelText3: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    linehHeight: "15px",
    letterSpacing: "0.005em",
    marginLeft: "24px",
    color: "#7F7E8C",
    textAlign: "left",
  },
  smallLabel: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    linehHeight: "15px",
    letterSpacing: "0.005em",
    marginLeft: "16px",
    color: "#313133",
    textAlign: "left",
    // width:"125px"
  },
  tags: {
    padding: "3px 6px",

    /* Neutrals/Background */

    background: "#F7F7FF",
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "13px",
    linehHeight: "1px",
    letterSpacing: "0.005em",

    color: "#313133",
  },
  connection_items: {
    border: "1px solid #E8E6FF",
    /* Elevation1 */

    boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
    borderRadius: "8px",
    padding: "30px",
    marginBottom: "30px",
  },
  toolTip: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    linehHeight: "15px",
    letterSpacing: "0.005em",
    marginBottom: "8px",
    color: "#313133",
  },
  toolTipIcon: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  reviweBotton: {
    padding: "9px 16px",

    width: "88px",
    height: "40px",

    /* Neutrals/Border */

    border: "1px solid #E8E6FF",
    borderRadius: "4px",
    color: "#7F7E8C",
  },
  connectButton: {
    padding: "9px 16px",

    /* Neutrals/Border */

    border: "1px solid #E8E6FF",
    borderRadius: "4px",
    color: "#FFFFFF",
    backgroundColor: "#B6E3D3",
    fontSize: "13px",
    fontWeight: "500",
  },

  avatar: {
    width: "80px",
    height: "80px",
    marginTop: 0,
    background: "#6456f7",
    borderRadius: "10px",
  },
  item_avatar: {
    width: "56px",
    height: "56px",
  },
  item_avatar90: {
    width: "90px",
    height: "90px",
  },
}));

const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
// const validationSchema = yup.object().shape({
//   text: yup.string().required("A text is required"),
//   image: yup
//     .mixed()
//     .required("A file is required")
//     .test(
//       "fileSize",
//       "File too large",
//       value => value && value.size <= FILE_SIZE
//     )
//     .test(
//       "fileFormat",
//       "Unsupported Format",
//       value => value && SUPPORTED_FORMATS.includes(value.type)
//     )
// });

const schema = yup.object().shape({
  companyname: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Company Name should include only alphabets")
    .required("Company Name is required"),
  Companywebsite: yup
    .string()
    .matches(
      /^(https?:\/\/)?([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/,
      "Invalid URL format"
    )
    .required("Company Website is required"),
  // file: yup
  // .mixed()
  // .required("required")
  // .test(
  //   "fileSize",
  //   "File too large",
  //   value => value && value.size <= FILE_SIZE
  // )
  // .test(
  //   "fileFormat",
  //   "Unsupported Format",
  //   value => value && SUPPORTED_FORMATS.includes(value.type)
  // )

  // .test("is-valid-type", "Not a valid image type", (value) => {
  //   const validFileTypes = ["image/jpeg", "image/png", "image/svg+xml"]; // Add more valid MIME types if needed
  //   if (value) {
  //     return validFileTypes.includes(value.type);
  //   }
  //   return false;
  // })
  // .test("is-valid-size", "Max allowed size is 100KB", value => {
  //   const MAX_FILE_SIZE = 100 * 1024; // 100KB in bytes
  //   return value && value.size <= MAX_FILE_SIZE;
  // })
});

function Company() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(true);
  const [image, setImage] = React.useState("");
  const [companyname, setCompanyName] = React.useState("");
  const [companywebsite, setCompanyWebsite] = React.useState("");
  const [companyId, setCompanyId] = React.useState("");
  const [imageerror, setImagerror] = React.useState("");
  const [added, setAdded] = React.useState(false);

  // const initialValues = {
  //   Companyname: companyname,
  //   image:'',
  //   Companywebsite:'',

  //   // Add other fields and initial values as needed
  // };

  //const updateschema=schema.default(initialValues)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    console.log(image);

    axios
      .post(
        "https://demo.subscribepod.com/api/v1/company/edit-company/" +
          companyId,
        {
          company_name: data.companyname,
          url: data.Companywebsite,
          img: image,
        },
        {
          headers: {
            Authorization: `Bearer ${fetchToken()}`,
          },
        }
      )
      .then(function (response) {
        console.log(response.data.message);
        if (response.data.message) {
          console.log(response.data.message);
          setAdded(true);
          toast.success("company information saved", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setAddUserButton(false)
        }
      })
      .catch(function (error) {
        console.log(error, "error in company");
      });

    // data.preventDefault();
    // schema
    //   .validate({ companyname })
    // You can perform form submission or further actions here
  };

  React.useEffect(() => {
    axios
      .get("https://demo.subscribepod.com/api/v1/company/get_company_details", {
        headers: {
          Authorization: `Bearer ${fetchToken()}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setCompanyName(res.data.company_name);
        setValue("companyname", res.data.company_name || "");
        setValue("Companywebsite", res.data.url || "");
        setImage(res.data.img);

        setCompanyId(res.data.id);
        // console.log(rows);
      });
  }, [added]);

  const handleModel = () => setOpen(!open);
  const handeSelect = () => setSelected(!selected);

  const onCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };
  const onCompanyWebsiteChange = (event) => {
    setCompanyWebsite(event.target.value);
  };
  const onImageChange = (event) => {
    console.log(event.target.files[0].type);
    console.log(event.target.files[0].size);
    if (
      event.target.files[0].type == "image/png" ||
      event.target.files[0].type == "image/svg+xml" ||
      event.target.files[0].type == "image/jpeg"
    ) {
      if (event.target.files[0].size > 1048576) {
        setImagerror("Size must be < 1MB");
        return;
      }
      if (event.target.files && event.target.files[0]) {
        setImage(URL.createObjectURL(event.target.files[0]));
        setImagerror("");
      }
    } else {
      setImagerror("Invalid Image format");
    }
  };
  // const Submit = () => {
  //   console.log(companywebsite);
  //   if (image === "" && companyname === "" && companywebsite === "") {
  //     return;
  //   }
  //   else {
  //     axios
  //       .post(
  //         "https://demo.subscribepod.com/api/v1/company/edit-company/" +
  //           companyId,
  //         {
  //           company_name: companyname,
  //           url: companywebsite,
  //           img: image,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${fetchToken()}`,
  //           },
  //         }
  //       )
  //       .then(function (response) {
  //         console.log(response.data.message);
  //         if (response.data.message) {
  //           console.log(response.data.message);
  //           // toast.success("company Added")
  //           // setAddUserButton(false)
  //         }
  //       })
  //       .catch(function (error) {
  //         console.log(error, "error in company");
  //       });
  //   }
  // };

  return (
    <Box class={classes.main}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box>
            <Typography class={classes.heading3}>Company details</Typography>
          </Box>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          <Box className={classes.connection_box}>
            <Typography className={classes.subheadingsText}>
              Company information
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex">
                <Button variant="text" component="label">
                  <Avatar
                    variant="rounded"
                    className={classes.avatar}
                    alt="Harsh Dhorajia"
                    src={image}
                  />
                  <input
                    type="file"
                    // name="image"
                    accept=".png, .jpeg, .svg"
                    // {...register("image")}
                    onChange={onImageChange}
                    hidden
                  />
                </Button>

                <Typography className="pt-20" class={classes.smallLabelText}>
                  Click on the image to change it.
                </Typography>
              </div>
              {imageerror && (
                <Typography variant="caption" color="error">
                  {imageerror}
                </Typography>
              )}

              <div className="my-24">
                <InputLabel className="mb-5" style={{ color: "#575579" }}>
                  Company Name
                </InputLabel>

                <TextField
                  // name="companyname"
                  {...register("companyname")}
                  // value={companyname}
                  // onChange={onCompanyNameChange}
                  error={!!errors.companyname}
                  helperText={errors.companyname?.message}
                  value={watch("companyname")}
                  onChange={(e) => setValue("companyname", e.target.value)}
                  variant="outlined"
                  size="small"
                  style={{ width: "340px" }}
                />
              </div>

              <div className="my-24">
                <InputLabel className="mb-5" style={{ color: "#575579" }}>
                  Company Website
                </InputLabel>
                <TextField
                  // name="Companywebsite"
                  {...register("Companywebsite")}
                  error={!!errors.Companywebsite}
                  helperText={errors.Companywebsite?.message}
                  value={watch("Companywebsite")}
                  onChange={(e) => setValue("Companywebsite", e.target.value)}
                  variant="outlined"
                  size="small"
                  style={{ width: "340px" }}
                />
              </div>

              <Button
                variant="contained"
                style={{ background: "#6456f7", color: "white" }}
                type="submit"
              >
                Save Changes
              </Button>
              <ToastContainer position="top-right" />
            </form>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box class={classes.connection}>
            <Typography class={classes.subheadingsText}>Connections</Typography>
            <Typography class={classes.subheadingsText} textAlign="center">No new connections available</Typography>
            {/* <Box class={classes.connection_items}>
              <div className="flex items-center w-full relative ">
                <Avatar
                  variant="rounded"
                  className={classes.item_avatar}
                  alt="sap"
                  src={"assets/icones/settings/SAP.svg"}
                />
                <div className="flex-col items-center ml-16">
                  <Typography
                    style={{ color: "#313133" }}
                    classes={classes.smallLabel}
                  >
                    SAP Concur
                  </Typography>
                  <div class={classes.tags}>Expense Account</div>
                </div>
                <div className="flex items-center absolute right-0">
                  <Button
                    variant="contained"
                    class={classes.reviweBotton}
                    onClick={handleModel}
                  >
                    CHANGE
                  </Button>
                  <Dialog
                    // fullScreen={true}
                    scroll={"body"}
                    maxWidth={"xl"}
                    open={open}
                    onClose={handleModel}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogTitle id="responsive-dialog-title">
                      <div className="flex justify-between">
                        <img
                          src={"assets/icones/settings/expense.svg"}
                          alt="expens"
                        />
                        <div className="ml-6" style={{ width: "700px" }}>
                          <Box class={classes.DialogTitletext}>
                            Expense Account
                          </Box>
                          <Box
                            style={{ color: "#7f7e8c" }}
                            class={classes.smallLabelText2}
                          >
                            Please help us monitor all of your subscriptions and
                            payments for you.
                          </Box>
                        </div>
                        <Button autoFocus onClick={handleModel} color="primary">
                          <img
                            src={"assets/icones/settings/close_button.svg"}
                            alt="close"
                          />
                        </Button>
                      </div>
                    </DialogTitle>
                  
                    <DialogContent dividers>
                      <div className="mt-20 mb-16">
                        <Box class={classes.smallLabelText2}>
                          Current connection
                        </Box>
                        <div
                          className="mt-12 flex flex-col gap-16 justify-center items-center relative cursor-pointer"
                          style={{
                            height: "208px",
                            width: "248px",
                            borderRadius: "4px",
                            border: `1px solid  ${
                              selected ? "#49B892" : "#E8E6FF"
                            }`,
                          }}
                          onClick={handeSelect}
                        >
                          <Avatar
                            variant="rounded"
                            className={classes.item_avatar90}
                            alt="sap"
                            src={"assets/icones/settings/SAP90.svg"}
                          />

                          <Box
                            style={{ fontSize: "15px" }}
                            class={classes.smallLabelText2}
                          >
                            SAP Concur
                          </Box>
                          {selected && (
                            <div className="absolute -top-2 -right-2">
                              <img
                                variant="rounded"
                                className={classes.item_avatar90}
                                alt="sap"
                                src={"assets/icones/settings/selected.svg"}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-20 mb-16">
                        <Box class={classes.smallLabelText2}>Other</Box>
                        <div className="flex gap-12">
                          
                          <div
                            className="mt-12 flex flex-col gap-16 justify-center items-center relative cursor-pointer"
                            style={{
                              height: "208px",
                              width: "248px",
                              borderRadius: "4px",
                              border: `1px solid  ${"#E8E6FF"}`,
                            }}
                          >
                            <Avatar
                              variant="rounded"
                              className={classes.item_avatar90}
                              alt="sap"
                              src={"assets/icones/settings/expensify.svg"}
                            />

                            <Box class={classes.smallLabelText2}>Expensify</Box>
                          </div>
                          <div
                            className="mt-12 flex flex-col gap-16 justify-center items-center relative cursor-pointer"
                            style={{
                              height: "208px",
                              width: "248px",
                              borderRadius: "4px",
                              border: `1px solid  ${"#E8E6FF"}`,
                            }}
                          >
                            

                            <Avatar
                              variant="rounded"
                              className={classes.item_avatar90}
                              alt="sap"
                              src={"assets/icones/settings/QuickBook.svg"}
                            />

                            <Box
                              class={classes.smallLabelText2}
                              sx={{ minWidth: "100px" }}
                            >
                              Quickbooks
                            </Box>
                          </div>
                        </div>
                      </div>
                    </DialogContent>
                
                    <DialogTitle id="responsive-dialog-title">
                      <div className="flex justify-between items-center">
                        <Box
                          style={{ color: "#575579" }}
                          class={classes.smallLabelText2}
                        >
                          SUGGEST US CONNECTION
                        </Box>
                        <Button
                          variant="contained"
                          class={classes.connectButton}
                          color="primary"
                          onClick={handleModel}
                          disabled
                        >
                          + CONNECT
                        </Button>
                      </div>
                    </DialogTitle>
                  </Dialog>
                </div>
              </div>
            </Box> */}
            {/* <Box class={classes.connection_items}>
              <div className="flex items-center w-full relative">
                <Avatar
                  variant="rounded"
                  className={classes.item_avatar}
                  alt="jaa dde"
                  src={"assets/icones/settings/ceridain.svg"}
                />
                <div className="flex-col items-center ml-16">
                  <Typography classes={classes.smallLabel}>
                    SAP Concur
                  </Typography>
                  <div class={classes.tags}>Expense Account</div>
                </div>
                <div className="flex items-center absolute right-0">
                  <Button
                    variant="contained"
                    class={classes.reviweBotton}
                    color="primary"
                  >
                    CHANGE
                  </Button>
                </div>
              </div>
            </Box> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Company;
