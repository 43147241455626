import React from "react";

const TransactionsConfig = {
  settings: {
    layout: {
      config: {
        navTitle:"Transactions"
      },
    },
  },
  routes: [
    {
      path: "/subscribe/transaction",
      component: React.lazy(() => import("./Transactions")),
    },
  ],
};

export default TransactionsConfig;