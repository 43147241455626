import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    rangeTextBox:{
        justifyContent: 'space-between',
    },
    rangeText:{
        // font-family: 'Inter';
        // font-style: normal;
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
/* identical to box height */

       letterSpacing: "0.005em",
       marginBottom:"8px",

/* Neutrals/Labels&Icons */

        color: "#7F7E8C"
    },
    track: {
        width:"100%", 
        height: "8px", 
        backgroundColor:"#E8E6FF",  
        borderRadius: "4px"
      },
      rail: {
        height: "8px", 
        backgroundColor:"#6456F7", 
        borderRadius: "4px 0px 0px 4px" 
      },
  }));
  
function RangSider({leftText, rightText, rangePercent}) {
    const classes = useStyles()
  return (
    <Box>

    <Box component="div" display="flex" style={{ justifyContent:"space-between"}}>
        <Typography class={classes.rangeText}>{leftText}</Typography>
        <Typography class={classes.rangeText}>{rightText}</Typography>
    </Box>
        <Box component="div" class={classes.track}>
            <Box component="div"  class={classes.rail} style={{width:`${rangePercent >= 100 ? 100 : rangePercent}%`}}>
            </Box>
        </Box>
    </Box>
  )
}


export default RangSider