import { useLocation, useHistory, Redirect } from "react-router-dom";

export const setToken = (token) => {
  localStorage.setItem("token", token); // make up your own token
};

export const fetchToken = (token) => {
  return localStorage.getItem("token");
};

export function RequireToken({ children }) {
  let auth = fetchToken();
  let location = useLocation();
  let history = useHistory();

  if (!auth) {
    
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
   
  }
  console.log("testing",auth)
  return children;
}
