import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { submitLogin } from 'app/auth/store/loginSlice';
import * as yup from 'yup';
import _ from '@lodash';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'react-router-dom';
import { fetchToken, setToken } from "../auth/auth";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  login: {
    width: "586px",
    height: "max-content",
    marginTop: '50px',
    height: '528px'
  },
  loginWrapper: {
    background: "#FFFFFF",
    boxShadow: "1px 1px 13px rgba(76, 72, 113, 0.1)",
    borderRadius: "8px"
  },
  title: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "28px",
    lineHeight: "36px",
    /* identical to box height */
    textAlign: "center",
    letterSpacing: "-0.02em",
    /* Neutrals/Text_Black */
    color: "#313133",
    marginBottom: "30px",
    fontStyle: 'normal'
  },
  container: {
    // background: "#E5E5E5",
    padding: "0 127px",
    position: 'relative',
    // border: '1px solid black',
    height: '100vh',
    overflow: 'hidden',
    paddingTop: '19px'
  },
  topbutton: {
    border: "1px solid #E8E6FF",
    padding: "10px",
    borderRadius: "4px",
    color: "#313133 !important",
    width: "159px",
    height: "44px",
    textDecoration: "none !important",
    display: "flex",

  },
  loginBtn: {
    background: "#49B892",
    borderRadius: "4px",
    color: "white",
    height: '50px',
    marginTop: '30px'
  },
  glogn: {
    background: "#FFFFFF",
    border: "1px solid #E8E6FF",
    borderRadius: "4px",
    height: '50px',
  },
  divider: {
    padding: "0 10px 0 10px"
  },
  headerText: {
    fontSize: "24px",
    lineHeight: "31px",
    letterSpacing: "-0.01em",
    fontStyle: "normal",
    color: "#6456F7",
    fontWeight: 500,
  },
  headerTextHeighlight: {
    fontSize: "24px",
    lineHeight: "31px",
    letterSpacing: "-0.01em",
    fontStyle: "normal",
    color: "#49B892",
    fontWeight: 500,
  }
}));

const Divider = ({ children }) => {
  const classes = useStyles()
  return (
    <div className="flex items-center">
      <div className="border-b w-full" />
      <span className={classes.divider}>
        {children}
      </span>
      <div className="border-b w-full" />
    </div>
  );
};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter a email'),
  password: yup
    .string()
    .required('Please enter your password.')
   
});

const defaultValues = {
  email: '',
  password: '',
};

function Login(props) {
  const classes = useStyles()
  const history = useHistory()
  // const dispatch = useDispatch();
  // const login = useSelector(({ auth }) => auth.login);
  // const { control, setValue, formState, handleSubmit, reset, trigger, setError } = useForm({
  //   mode: 'onChange',
  //   defaultValues,
  //   resolver: yupResolver(schema),
  // });

  // const { isValid, dirtyFields, errors } = formState;

  const [showPassword, setShowPassword] = useState(false);
  const [passwordError,setPasswordError]= useState("")
  const [localstor,setLocalStorage]= useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });


  // useEffect(() => {
  //   setValue('email', 'user@gmail.com', { shouldDirty: true, shouldValidate: true });
  //   setValue('password', 'testpass', { shouldDirty: true, shouldValidate: true });
  // }, [reset, setValue, trigger]);

  // useEffect(() => {
  //   login.errors.forEach((error) => {
  //     setError(error.type, {
  //       type: 'manual',
  //       message: error.message,
  //     });
  //   });
  // }, [login.errors, setError]);

  if (localstor) {
    return <Redirect to="/subscribe/dashboard" />;
  }

  function onSubmit(model) {
    console.log(model)

    if (model) {
      axios
        .post("https://demo.subscribepod.com/api/v1/users/login/", {
          email: model.email,
          password: model.password,
        })
        .then(function (response) {
          console.log(response.data);
          if (response.data.access_token) {
            setToken(response.data.access_token);
            localStorage.setItem("email",response.data.email);
            localStorage.setItem("user",response.data.user_name);
            setLocalStorage(true)
            //console.log(fetchToken())
            //history.push('/subscribe/dashboard');
          }
        })
        .catch(function (error) {
          console.log(error, "error");
         let  passwordError ="Error! check your credentials again";
          setPasswordError(passwordError);
          return;
        });
    }
    
    
    //dispatch(submitLogin(model));
  }

  function onForgotpasswordClick(model) {
    history.push('/forgot-password')
    //dispatch(submitLogin(model));
  }

  return (
    <div className={classes.container}>
      <div className='flex justify-between w-full'>
        <div className='flex'>
          <img src="assets/icones/logo/Logo_short.svg" />
          <div className={classes.headerText}>Subscribe<span className={classes.headerTextHeighlight}>pod</span>
          </div>
        </div>
        <Link to="/register" className={`${classes.topbutton} flex justify-center items-center`}>REQUEST DEMO</Link>
      </div>
      <div className={`flex w-full h-full justify-center `}>
        <div className={`${classes.login} p-28  ${classes.loginWrapper} items-center`} style={{ zIndex: 15 }}>
          <Typography className={classes.title}>Welcome back!</Typography>
          {passwordError && <Typography fontSize={4} style={{textAlign:"center"}} color="error">{passwordError}</Typography>}
          <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <InputLabel className='mb-4'>Business Email</InputLabel>
             
                  <TextField
                    
                    className="mb-24"
                    type="text"
                    error={!!errors.email}
                    {...register("email")}
                    helperText={errors?.email?.message}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className="text-20" color="action">
                            user
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    required
                  />
               
            </div>
            <div>
              <InputLabel className='mb-4'>Password</InputLabel>
              
                  <TextField
                    
                    className="mb-24"
                    fullWidth
                    type="password"
                    {...register("password")}
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    variant="outlined"
                    InputProps={{
                      className: 'pr-2',
                      type: showPassword ? 'text' : 'password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                            <Icon className="text-20" color="action">
                              {showPassword ? 'visibility' : 'visibility_off'}
                            </Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
              
            </div>
            <div className='flex justify-end'>
              <Typography className='text-grey-700 cursor-pointer' onClick={onForgotpasswordClick}>FORGOT PASSWORD?</Typography>
            </div>
            <div>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                // color="primary"
                className={`w-full mx-auto mt-16 ${classes.loginBtn}`}
                aria-label="CONTINUE"
               // disabled={_.isEmpty(dirtyFields) || !isValid}
                value="legacy"
              >
                CONTINUE
              </Button>
              <div className='py-24'><Divider>Or</Divider></div>

              <Button
                fullWidth
                type="submit"
                variant="contained"
                className={`w-full mx-auto ${classes.glogn}`}
                aria-label="CONTINUE WITH GOOGLE"
                // disabled={_.isEmpty(dirtyFields) || !isValid}
                value="legacy"
              >
                <div className='flex justify-center gap-x-4 w-full items-center'>
                  <span><img src="assets/icones/social_login/google-logo.svg" /></span>
                  <span>CONTINUE WITH GOOGLE</span>
                </div>
              </Button>
            </div>
          </form>
        </div>
      </div >
      <img src='assets/icones/iconography/bottom-curve.png' width='100%'
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          zIndex: 10
        }}
      />
    </div>
  );
}

export default Login;