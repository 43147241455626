import React from 'react'
import { alpha, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, InputBase, InputLabel, makeStyles, MenuItem, Select, styled, TextField, Typography } from '@material-ui/core'
import {
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
} from "@material-ui/core";
import { SpaRounded } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  container:{
    // border:"1px solid #E8E6FF",
    bordeRadius: "8px"
  },
  main:{
    backgroundColor:  "#FFFFFF",
    boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
    borderRadius: "8px",
    marginTop: "30px", 
    height: "100%",
  },
  smallLabelText:{
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    linehHeight: "15px",
    letterSpacing: "0.005em",
    // marginLeft:"24px",
    color: "#7F7E8C",
    textAlign: "left",
    width:"100%"
   
  },
  sub_text:{
    // fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    linehHeight: "18px",
    letterSpacing: "0.0025em",
    // marginTop:"12px",

/* Neutrals/Subheading */

    color: "#575759"

  },
  heading3:{
    
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "23px",
    margin:"28px 40px",
    color: "#313133",

  },
  heading4:{   
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "23px",
    color: "#313133",
    marginBottom: "12px"
  },
  heading4_white: {   
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "23px",
    color: "#FFFFFF",
    marginBottom: "12px"
  },
  heading1_white: {   
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "23px",
    color: "#FFFFFF",
    marginBottom: "12px",
    
  },
  text_grey: {   
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "16px",
    color: "#7F7E8C",
    // marginBottom: "12px",
    
  },
  text_CardNumber:{
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#313133"
  },
  current_planBox:{
    background: "#FFFFFF",
    /* Neutrals/Border */

    border: "1px solid #E8E6FF",
    /* Elevation2 */

    boxShadow:"1px 1px 13px rgba(76, 72, 113, 0.1)",
    bordeRadius: "8px"
  },
  current_plan:{
    // position: absolute;
    width: "301px",
    height: "164px",

    background: "linear-gradient(105.5deg, #8378F9 0.81%, #6456F7 100%)",
    /* Elevation2 */
    padding: "30px",

    boxShadow:"1px 1px 13px rgba(76, 72, 113, 0.1)",
    borderRadius: "8px 0px 0px 8px"
      
  },
  addButton:{
    boxSizing: "border-box",
    padding:"27px 37px",

    /* Neutrals/Text_White */

    background: "#FFFFFF",
    /* Neutrals/Border */

    border: "1px dashed #E8E6FF",
    bordeRadius: "8px"
  },
  payment_cards:{
    background: "#FFFFFF",
/* Neutrals/Border */

    border: "1px solid #E8E6FF",
/* Elevation2 */

    boxShadow:"1px 1px 13px rgba(76, 72, 113, 0.1)",
    bordeRadius: "8px",
    padding:"24px"
  },
  connectButton:{
    padding: "9px 16px",

    

/* Neutrals/Border */

    border: "1px solid #E8E6FF",
    borderRadius: "4px",
    color: "#FFFFFF",
    backgroundColor: "#49B892",
    fontSize:"13px",
    fontWeight:"500"
  },
  reviweBotton:{
    padding: "9px 16px",

    width: "88px",
    height: "40px",

/* Neutrals/Border */

    border: "1px solid #E8E6FF",
    borderRadius: "4px",
    color: "#7F7E8C"

  },
  
}));
const dataText = [
  "SaaS Management", "SaaS Vendor Management", "SaaS Vendor Management",
  "Vendor Management", "SaaS Vendor Management", "SaaS Vendor Management",
  "Workflows", "SaaS Vendor Management", "SaaS Vendor Management",
]
const historyData = [
  {
    date: "02 Mar 22",
    invoiceId : "Inv-001201211", 
    amount: "$1,499", 
    plan : "Enterprise",
    status : "Completed",
   },

];
const columTitleRow = ['DATE', 'INVOICE ID', 'AMOUNT', 'PLAN', 'STATUS', ''];

function Billing() {
  const classes = useStyles();
  const [addCard, setAddCard] = React.useState(false)
  const [editCard, setEditCard] = React.useState(false)
  const [cardType, setCardType] = React.useState("visa")
  const [cardMethod, setCardMethod] = React.useState("Credit card payment")
  
  const handleAddCardButton = () => setAddCard(!addCard)
  const handleEditCardButton = () => setEditCard(!editCard)

  const handleCardType = (event) => setCardType(event.target.value)
  const handleCardMethod = (event) => setCardMethod(event.target.value)

  
return (
  <Box class={classes.main}>
  <Grid container spacing={0}>
  <Grid item xs={12} >
      <Box> 
          <Typography class={classes.heading3} >Billing</Typography>    
      </Box>
      <Divider /> 
  </Grid>
  <Grid item xs={12} >
      <div className="p-32">
        <div>
          <Typography class={classes.heading4} >Current Plan</Typography> 
          <Box class={classes.current_planBox} >
          <Grid container >
             <Grid item xs={3} >
                <Box class={classes.current_plan}>
                <Typography class={classes.heading4_white} >Enterprise plan</Typography> 
              
                  <div class={classes.heading4_white} style={{display: 'flex'}} >$
                  <Typography class={classes.heading1_white} >14,999 </Typography> 
                  /month
                  </div> 
    
                </Box>
             </Grid >
             <Grid item xs={9} >
               <div className="pl-32 m-12">
                <div className="mb-14 mt-24">
                  <Typography class={classes.text_grey} >This includes:</Typography>
                </div>
               <Grid container>
                
              {dataText.map((item, key)=>
                <Grid item xs={4} key={key}> 
                   <div className="flex mb-12 ">
                   <img className='mx-5' src={'assets/icones/settings/greencheck.svg'} />
                    <Typography  class={classes.text_grey} >{item}</Typography>
                    </div>
                </Grid>
              )}
               </Grid>
               </div>
              {/* </div> */}
             </Grid >
           </Grid>
           </Box>
          </div>
        <div class="mt-12">
          <Typography class={classes.heading4} >Payment method</Typography>
           <Grid container>
              <Grid item xs={3}>
              <Button variant="contained" class={classes.addButton} color="primary" onClick={handleAddCardButton}>
                <Typography  class={classes.text_grey} >+ ADD NEW METHOD</Typography>  
              </Button>
              <Dialog 
              open={addCard}
              maxWidth={"sm"}
              scroll={"body"}
              >
              <div className="flex justify-between items-center p-12">
              <DialogTitle>Edit Credit Card info</DialogTitle>
              <Button autoFocus onClick={handleAddCardButton} color="primary">
            
            <img src={'assets/icones/settings/close_button.svg'} alt="close" />
            </Button>
              
              </div>   
              <DialogContent>
                <div className="mt-12 mb-20 mx-12">
                <Grid container spacing={3}  >
                  <Grid item xs={6}>
                    <InputLabel className="mb-5" > Card Holder </InputLabel>
                    <TextField name="card_holder" value=""  variant="outlined"  size="small" style={{width:"100%"}} />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel className="mb-5" > Type </InputLabel>
                    <Select
                      value={cardType}
                      onChange={handleCardType}
                      borderRadius="4px"
                      margin="dense"
                      variant="outlined"
                      style={{width:"100%"}}>
                      <MenuItem value={'Rupay'}>visa</MenuItem>
                      <MenuItem value={'master_card'}>Master Card</MenuItem>
      
                    </Select>
                  </Grid>
                  
                  <Grid item xs={9}>
                    <InputLabel className="mb-5" > Card Number </InputLabel>
                    <TextField name="card_number" value=""  variant="outlined"  size="small"  style={{width:"100%"}}/>
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel className="mb-5" > CCV </InputLabel>
                    <TextField name="cvv" value=""  variant="outlined"  size="small" style={{width:"100%"}} />
                  </Grid>
              </Grid>
                </div>
              </DialogContent>
              <Divider />
                <div className="p-12">
              <DialogActions>

              <Button variant="contained" class={classes.reviweBotton}  onClick={handleAddCardButton} > CANCEL</Button>
              <Button variant="contained" class={classes.connectButton}  onClick={handleAddCardButton} > CHANGE</Button>
              </DialogActions>
                </div>
              
            </Dialog>
               
              </Grid>
              <Grid item xs={4}>
                  <Box class={classes.payment_cards}> 
                      <div class="flex w-full justify-between">
                      <div>
                        <div className="flex mb-4 mr-12"> 
                        <Typography  class={classes.text_grey}>Credit card</Typography> 
                          <img className='mx-8 cursor-pointer' src={'assets/icones/settings/edit.svg'} onClick={handleEditCardButton} />
                          <Dialog 
                            open={editCard}
                            maxWidth={"sm"}
                            scroll={"body"}
                            >
                            <div className="flex justify-between items-center p-12">
                            <DialogTitle>Add payment method</DialogTitle>
                            <Button autoFocus onClick={handleEditCardButton} color="primary">
                          
                          <img src={'assets/icones/settings/close_button.svg'} alt="close" />
                          </Button>
                            
                            </div>   
                            <DialogContent>
                              <div className="mt-12 mb-20 mx-12">
                              <Grid container spacing={3}  >
                        
                                <Grid item xs={12}>
                                  <InputLabel className="mb-5" > Payment Method </InputLabel>
                                  <Select
                                    value={cardMethod}
                                    onChange={handleCardMethod}
                                    borderRadius="4px"
                                    margin="dense"
                                    variant="outlined"
                                    style={{width:"100%"}}>
                                    <MenuItem value={'credit_card_payment'}>Credit card payment</MenuItem>
                                    <MenuItem value={'debit_card_payment"'}>Debit card payment</MenuItem>
                                  </Select>
                                </Grid>
                                <Grid item xs={12}>
                                  <InputLabel className="mb-5" > Card Name </InputLabel>
                                  <TextField name="card_name" value=""  variant="outlined"  size="small" style={{width:"100%"}} />
                                </Grid>
                                <Grid item xs={6}>
                                  <InputLabel className="mb-5" > Cardholder Name </InputLabel>
                                  <TextField name="card_holder_name" value=""  variant="outlined"  size="small" style={{width:"100%"}} />
                                </Grid>
                                <Grid item xs={6}>
                                  <InputLabel className="mb-5" > Type </InputLabel>
                                  <Select
                                    value={cardType}
                                    onChange={handleCardType}
                                    borderRadius="4px"
                                    margin="dense"
                                    variant="outlined"
                                    style={{width:"100%"}}>
                                    <MenuItem value={'Rupay'}>visa</MenuItem>
                                    <MenuItem value={'master_card'}>Master Card</MenuItem>
                    
                                  </Select>
                                </Grid>
                                
                                <Grid item xs={9}>
                                  <InputLabel className="mb-5" > Card Number </InputLabel>
                                  <TextField name="card_number" value="XXXX XXXX XXXX XXXX"  variant="outlined"  size="small"  style={{width:"100%"}}/>
                                </Grid>
                                <Grid item xs={3}>
                                  <InputLabel className="mb-5" > CCV </InputLabel>
                                  <TextField name="cvv" value="xxx"  variant="outlined"  size="small" style={{width:"100%"}} />
                                </Grid>
                              </Grid>
                            </div>
                            </DialogContent>
                            <Divider />
                              <div className="p-12">
                            <DialogActions>

                            <Button variant="contained" class={classes.reviweBotton}  onClick={handleEditCardButton} > CANCEL</Button>
                            <Button variant="contained" class={classes.connectButton}  onClick={handleEditCardButton} > ADD PAYMENT METHOD</Button>
                            </DialogActions>
                              </div>
                            
                          </Dialog>
                        </div>

                        <Typography  class={classes.text_CardNumber}>XXXX  XXXX  XXXX  0123</Typography> 
                      </div>
                      <img  src={'assets/icones/settings/visa.svg'} />
                      </div>
                  </Box>
              </Grid>
           </Grid>
          
        </div>
        <div>
          <Typography class={classes.heading4} >History</Typography> 
          <TableContainer className={classes.container}>
        <Table style={{ border: '1px solid #E8E6FF' }}>
          <TableHead style={{ background: '#FCFCFF' }}>
            <TableRow>
              {columTitleRow.map((title) => (
                <TableCell
                  key={title}
                  align={"left"}
                  padding={'normal'}
                  style={{color :"#575759"}}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {historyData.map((item, index) => (
              <TableRow key={index} hover style={{color :"#575759"}}>
                <TableCell align={"left"} padding={'normal'} style={{color :"#575759"}}>
                   {item.date}
                </TableCell>
                <TableCell align={"left"} padding={'normal'} style={{color :"#575759"}}>
                {item.invoiceId }
                </TableCell>
                <TableCell align={"left"} padding={'normal'} style={{color :"#575759"}} >
                {item.amount}
                </TableCell>
                <TableCell align={"left"} padding={'normal'} style={{color :"#575759"}} >
                {item.plan}
                </TableCell>
                <TableCell align={"left"} padding={'normal'} >
                  <span className="Small_13R" style={{padding: "3px 6px", gap: "4px",background: "#E9F8F3",borderRadius: "4px", color: "#2C6E58"}}>

                {item.status}
                  </span>
                </TableCell>
                <TableCell align={"left"} padding={'normal'} >
                  <img src={'assets/icones/settings/download.svg'} ></img> 
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        </div>
      </div>
  </Grid>
  
  
    
 
   </Grid>
   </Box>
)
}

export default Billing