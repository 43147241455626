import {
  makeStyles,
  Typography,
  Button,
  Box,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  Avatar,
  Slide,
} from "@material-ui/core";
import React from "react";
// import { useHistory } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckIcon from "@mui/icons-material/Check";
import { AvatarGroup } from "@mui/material";
import { Redirect, useHistory } from "react-router-dom";

const useStyles = makeStyles({
  paper: {
    width: "249px",
    height: "182px",
    marginTop: "16px",
    borderRadius: "8px",
    paddingLeft: "24px",
    marginRight: "10px",
    padding: "24px",
    cursor: "pointer",
    marginLeft: "30px",
  },
  top: {
    display: "flex",
  },
  connectedText: {
    color: "#3A9375",
    fontSize: "13px",
  },
  image: {
    width: "66px",
    height: "66px",
  },
  imageDialogBox: {
    width: "36px",
    height: "36px",
  },
  imageDialogBoxLogo: {
    width: "80px",
    height: "80px",
  },
  bidirectionArrow: {
    width: "20px",
    height: "16px",
    margin: "0 28px",
  },
  t1: {
    marginTop: "16px",
    fontSize: "18px",
    color: "#313133",
  },
  t2: {
    marginTop: "8px",
    marginBottom: "16px",
    color: "#313133",
  },
  stopButton: {
    color: "#CC2525",
    border: "1px solid #E8E6FF",
    borderRadius: "4px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#313133",
  },
  // stopItButton: {
  //   fontSize: '13px',
  //   borderRadius: "4px",
  //   height: '40px',
  //   width: '165px',
  //   background: '#49B892',
  //   marginRight: '40px',
  //   marginBottom: '30px',
  //   color: '#FFFFFF'
  // },
  mayBeButton: {
    fontSize: "13px",
    borderRadius: "4px",
    height: "40px",
    width: "144px",
    marginRight: "16px",
    marginBottom: "30px",
    color: "#7F7E8C",
  },
  tableTagContainer: {
    background: "#F7E9E9",
    borderRadius: "4px",
    padding: "6px",
    height: "26px",
    width: "max-content",
  },
  tagContainer: {
    background: "#E8E6FF",
    borderRadius: "4px",
    padding: "8px",
    height: "26px",
    width: "80px",
    marginTop: "20px",
    marginRight: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  reviweBotton: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "9px 16px",
    width: "82px",
    height: "40px",
    background: "#49B892",
    color: "#FFFFFF",
    borderRadius: "4px",
  },
  root: {
    margin: "40px",
  },
  loader: {
    backgroundColor: "white",
    marginTop: "250px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  container: {
  
    marginTop: "25px",
  },
  textAlign: {
    textAlign: "left",
  },
  columTitleRow: {
    background: "#FCFCFF",
    border: "1px solid #E8E6FF",
  },
  stopButton: {
    // width: '60px',
    // height: '40px',
    // borderRadius: '8px',
    color: "#CC2525",
    border: "1px solid #E8E6FF",
    borderRadius: "4px",
  },
  dialogTitle: {
    marginLeft: "30px",
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: "500",
    color: "#313133",
    width: "350px",
  },
  stopItButton: {
    fontSize: "13px",
    borderRadius: "4px",
    height: "40px",
    width: "144px",
    background: "red",
    color: "white",
    marginRight: "40px",
    marginBottom: "30px",
    "&:hover": {
      background: "red",
    },
  },
  mayBeButton: {
    fontSize: "13px",
    borderRadius: "4px",
    height: "40px",
    width: "144px",
    marginRight: "16px",
    marginBottom: "30px",
    color: "#7F7E8C",
  },
});

const users = [
  { 
    ID:1,
    NAME: "Harsh",
    EMAIL: "harsh@gmail.com",
    TEAM: "Design",
    SOURCE: "QAQA",
    "LAST USED": "2 Months ago",
  },
  {
    ID:2,
    NAME: "Harsh",
    EMAIL: "harsh@gmail.com",
    TEAM: "Design",
    SOURCE: "QAQA",
    "LAST USED": "2 Months ago",
  },
  { 
    ID:3,
    NAME: "Harsh",
    EMAIL: "harsh@gmail.com",
    TEAM: "Design",
    SOURCE: "QAQA",
    "LAST USED": "2 Months ago",
  },
  {
    ID:4,
    NAME: "Harsh",
    EMAIL: "harsh@gmail.com",
    TEAM: "Design",
    SOURCE: "QAQA",
    "LAST USED": "2 Months ago",
  },
  {
    ID:5,
    NAME: "Harsh",
    EMAIL: "harsh@gmail.com",
    TEAM: "Design",
    SOURCE: "QAQA",
    "LAST USED": "2 Months ago",
  },
  {
    ID:6,
    NAME: "Harsh",
    EMAIL: "harsh@gmail.com",
    TEAM: "Design",
    SOURCE: "QAQA",
    "LAST USED": "2 Months ago",
  },
  {
    ID:7,
    NAME: "Harsh",
    EMAIL: "harsh@gmail.com",
    TEAM: "Design",
    SOURCE: "QAQA",
    "LAST USED": "2 Months ago",
  },
  {
    ID:8,
    NAME: "Harsh",
    EMAIL: "harsh@gmail.com",
    TEAM: "Design",
    SOURCE: "QAQA",
    "LAST USED": "2 Months ago",
  },
  {
    ID:9,
    NAME: "Harsh",
    EMAIL: "harsh@gmail.com",
    TEAM: "Design",
    SOURCE: "QAQA",
    "LAST USED": "2 Months ago",
  },
  {
    ID:10,
    NAME: "Harsh",
    EMAIL: "harsh@gmail.com",
    TEAM: "Design",
    SOURCE: "QAQA",
    "LAST USED": "2 Months ago",
  },
  {
    ID:11,
    NAME: "harsh harh harsh",
    EMAIL: "abcdef@gmail.com",
    TEAM: "Design",
    SOURCE: "QAQA",
    "LAST USED": "2 Months ago",
  },
];

const TableTag = ({ tag }) => {
  const classes = useStyles();
  return (
    <div className={classes.tableTagContainer}>
      <div style={{ fontSize: "11px", color: "#CC2525" }}>{tag}</div>
    </div>
  );
};

const Tag = ({ tag }) => {
  const classes = useStyles();
  return (
    <div className={classes.tagContainer}>
      <div style={{ fontSize: "11px" }}>{tag}</div>
    </div>
  );
};

function ReviewButtonWithDialog({ subscriptionName }) {
  const classes = useStyles();
  const history= useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [redirect, setRedirect] = React.useState(false)
  const [button,setbutton] = React.useState(false)
  // const history = useHistory();

  const columTitleRow = ["NAME", "TEAM", "SOURCE", "LAST USED"];
  const [open, setOpen] = React.useState(false);
  const [deprovisionOpen, setDeprovisionOpen] = React.useState(false);
  // const [allowAccessOpen, setAllowAccessOpen] = React.useState(false);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // history.push("/loading");
    // history.push("/subscribe/insight");
    setbutton(true) 
    setRedirect(true)
   
   

  };
  

  const handleClickDeprovisionOpen = () => {
    setDeprovisionOpen(true);
    setOpen(false);
  };

  const handleClickDeprovisionClose = () => {
    setDeprovisionOpen(false);
    setOpen(false);
  };

  const onChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };



  const showToastMessage = () => {
    console.log("toats");
    toast.success(
      "Successfully run the Deprovision Workflow “60-Day License Deprovision”.",
      {
        position: toast.POSITION.CENTER,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      }
    );
    // setDeprovisionOpen(false);
    setOpen(false);
  };

const  handleRedirect =()=>{

  if(redirect){
   
    return <Redirect to="/subscribe/insight" />
    
    
  }
  else{
  return (
    <>
      <Button
        variant="contained"
        className={classes.reviweBotton}
        color="primary"
        onClick={handleClickOpen}
      >
        Review
      </Button>
    
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
     
      >
        <div
          style={{
            display: "flex",
            height: "88px",
            borderBottom: "1px solid #E8E6FF",
            paddingBottom: "22px",
          }}
        >
          <div
            style={{
              display: "flex",
              marginLeft: "48px",
              marginTop: "30px",
              alignItems: "center",
            }}
          >
            <img
              src="assets/icones/integrations/integration-icon-2.svg"
              className={classes.imageDialogBox}
              key="integration-icon"
            />
            <div className="flex align-center">
              <DialogTitle id="alert-dialog-title" className={classes.title}>
                {subscriptionName}
              </DialogTitle>
              <Tag  tag="Verified" key="tag-verified" />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              onClick={handleClose}
              style={{
                borderRadius: "4px",
                width: "32px",
                height: "32px",
                marginTop: "16px",
                marginRight: "16px",
                background: "#F7F7FF",
              }}
            >
              <CloseIcon style={{ width: "13.33", height: "13.33" }} />
            </Button>
          </div>
        </div>

        <DialogContent
          style={{ marginLeft: "25px", fontSize: "14px", color: "#7F7E8C" }}
        >
          <div style={{ display: "flex", width: "100%", marginRight: "48px" }}>
            <div>
              <Typography
                style={{ fontSize: "18px", color: "#313133", weight: 500 }}
              >
                80 inactive users out of 350
              </Typography>
              <Typography
                style={{ fontSize: "14px", color: "#575759", weight: 400 }}
              >
                We found around 80 inactive users for the past 60 days.
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <div style={{ display: "inline-block" }}>
                <Typography
                  style={{ fontSize: "22px", color: "#313133", weight: 500 }}
                >
                  <span style={{ fontSize: "12px" }}>$ </span>15,000
                </Typography>
                <Typography>Potential Savings</Typography>
              </div>
            </div>
          </div>
          <TableContainer className={classes.container}>
            <Table>
              <TableHead >
                <TableRow>
                  {columTitleRow.map((title) => (
                    <TableCell
                      key={title}
                      className={`${classes.textAlign} ${classes.columTitleRow}`}
                    >
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user, index) => (
                    <TableRow key={user.ID} hover>
                      <TableCell>
                        <div style={{ display: "flex", cursor: "pointer" }}>
                          <Avatar src="assets/icones/insight/Framer Icon.svg" />
                          <div
                            style={{
                              display: "inline-block",
                              marginLeft: "8px",
                            }}
                          >
                            <Typography>{user.NAME}</Typography>
                            <Typography>{user.EMAIL}</Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={classes.textAlign}>
                        {user.TEAM}
                      </TableCell>
                      <TableCell className={classes.textAlign}>
                        {user.SOURCE}
                      </TableCell>
                      <TableCell className={classes.textAlign}>
                        <TableTag tag={user["LAST USED"]} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
            count={rowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </DialogContent>
        <DialogActions>
          <Divider orientation="horizontal" />
          <Button
            style={{
              marginTop: "16px",
              borderRadius: "4px",
              border: "1px solid #F7F7FF",
              padding: "10px",
              marginBottom: "16px",
              marginRight: "20px",
              width: "113px",
              height: "40px",
            }}
            onClick={handleClose}
          >
            CANCEL
          </Button>
          <Button
            style={{
              marginTop: "16px",
              borderRadius: "4px",
              border: "1px solid #F7F7FF",
              padding: "10px",
              marginBottom: "16px",
              marginRight: "48px",
              background: "#49B892",
              width: "231px",
              height: "40px",
              color: "white",
            }}
            onClick={handleClickDeprovisionOpen}
          >
            Run Deprovision Workflow
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deprovisionOpen}
        onClose={handleClickDeprovisionClose}
        fullWidth={true}
        maxWidth="sm"
       
      >
        <div style={{ display: "flex" }}>
          <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
            {"Are you sure that you want to deprovision these licenses?"}
          </DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              onClick={handleClickDeprovisionClose}
              style={{
                borderRadius: "4px",
                width: "32px",
                height: "32px",
                marginTop: "16px",
                marginRight: "16px",
                background: "#F7F7FF",
              }}
            >
              <CloseIcon style={{ width: "13.33", height: "13.33" }} />
            </Button>
          </div>
        </div>
        <DialogContent
          style={{ marginLeft: "30px", fontSize: "14px", color: "#7F7E8C" }}
        >
          <Typography>
            You will be navigated to run the “60-Day License Deprovision”
            workflow which will review these licenses.
          </Typography>
          <AvatarGroup
            max={4}
            spacing={12}
            style={{
              justifyContent: "left",
              marginTop: "16px",
              marginBottom: "32px",
            }}
          >
            <Avatar alt="Remy Sharp" src="assets/icones/insight/Avatar.svg" />
            <Avatar
              alt="Travis Howard"
              src="assets/icones/insight/Avatar 2.svg"
            />
            <Avatar
              alt="Cindy Baker"
              src="assets/icones/insight/Avatar 3.svg"
            />
            <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
            <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
          </AvatarGroup>
          <Typography style={{ color: "#313133", fontSize: "13px" }}>
            This workflow includes:
          </Typography>
          <div className="flex mb-8 mt-16">
            <CheckIcon style={{}} />
            <Typography style={{ marginLeft: "10px" }}>
              Sending email alerts to users.
            </Typography>
          </div>
          <div className="flex mb-8">
            <CheckIcon style={{}} />
            <Typography style={{ marginLeft: "10px" }}>
              Sending email alerts to users.
            </Typography>
          </div>
        </DialogContent>
        <DialogActions style={{ marginTop: "40px" }}>
          <Button
            onClick={handleClickDeprovisionClose}
            className={classes.mayBeButton}
          >
            MAYBE LATER
          </Button>
          <Button onClick={showToastMessage} className={classes.stopItButton}>
            YES, CONTINUE
          </Button>
          <ToastContainer />
        </DialogActions>
      </Dialog>
    </>
  );
  }
}
return (
  <div>
    {/* Other parent component content */}
    {handleRedirect()}
    {/* Other parent component content */}
  </div>
);
}

export default ReviewButtonWithDialog;
