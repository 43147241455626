import {  Avatar, Box, Divider, makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { AvatarGroup } from '@mui/material';
import React from 'react'
const useStyles = makeStyles((theme) => ({
    column:{
      height:"100%"
    },
    row:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height:"20px",
        padding: "6px 0px"
    },
    avatarGroups:{
        display: "flex",
        justifyContent: "flex-end",
        flexDirection:"column",
        alignItems: "center",
        height:"70px",
        padding: "6px 0px"
    },
    avatar:{
        width: "16px",
        height: "16px",
    },
    divider: {
        height: "5px",
    },
    
    rangeText:{
        // font-family: 'Inter';
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
/* identical to box height */

       letterSpacing: "0.005em",
       marginTop:"8px",

/* Neutrals/Labels&Icons */

        color: "#7F7E8C"
    },
    
  }));
  
function ColumnCharts() {
    const classes = useStyles()
    const chartData=[
      
      {
        id:1,
        month:"May",
        imgs:[
          "assets/icones/insight/Framer Icon.svg",
        ]
      },
      {
        id:2,
        month:"Jun",
        imgs:[
          "assets/icones/insight/InVisionApp Icon.svg",
          "assets/icones/insight/Zoom Icon.svg",

        ]
      },
      {
        id:3,
        month:"Jul",
        imgs:[
          "assets/icones/insight/Zeplin Icon.svg",
         
        ]
      },
      {
        id:4,
        month:"Aug",
        imgs:["assets/icones/insight/ProtoPie Icon.svg","assets/icones/insight/Miro Icon.svg",]

      },
      {
        id:5,
        month:"Sep",
        imgs:[
          "assets/icones/insight/InVisionApp Icon.svg"]
      },
      {
        id:6,
        month:"Oct",
        imgs:[
          "assets/icones/insight/Zeplin Icon.svg",
         
        ]
      },
    
    ]

  return (
    <Box>

    <Box component="div" display="flex" style={{ justifyContent:"space-between"}}>
    <Grid container >
        {chartData.map((items,index)=>
          <Grid item xs={2} key={index}>
               <AvatarGroup   max={4} className={classes.avatarGroups}>
               {items?.imgs.map((img,key)=><Avatar  className={classes.avatar} alt={img} key={key}  src={img} />)}
               </AvatarGroup>
              <Box component="span">
                     <Divider></Divider>
              </Box>
              <Box component="span" className={classes.row}>
                  <Typography className={classes.rangeText}>{items.month}</Typography>
              </Box>
          </Grid>
        )}
        
        
    </Grid>
    </Box>
        
    </Box>
  )
}

export default ColumnCharts