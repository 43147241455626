import React from "react";

const TemplatesConfig = {
  settings: {
    layout: {
      config: {
        navTitle:"Templates",
        // toolbarColor:"#FFFFFF",
      },
    },
  },
  routes: [
    {
      path: "/subscribe/workflows/template",
      component: React.lazy(() => import("./Templates")),
    },
  ],
};

export default TemplatesConfig;