import React from "react";
import Onboarding from './Onboarding';
const OnboardingConfig = {
    settings: {
        layout: {
            config: {
              navbar: {
                display: false,
              },
              toolbar: {
                display: false,
              },
              footer: {
                display: false,
              },
              leftSidePanel: {
                display: false,
              },
              rightSidePanel: {
                display: false,
              },
              ispadding: false
            },
          },
    },
    routes: [
        {
            path: "/onboarding",
            component: Onboarding,
        },
    ],
};

export default OnboardingConfig;