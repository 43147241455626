import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import TabPanel from './components/TabPanel';
// import AllTab from './tabs/All';

import { Divider } from '@material-ui/core';
import All from './tabs/All';
import Renewals from './tabs/Renewals';
import Utilisation from './tabs/Utilisation';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor:"transparent"
  },
  appbar:{
    color: "#483EB3",
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: "0px",
    
  },
  tabs:{
    minWidth:"fit-content",
    marginRight: "30px",
    padding:"0px",
   
  },
  tabpanel:{
    
    padding:"0px",
  },
  indicator:{
    backgroundColor : "#483EB3",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const insightTabs = ['All', 'Utilisation', 'Renewals']
export default function Insights() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box >
      <AppBar position="static" className={classes.appbar}>
        <Tabs value={value} classes={{ indicator: classes.indicator }} onChange={handleChange} aria-label="simple tabs example">
           {insightTabs.map((item, i)=>{
            return <Tab className={classes.tabs} key={i} label={item} {...a11yProps(i)} />
          }) }
        </Tabs>
      </AppBar>
        <Divider/>
      <TabPanel value={value} index={0} className={classes.tabpanel}>
        <All> </All>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Utilisation> </Utilisation>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Renewals></Renewals>
      </TabPanel>
    </Box>
  );
}
