import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link ,useHistory} from 'react-router-dom';
import { logoutUser } from 'app/auth/store/userSlice';
import { Divider } from '@material-ui/core';


function UserMenu(props) {
  let history = useHistory();
  const user = useSelector(({ auth }) => auth.user);

  const [userMenu, setUserMenu] = useState(null);
  const [userdata, setUserData] = useState(localStorage.getItem("user"));
  const [useremail,setUserEmail]= useState(localStorage.getItem("email"));

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const handleSignout = () =>{
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("email");
    history.push("/login")

  }

  return (
    <>

      <Button className="min-h-40 px-0 md:px-16 py-0 md:py-6" onClick={userMenuClick}>
        {userdata.photoURL ? (
          <Avatar className="md:mx-4" alt="user photo" src={userdata.photoURL} />
        ) : (
          <Avatar className="md:mx-4" style={{ background: "#6456F7" }}>{`${userdata.match(/(\b\S)?/g).join("").toUpperCase()}`}</Avatar>
        )}
        <img src="assets/icones/toolbar/more.svg" />

      </Button>

      <Popover
      open={Boolean(userMenu)}
      anchorEl={userMenu}
      onClose={userMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{
        paper: 'py-8',
      }}
    >
      <div style={{ padding: '16px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <Avatar style={{ background: "#6456F7",marginRight: '8px' }}>{`${userdata.match(/(\b\S)?/g).join("").toUpperCase()}`} </Avatar>
          <div>
            <div>{userdata}</div>
            <div>{useremail}</div>
          </div>
        </div>
        <Divider style={{ marginBottom: '16px' }} />
        {
          userdata.role ? (
            <>
              <MenuItem component={Link} to="/login" role="button" >
                <ListItemIcon>
                  <Icon>lock</Icon>
                </ListItemIcon>
                <ListItemText primary="Login" />
              </MenuItem>
              <MenuItem component={Link} to="/register" role="button">
                <ListItemIcon>
                  <Icon>person_add</Icon>
                </ListItemIcon>
                <ListItemText primary="Register" />
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem 
                component={Link} to="/subscribe/settings" 
                onClick={userMenuClose}
                role="button"
                style={{ padding: '0',marginRight: '8px' }}
              >
                <ListItemIcon>
                  <Icon>account_circle</Icon>
                </ListItemIcon>
                <ListItemText primary="My Profile" />
              </MenuItem>
              <MenuItem onClick={handleSignout}  style={{ padding: '0' ,marginRight: '15px'}}>
                <ListItemIcon>
                  <Icon>exit_to_app</Icon>
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </>
          )
        }
      </div>
    </Popover>
    </>
  );
}

export default UserMenu;
