import React from "react";

const DetailpageConfig = {
    settings: {
        layout: {
            config: {
                toolbar: {
                    display: false
                }
            },
        },
    },
    routes: [
        {
            path: "/subscribe/subscription/:id",
            component: React.lazy(() => import("./Detailpage")),
        },
    ],
};

export default DetailpageConfig;