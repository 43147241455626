import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import TabPanel from './components/TabPanel';
// import AllTab from './tabs/All';

import { Divider } from '@material-ui/core';
import Notifications from './tabs/Notifications';
import Billing from './tabs/Billing';
import Administration from './tabs/Administration';
import Company from './tabs/Company';
import { Redirect } from 'react-router';
// import All from './tabs/All';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor:"transparent"
  },
  appbar:{
    color: "#483EB3",
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: "0px",
  },
  tabs:{
    // background:"blue"
    // minHeight:"10px",
    minWidth:"fit-content",
    marginRight: "30px",
    padding:"0px",
    
  },
  tabpanel:{
    
    padding:"0px",
  },
  indicator:{
    backgroundColor : "#483EB3",
  },
}));
const settingTabs = ['Company', 'Administration', 'Billing', 'Notifications']
export default function Settings() {
  const isAuthenticated = localStorage.getItem('token') !== null;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  if (isAuthenticated) {
  return (
    <Box >
      <AppBar position="static" className={classes.appbar}>
        <Tabs value={value} classes={{ indicator: classes.indicator }} onChange={handleChange} aria-label="simple tabs example">
           {settingTabs.map((item, i)=>{
            return <Tab className={classes.tabs} label={item} {...a11yProps(i)} />
          }) }
        </Tabs>
      </AppBar>
        <Divider/>
      <TabPanel value={value} index={0}>
      <Company />
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Administration />
      </TabPanel>
      <TabPanel value={value} index={2}>
       <Billing />
      </TabPanel>
      <TabPanel value={value} index={3}>
      <Notifications />
    
      </TabPanel>
    </Box>
  );
  }
  else {
    // Redirect to the login page when not authenticated
    return <Redirect to="/login" />;
  }
}

