
                            
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { submitRegister } from 'app/auth/store/registerSlice';
import _ from '@lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Userdetail from './Userdetail';
import Greeting from './Greeting';
import Button from '@material-ui/core/Button';
import { useHistory ,useLocation} from 'react-router-dom';
import React from 'react'
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { submitPassword } from 'app/auth/store/registerSlice';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import axios from "axios";
import { ErrorSharp } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    loginWrapper: {
        background: "#FFFFFF",
        borderRadius: "8px",
        boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
        width: "480px",
        height: "521px",
        paddingTop: '28px',
        zIndex: 15,
        paddingBottom: '20px',
        marginTop: '50px'
    },
    setPassword: {
        background: "#FFFFFF",
        borderRadius: "8px",
        boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
        width: "586px",
        height: "428px",
        paddingTop: '28px',
        zIndex: 15,
        paddingBottom: '20px',
        marginTop: '50px'
    },
    container: {
        // background: "#E5E5E5",
        padding: "0 60px",
        paddingTop: '20px',
        marginLeft: '123px'
    },
    topbutton: {
        border: "1px solid #E8E6FF",
        borderRadius: "4px",
        color: "#313133 !important",
        width: "50px",
        height: "30px",
        textDecoration: "none !important",
        display: "flex",
    },
    descHeader: {
        fontSize: "32px",
        lineHeight: "41px",
        letterSpacing: "-0.01em",
        fontStyle: "normal",
        color: " #313133",
        fontStyle: 'normal',

    },
    heighlight: {
        color: "#6456F7",
        fontSize: "32px",
        lineHeight: "41px",
        letterSpacing: "-0.01em",
        fontStyle: "normal",
    },
    headerText: {
        fontSize: "24px",
        lineHeight: "31px",
        letterSpacing: "-0.01em",
        fontStyle: "normal",
        color: "#6456F7",
        fontWeight: 500,
    },
    headerTextHeighlight: {
        fontSize: "24px",
        lineHeight: "31px",
        letterSpacing: "-0.01em",
        fontStyle: "normal",
        color: "#49B892",
        // fontFamily: 'Mail Sans Roman',
        fontWeight: 500,
    },
    glogn: {
        background: "#FFFFFF",
        border: "1px solid #E8E6FF",
        borderRadius: "4px",
        height: '50px'
    },
    divider: {
        padding: "0 10px 0 10px"
    },
    title: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "25px",
        lineHeight: "36px",
        /* identical to box height */
        textAlign: "center",
        letterSpacing: "-0.02em",
        /* Neutrals/Text_Black */
        color: "#313133",
        marginBottom: "15px",
        marginTop: '8px'
    },
    ///
    loginBtn: {
        background: "#49B892",
        borderRadius: "4px",
        color: "white",
        height: '50px'
    },
    ///
    text: {
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "22px",
        /* or 22px */

        textAlign: "center",
        letterSpacing: "0.0025em",

        /* Neutrals/Subheading */

        color: "#575759",

    },

}));
const Divider = ({ children }) => {
    const classes = useStyles()
    return (
        <div className="flex items-center">
            <div className="border-b w-full" />
            <span className={classes.divider}>
                {children}
            </span>
            <div className="border-b w-full" />
        </div>
    );
};
const schema = yup.object().shape({
    password: yup
        .string()
        .required('Please enter your password.')
        .min(8, 'Password is too short - should be 8 chars minimum.')
       
});
const defaultValues = {
    password: ""
}



function Passwordset() {
   
    const { state } = useLocation();
    console.log(state)
    const [formdata,setformdata]=useState(state)
    const [passwordSetup, setPasswordSetup] = useState(false)
    const [userDetail, setUserDetail] = useState(true)
    const [greeting, setGreetings] = useState(false)

    const submitDetailHandler = (userDetail, greeting) => {
        setUserDetail(userDetail)
        setGreetings(greeting)
    }

    useEffect(() => {
        if (greeting)
            setTimeout(() => {
               
            }, 100)
    }, [greeting])

    function onClickLogin(model) {
        history.push('/Login')
    
      }
      const classes = useStyles()
   
      const history = useHistory()

      const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schema),
      });
  
     
      function onSubmit(model) {
        var newdata = {
            data:formdata.state.formData,
            password:model.password,
          };
        if (newdata){
    
            axios
              .post("https://demo.subscribepod.com/api/v1/users/signup_company_user/", {
      
                full_name:newdata.data.fullname,
                company_name:newdata.data.cname,
                email: newdata.data.email,
                password: newdata.password,
              })
              .then(function (response) {
                console.log(response.data, " welcome to subscribepod");
                if (response.data.message) {
                    history.push("/login");
                }
              })
              .catch(function (errors) {
                console.log(errors, " Sign up error");
                setError({
                    message: errors.detail,
                });

              });
            }
          
          
      }
   
    return (
        <>
            <div className={classes.container}>
                <div className='flex justify-between w-full'>
                    <div>
                        <div className='flex'>
                            <img src="assets/icones/logo/Logo_short.svg" />
                            <div className={classes.headerText}>Subscribe<span className={classes.headerTextHeighlight}>pod</span>
                            </div>
                        </div>
                    </div>
                    <Button onClick={onClickLogin} className={`${classes.topbutton} flex justify-center items-center`}>LOG IN</Button>
                </div>

                <div className={`flex w-full h-full justify-center items-center`}>
                   

                    
                        <div className={`px-56 ${classes.setPassword} items-center`}>
                        <div>
            <Typography className={classes.title}>Welcome, set up a password.</Typography>
            <Typography className='flex text-center mb-16'>Now that you know how we can help, feel free to define
                a password for your account.</Typography>
            <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <InputLabel className='mb-4'>Password</InputLabel>
                    {/* <Controller
                        name="password"
                        control={control}
                        render={({ field }) => ( */}
                            <TextField
                                // {...field}
                                className="mb-16"
                                {...register("password")}
                                fullWidth
                                type="password"
                                error={!!errors.password}
                                helperText={errors?.password?.message}
                                variant="outlined"
                                required
                            />
                        {/* )}
                    /> */}
                </div>
                <div>
                    <div>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            // color="primary"
                            className={`w-full mx-auto mt-16 ${classes.loginBtn}`}
                            aria-label="CONTINUE"
                           // disabled={_.isEmpty(dirtyFields) || !isValid}
                            value="legacy"
                        >
                            CONTINUE
                        </Button>
                        <div className='py-14'><Divider>Or</Divider></div>

                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            className={`w-full mx-auto ${classes.glogn}`}
                            aria-label="CONTINUE WITH GOOGLE"
                            //disabled={_.isEmpty(dirtyFields) || !isValid}
                            value="legacy"
                        >
                            <div className='flex justify-center gap-x-4 w-full items-center'>
                                <span><img src="assets/icones/social_login/google-logo.svg" /></span>
                                <span>CONTINUE WITH GOOGLE</span>
                            </div>
                        </Button>
                    </div>
                </div>
            </form>
        </div>
                            
                        </div>
                   
                </div>
                <img src='assets/icones/iconography/bottom-curve.png' width='100%'
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        zIndex: 10
                    }}
                />
            </div>
        </>
    )
}

export default Passwordset;