import React from 'react'
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { submitPassword } from 'app/auth/store/registerSlice';
import _ from '@lodash';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
const useStyles = makeStyles((theme) => ({
    login: {
        // width: "586px",
        margin: ' 0 427px',
        height: '346px'
        // height: "auto",
    },
    loginWrapper: {
        background: "#FFFFFF",
        boxShadow: "1px 1px 13px rgba(76, 72, 113, 0.1)",
        borderRadius: "8px",
        zIndex: 15,
        marginTop: '54px',
        width: '586px',
        height: '346px'
    },
    glogn: {
        background: "#FFFFFF",
        border: "1px solid #E8E6FF",
        borderRadius: "4px",
    },
    divider: {
        padding: "0 10px 0 10px"
    },
    title: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "25px",
        lineHeight: "36px",
        /* identical to box height */
        textAlign: "center",
        letterSpacing: "-0.02em",
        /* Neutrals/Text_Black */
        color: "#313133",
        marginBottom: "15px"
    },
    ///
    loginBtn: {
        background: "#49B892",
        borderRadius: "4px",
        color: "white",
        height: '50px'
    },
    ///
    text: {
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "22px",
        /* or 22px */

        textAlign: "center",
        letterSpacing: "0.0025em",

        /* Neutrals/Subheading */

        color: "#575759",

    },

}));
const Divider = ({ children }) => {
    const classes = useStyles()
    return (
        <div className="flex items-center">
            <div className="border-b w-full" />
            <span className={classes.divider}>
                {children}
            </span>
            <div className="border-b w-full" />
        </div>
    );
};
const schema = yup.object().shape({
    password: yup
        .string()
        .required('Please enter your password.')
        .min(4, 'Password is too short - should be 4 chars minimum.')
});
const defaultValues = {
    password: ""
}
function Passwordset() {
    const classes = useStyles()
    const dispatch = useDispatch();
    const register = useSelector(({ auth }) => auth.register);
    const history = useHistory()
    const { control, setValue, formState, handleSubmit, reset, trigger, setError } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });
    const { isValid, dirtyFields, errors } = formState;
    function onSubmit(model) {
        dispatch(submitPassword(model));
        history.push('/login')
    }
    useEffect(() => {
        register.errors.forEach((error) => {
            setError(error.type, {
                type: 'manual',
                message: error.message,
            });
        });
    }, [setError]);
    useEffect(() => {
        setValue('password', '', { shouldDirty: true, shouldValidate: true });
    }, [reset, setValue, trigger]);
    return (
        <div className={`${classes.login} p-28  ${classes.loginWrapper} items-center`}>
            <Typography className={classes.title}>Reset password</Typography>
            <Typography className={`flex text-center mb-16 ${classes.text} `}>Please define a new password that is not same as
                the previously used passwords.</Typography>
            <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <InputLabel className='mb-2'>Password</InputLabel>
                    <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className="mb-16"
                                fullWidth
                                type="password"
                                error={!!errors.password}
                                helperText={errors?.password?.message}
                                variant="outlined"
                                required
                            />
                        )}
                    />
                </div>
                <div>
                    <div>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            className={`w-full mx-auto mt-16 ${classes.loginBtn}`}
                            aria-label="CONTINUE"
                            disabled={_.isEmpty(dirtyFields) || !isValid}
                            value="legacy"
                        >
                            CONFIRM PASSWORD
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Passwordset