import React, { Component } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import PopUp from "./Popup";
import Tour from "reactour";
import Text from "./Text";
import Glitch from "./Glitch";
import Tooltip from "./Tooltip";
import { Link } from "./Button";
import Dashboard from "./Dashboard";
const isAuthenticated = localStorage.getItem('token');
const tourConfig = [
    {
      selector: '[data-tut="first_step"]',
      content: () => (
        <div>
         <h2>Overview section</h2>
         <br></br>
      <p>Complete overview of your SaaS management that you want to know. Hover to see more.</p>
      </div>
      )
    },
    {
        selector: '[data-tut="second_step"]',
        content: () => (
            <div>
             <h2>Recent Insights</h2>
             <br></br>
         <p>Insights provide a deep understanding of your SaaS management. Click to review them.</p>
         </div>
        )
    },
    {
        selector: '[data-tut="third_step"]',
        content: () => (
            <div>
             <h2>Top 10 Apps</h2>
             <br></br>
        <p>This shows top 10 apps by Most unused, Multi-sourced and Spendings. Toggle between them to learn more.</p></div>
        )
    },
    {
        selector: '[data-tut="fourth_step"]',
        content: () => (
            <div>
             <h2>Redundant functions by spend</h2>
             <br></br>
        
        <p>This shows top 10 redundant functions of your subscriptions by spending. Click on the chart to learn more.</p></div>
   ) },
    {
        selector: '[data-tut="fifth_step"]',
        content: () => (
            <div>
             <h2>Upcoming Renewals</h2>
             <br></br>
        <p>Upcoming SaaS renewals for the whole year. Click on the Subscription avatars to see more details.</p>
        </div>
        )
    },
    {
        selector: '[data-tut="sixth_step"]',
        content: () => (
                   <div>
                    <h2>Subscription by Teams & Categories</h2>
                    <br></br>
                    <p>Pie chart shows the usage and spendings data on the subscriptions by teams and categories.</p>
                    </div>

          
              )
        
    },
]


class TourApp extends Component {
  constructor() {
    super();
    this.state = {
      isTourOpen: false,
      isShowingMore: false
    };
    this.enableBody=this.enableBody.bind(this,true)
    this.disableBody=this.disableBody.bind(this,true)
  }

  disableBody = (target) => {
  
    disableBodyScroll(target);
  }

  enableBody = (target) => { 
    enableBodyScroll(target);
    
  }
  


  toggleShowMore = () => {
    this.setState(prevState => ({
      isShowingMore: !prevState.isShowingMore
    }));
  };

  closeTour = () => {
    this.setState({ isTourOpen: false });
    
  };

  openTour = () => {
    this.setState({ isTourOpen: true });

    
  };

  render() {
    const { isTourOpen, isShowingMore } = this.state;
    const accentColor = "#5cb7b7";
    return (
      <div>
         
         <Dashboard  openTour={this.openTour}  toggleShowMore={this.toggleShowMore}
          isShowingMore={isShowingMore} />
        <Tour
          steps={tourConfig}
          isOpen={isTourOpen}
          className="helper"
          rounded={10}
          accentColor={accentColor}
          disableScrollLock
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}  
          onRequestClose={this.closeTour}
        />
      </div>
    );
  }
}


//   {
//     selector: '[data-tut="reactour__logo"]',
//     content: `And this is our cool bus...`
//   },
//   {
//     selector: '[data-tut="reactour__copy"]',
//     content: `Keep in mind that you could try and test everithing during the Tour. 
//       For example, try selecting the highlighted text…`
//   },
//   {
//     selector: '[data-tut="reactour__style"]',
//     content: () => (
//       <div>
//         <Glitch data-glitch="Styled">Styled</Glitch>
//         <Text color="#e5e5e5">
//           The <Tooltip data-tooltip="this helper ⬇">tourist guide</Tooltip>{" "}
//           could be dressed in any way, using custom components, styles and so
//           on…
//         </Text>
//         <Text color="#373737" size=".7em" style={{ marginTop: ".7em" }}>
//           <Link
//             href="http://codepen.io/lbebber/full/ypgql/"
//             color="dark"
//             nospaces
//           >
//             Text effect
//           </Link>{" "}
//           by{" "}
//           <Link href="https://twitter.com/lucasbebber" color="dark" nospaces>
//             Lucas Bebber
//           </Link>
//         </Text>
//       </div>
//     ),
//     style: {
//       backgroundColor: "black",
//       color: "white"
//     }
//   },
//   {
//     selector: '[data-tut="reactour__goTo"]',
//     content: ({ goTo }) => (
//       <div>
//         If you wanna go anywhere, skipping places, it is absolutely possible.
//         <br /> "Oh, I forgot something inside the bus…"{" "}
//         <button
//           style={{
//             border: "1px solid #f7f7f7",
//             background: "none",
//             padding: ".3em .7em",
//             fontSize: "inherit",
//             display: "block",
//             cursor: "pointer",
//             margin: "1em auto"
//           }}
//           onClick={() => goTo(1)}
//         >
//           Please go back to{" "}
//           <span aria-label="bus" role="img">
//             🚌
//           </span>
//         </button>
//       </div>
//     )
//   },
//   {
//     selector: '[data-tut="reactour__position"]',
//     content: () => (
//       <Text>
//         The <Tooltip data-tooltip="this helper ⬇">tourist guide</Tooltip> could
//         be positioned where you want.
//         <br /> In this case will try to stay in the <strong>
//           left side
//         </strong>{" "}
//         if there's available space, otherwise will{" "}
//         <strong>auto position</strong>.
//       </Text>
//     ),
//     position: "left"
//   },
//   {
//     selector: '[data-tut="reactour__scroll"]',
//     content:
//       "Probably you noted that the Tour scrolled directly to the desired place, and you could control the time also…"
//   },
//   {
//     selector: '[data-tut="reactour__scroll--hidden"]',
//     content: "Also when places are pretty hidden…"
//   },
//   {
//     selector: '[data-tut="reactour__action"]',
//     content:
//       "When arrived on each place you could fire an action, like… (look at the console)",
//     action: () =>
//       console.log(`
//                   ------------🏠🏚---------
//       🚌 Arrived to explore these beautiful buildings! 🚌
//                   ------------🏠🏚---------
//    🚧 This action could also fire a method in your Component 🚧
//     `)
//   },
//   {
//     selector: '[data-tut="reactour__state"]',
//     content:
//       "And the Tour could be observing changes to update the view, try clicking the button…",
//     observe: '[data-tut="reactour__state--observe"]'
//   }
//];

export default TourApp;
