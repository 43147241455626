import { authRoles } from 'app/auth';
import Settings from './Settings';

const SettingsConfig = {
    settings: {
        layout: {
            config: {
                navTitle:"Settings"
            },
        },
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: '/subscribe/settings',
            component: Settings,
        },
    ],
};

export default SettingsConfig;
