import React from 'react'
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { submitRegister, submitPassword } from 'app/auth/store/registerSlice';
import * as yup from 'yup';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    // login: {
    //     width: "586px",
    //     // height: "auto",
    // },
    loginWrapper: {
        background: "#FFFFFF",
        borderRadius: "8px",
        boxShadow: "0px 1px 2px rgba(76, 72, 113, 0.05)",
    },
    title: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "28px",
        lineHeight: "36px",
        /* identical to box height */
        textAlign: "center",
        letterSpacing: "-0.02em",
        /* Neutrals/Text_Black */
        color: "#313133",
        marginBottom: "30px",
        fontStyle: 'normal'
    },
    container: {
        // background: "#E5E5E5",
        // padding: "0 64px"
    },
    topbutton: {
        border: "1px solid #E8E6FF",
        padding: "10px",
        borderRadius: "4px",
        color: "#313133 !important",
        width: "159px",
        height: "44px",
        textDecoration: "none !important",
        display: "flex",

    },
    loginBtn: {
        background: "#49B892",
        borderRadius: "4px",
        color: "white",
        height: '50px',
        marginTop: '14px'
    },
    glogn: {
        background: "#FFFFFF",
        border: "1px solid #E8E6FF",
        borderRadius: "4px",
    },
    divider: {
        padding: "0 10px 0 10px"
    },
    text: {
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "22px",
        /* or 22px */

        textAlign: "center",
        letterSpacing: "0.0025em",

        /* Neutrals/Subheading */

        color: "#575759",

    },
    descHeader: {
        fontSize: "32px",
        lineHeight: "41px",
        letterSpacing: "-0.01em",
        fontStyle: "normal",
        color: " #313133",

    },
    heighlight: {
        color: "#6456F7",
        fontSize: "32px",
        lineHeight: "41px",
        letterSpacing: "-0.01em",
        fontStyle: "normal",
    },
    imageSize: {
        width: "110px",
        height: "135px",
    },
    afterdetailHeading: {
        color: "#313133",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "22px",
        lineHeight: "28px",
        textAlign: "center",
    }
}));
const schema = yup.object().shape({
    
    fullname: yup.string().required('full name is required').matches(/^[a-zA-Z ]{1,75}$$/, "Only alphabets allowed with maximum 75 characters"),
    email: yup.string().email('You must enter a valid email').required('You must enter a email').matches(/^.{1,254}$/,"length cannot be greater than 254 characters"),
    cname: yup.string().required('company name is required').matches(/^[a-zA-Z ]{1,75}$/, "Only alphabets allowed with maximum 75 characters"),
});


function Userdetail(props) {
    const classes = useStyles()
    // const dispatch = useDispatch();
    // const register = useSelector(({ auth }) => auth.register);
    const history = useHistory()
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schema),
      });
    
  
    // const { control, setValue, formState, handleSubmit, reset, trigger, setError } = useForm({
    //     mode: 'onChange',
    //     defaultValues,
    //     resolver: yupResolver(schema),
    // });

    // const { isValid, dirtyFields, errors } = formState;
    // useEffect(() => {
    //     register.errors.forEach((error) => {
    //         setError(error.type, {
    //             type: 'manual',
    //             message: error.message,
    //         });
    //     });
    // }, [register.errors, setError]);

    // useEffect(() => {
    //     setValue('fullname', 'Vishal Soni', { shouldDirty: true, shouldValidate: true });
    //     setValue('email', 'vishaal@studio.com', { shouldDirty: true, shouldValidate: true });
    //     setValue('cname', 'IT House', { shouldDirty: true, shouldValidate: true });
    // }, [reset, setValue, trigger]);

    function onSubmit(model) {
       console.log(model)
       history.push('/password',{
        state: {
        formData: {
          email:model.email,
          fullname:model.fullname,
          cname:model.cname,
        },
    },
      });
      
      // console.log(defaultValues)
    //   dispatch(submitRegister(model));
    }
    const submitDetailHandler = () => {
      
    }
    return (
        <div>
            <Typography className={classes.title}>Book a Demo</Typography>
            <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <InputLabel className='mb-4'>Full Name</InputLabel>
                    
                            <TextField
                               
                                className="mb-16"
                                type="text"
                                {...register("fullname")}
                                error={!!errors.fullname}
                                helperText={errors?.fullname?.message}
                                fullWidth
                                variant="outlined"
                                required
                            />
                  
                </div>
                <div>
                    <InputLabel className='mb-4'>Business Email</InputLabel>
                    
                            <TextField
                               
                                className="mb-16"
                                type="text"
                                {...register("email")}
                                error={!!errors.email}
                                helperText={errors?.email?.message}
                                fullWidth

                                variant="outlined"
                                required
                            />
            
                </div>
                <div>
                    <InputLabel className='mb-4'>Company Name</InputLabel>
                  
                            <TextField
                              
                                className="mb-16"
                                fullWidth
                                type="text"
                                {...register("cname")}
                                error={!!errors.cname}
                                helperText={errors?.cname?.message}
                                variant="outlined"
                                required
                            />
                 
                </div>
                <div>
                    <div>
                        <Button
                            onClick={submitDetailHandler}
                            fullWidth
                            type="submit"
                            variant="contained"
                            className={`w-full mx-auto mt-16 ${classes.loginBtn}`}
                            aria-label="CONTINUE"
                           // disabled={_.isEmpty(dirtyFields) || !isValid}
                            value="legacy"
                        >
                            <span className='flex item-center justify-center'>
                                <span>BOOK A DEMO</span>
                                <span><img src="assets/icones/iconography/bookdemo.svg" /></span>
                            </span>
                        </Button>
                    </div>
                    {/* <div className={`flex text-center px-56 mt-8 pb-8 ${classes.text}`}>We will be in touch shortly via email to
                        schedule the demo time.</div> */}
                </div>
            </form>
        </div>
    )
}

export default Userdetail