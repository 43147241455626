import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    text: {
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "22px",
        /* or 22px */

        textAlign: "center",
        letterSpacing: "0.0025em",

        /* Neutrals/Subheading */

        color: "#575759",

    },
    imageSize: {
        width: "134px",
        height: "141px",
        marginTop: '122px',
    },
    afterdetailHeading: {
        color: "#313133",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "22px",
        lineHeight: "28px",
        textAlign: "center",
        marginTop: '40px'
    }
}));
function Greeting() {
    const classes = useStyles()

    return (
        <div className="flex flex-col justify-center w-full">
            <div className='flex justify-center items-center'>
                <img className={classes.imageSize} src="assets/icones/logo/Logo_short.svg" />
            </div>
            <div className={classes.afterdetailHeading}>Thanks for your interest! 🎉</div>
            <div className={`flex text-center px-56 mt-8 ${classes.text}`}> We will be in touch shortly via email to schedule the demo time.</div>

        </div>
    )
}

export default Greeting