import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { useParams, useHistory, Redirect } from 'react-router';
import Toolbar from '@material-ui/core/Toolbar';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import { selectNavigation } from 'app/store/fuse/navigationSlice';
import Showoverall from './Showoverall'
import Datatable from './Datatable'
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TextField, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
export const SubsciptionContext = React.createContext();


const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 0
  },
  heading: {
    // fontFamily: "Mail Sans Roman",
    display: 'flex',
    alignItems: 'center',
    color: '#313133',
    // fontFamily: 'Mail Sans Roman',
    // fontStyle: "normal",
    // fontWeight: 500,
    // fontSize: "22px",
    // padding:"52px 0"
  },
  button: {
    background: "#49B892",
    color: "#fff",
  },
  mailSansRoman: {
    fontFamily: "Mail Sans Roman"
  },
  Inter: {
    fontFamily: "Inter"
  }
}));
function Subscriptions(props) {
  const param = useParams()
  const navigation = useSelector(selectNavigation);
  const history = useHistory();
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const navbar = useSelector(({ fuse }) => fuse.navbar);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const [getdata,setGetData] = React.useState(true)
  const isAuthenticated = localStorage.getItem('token') !== null;
  const classes = useStyles(props);

  function onBackButtonClick() {
    history.goBack();
  }
  const [open, setOpen] = React.useState(false);
  const [segment, setNewSegment] = React.useState(false)
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setNewSegment(false)
  };

  const handleNewSegment = () => {
    setNewSegment((prev) => !prev)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  if (isAuthenticated) {
  return (
    <SubsciptionContext.Provider value={{getdata,setGetData}}>
    <>
      
      <ThemeProvider theme={toolbarTheme}>
        <AppBar
          id="fuse-toolbar"
          className={clsx(classes.root, 'shadow-0 flex relative', props.className)}
          style={{ backgroundColor: "#F7F7FF" }}//props.toolbarConfig.toolbarColor ? props.toolbarConfig.toolbarColor : "#F7F7FF", height: "80px", }}
          position="static"
        >
          <Toolbar className="min-h-48 px-0 md:min-h-64">
            <div className="flex flex-1 ">
              {config.navbar.display && config.navbar.position === 'left' && (
                <>
                  <Hidden mdDown>
                    {(config.navbar.style === 'style-3' ||
                      config.navbar.style === 'style-3-dense') && (
                        <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                      )}

                    {config.navbar.style === 'style-1' && !navbar.open && (
                      <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                    )}
                  </Hidden>

                  <Hidden lgUp>
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                  </Hidden>
                </>
              )}

              <div className={`${classes.heading} H2_22`}>
                {(<img src='assets/icones/iconography/Arrow_left.svg' width='20px' height='20px' style={{ cursor: 'pointer', marginRight: '10px' }} onClick={onBackButtonClick} />)}
                Subscription
              </div>
            </div>

            <div className="flex gap-x-20 items-center px-8 h-full overflow-x-auto">
              {/* <div>
                <Stack direction="row" spacing={2}>
                  <div>
                    <Button
                      ref={anchorRef}
                      id="composition-button"
                      aria-controls={open ? 'composition-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                      variant="outlined"
                      endIcon={<ArrowDropDownIcon />}
                      style={{ color: "#7F7E8C", borderColor: "#7F7E8C" }}
                    >
                      SEGMENTS
                    </Button>
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom-start' ? 'left top' : 'left bottom',
                          }}
                          className="mt-10 flex justify-center"
                        >
                          <Paper sx={{ width: '100%', maxWidth: '100%' }}>
                            {!segment && <div className='w-full p-10'>
                              <div className='flex w-full p-10 justify-between items-center'>
                                <Typography style={{ color: "#313133", fontSize:'14px'}}>Segment</Typography>
                                <Button onClick={handleNewSegment} variant='contained' style={{ background: "#49B892", color: "#fff" }} className={`${classes.button}Medium_14M`} startIcon={<AddIcon />}>New Segment</Button>
                              </div>
                              <hr />
                              <div>
                                <MenuList
                                  autoFocusItem={open}
                                  id="composition-menu"
                                  aria-labelledby="composition-button"
                                  onKeyDown={handleListKeyDown}
                                  onClick={handleClose}
                                >
                                  <MenuItem className='flex justify-between w-full items-center' onClick={handleClose}><Typography>All app that has spending more than $1000</Typography><CloseIcon /></MenuItem>
                                  <MenuItem className='flex justify-between items-center' onClick={handleClose}><Typography>All app that has spending more than $1000</Typography><CloseIcon /></MenuItem>
                                  <MenuItem className='flex justify-between items-center' onClick={handleClose}><Typography>All app that has spending more than $1000</Typography><CloseIcon /></MenuItem>
                                </MenuList>
                              </div>
                            </div>}
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </Stack>
              </div>
              <img src="assets/icones/toolbar/info.svg" />

              <img src="assets/icones/toolbar/notification.svg" /> */}


              <UserMenu />

            </div>

            {config.navbar.display && config.navbar.position === 'right' && (
              <>
                <Hidden mdDown>
                  {!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <div className='pt-20'>
        <div>
          <Paper className='mb-20'>
            {segment && <div className='p-14 w-full'>
              <Typography className='font-bold' style={{ color: "#313133", fontSize:'14px'}}>New Segment</Typography>
              <div className='flex mt-10 justify-between items-center '>
                <div className='flex gap-x-6 items-center '>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={10}
                    // label="Age"
                    // onChange={handleChange}
                    
                    style={{ width: "120px" ,fontFamily:'Inter', }}
                  >
                    <MenuItem value={10}><Typography>All apps</Typography></MenuItem>
                    <MenuItem value={30}><Typography>Project management</Typography></MenuItem>
                    <MenuItem value={20}><Typography>Design</Typography></MenuItem>
                    <MenuItem value={30}><Typography>Team collaborations</Typography></MenuItem>
                    <MenuItem value={30}><Typography>Productivity</Typography></MenuItem>
                    <MenuItem value={30}><Typography>Analytics</Typography></MenuItem>
                  </Select>
                  <Typography>That has</Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={10}
                    // label="Age"
                    // onChange={handleChange}
                    style={{ width: "120px" ,fontFamily:'Inter'}}
                  >
                     <MenuItem value={30}><Typography>Spending</Typography></MenuItem>
                     <MenuItem value={30}><Typography>Utilisation</Typography></MenuItem>
                    <MenuItem value={10}><Typography>Users</Typography></MenuItem>
                    <MenuItem value={10}><Typography>Renewals</Typography></MenuItem>
                    <MenuItem value={20}><Typography>Status</Typography></MenuItem>
                   
                  </Select>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={10}
                    // label="Age"
                    // onChange={handleChange}
                    style={{ width: "120px" ,fontFamily:'Inter' }}
                  >
                    <MenuItem value={10}><Typography>More than</Typography></MenuItem>
                    <MenuItem value={20}><Typography>Less tham</Typography></MenuItem>
                    <MenuItem value={30}><Typography>Equal to</Typography></MenuItem>
                  </Select>
                  <TextField InputProps={{
                    startAdornment: "$"
                  }} style={{ width: "120px" }} variant="outlined" />
                </div>
                <div className='flex gap-x-7' >
                  <Button size="large" onClick={handleNewSegment} style={{ color: "#7F7E8C", fontSize:'13px',borderColor: "#7F7E8C" ,fontFamily:'Inter'}} variant='outlined'>RESET</Button>
                  <Button size="large" onClick={handleNewSegment} style={{ color: "#7F7E8C", borderColor: "#7F7E8C" , fontSize:'13px', fontFamily:'Inter' }} variant='outlined'>SAVE</Button>
                  <Button size="large" onClick={handleNewSegment} style={{ background: "#49B892",fontFamily:'Inter', fontSize:'13px'}} variant='contained'>APPLY</Button>
                </div>
              </div>
            </div>}
          </Paper>
          <Showoverall />
        </div>
        <div className='pt-20'>
          <Datatable />
        </div>
      </div>
    </>
    </SubsciptionContext.Provider >
  )
}

  else {
    // Redirect to the login page when not authenticated
    return <Redirect to="/login" />;
  }
}

export default memo(Subscriptions)